// import React, { useEffect, useState } from 'react';
// import { Box, Breadcrumbs, Button, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import back from "../School/Images/back-icn.svg";
// import HttpComponent from "../School/MakeRequest";
// import PaymentSetup from "../School/PaymentSetup";
// import AddTenant from "./components/addTenant";
// import CreatServiceForm from "./components/Service/serviceSetup";
// import CreateUnitsForm from './components/Units/unitsSetup'
// import BusinessDetailsForm from "./components/businessSetup";
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import PaymentSettingUp from '../paymentmodule/paymentsettingup';
// import DefineUnits from './components/Units/defineUnits';
// import SetWorkFlow from './Utility/updateSetupWorkFlow';


// //import SchoolDetailsForm from "./SchoolDetailsForm";
// //import ServiceCategorySetup from "./ServiceCategorySetup";
// //import ServiceSetup from "./ServiceSetup";
// //import PaymentSetup from "./PaymentSetup";
// //import ParentStudentSetUp from "./ParentStudentSetUp";
// //import "./MulitStep.css"

// const RentalMultiSetup = () => {
// 	const steps = ['Business Details', 'Create Units', 'Create Services', 'Payment Options', 'Create tenant'];
// 	const [activeStep, setActiveStep] = useState(0);
// 	const { userId, X_Authorization } = useSelector((store) => store.user);
// 	const [showActiveStepper ,setShowActiveStepper] = useState(true)
// 	const [showBreadCrumbs ,setShowBreadCrumbs] = useState(true)

// 	const checkFormStatus = () => {
// 		HttpComponent({
// 			method: 'GET',
// 			url: '/api/get_setup_status',
// 			body: null,
// 			token: X_Authorization,
// 		}).then((data) => {
// 			console.log("here store is data", data);
// 			if (data.status === 201) {
// 				console.log("state", data.response.data.workflowState)
// 				let state = data.response.data.workflowState;
// 				if (state === "BASIC") {
// 					setActiveStep(0)
// 				} else if (state?.includes("UNIT")) {
// 					setActiveStep(1)
// 				} else if (state?.includes("SERVICE")) {
// 					setActiveStep(2)
// 				} else if (state?.includes("PAYMENTOPTIONS")) {
// 					setActiveStep(3)
// 				} else if (state?.includes('TENANTS')) {
// 					setActiveStep(4)
// 				}
// 			} else {

// 			}
// 		}).catch((error) => {
// 			console.error(error.message);
// 		})
// 	}

// 	useEffect(() => {
// 		checkFormStatus();
// 	}, []);

// 	const handleNext = () => {
// 		setActiveStep((prevActiveStep) => prevActiveStep + 1);
// 	};
// 	const handleBack = () => {
// 		setActiveStep((prevActiveStep) => prevActiveStep - 1);
// 	};

// 	const handleGoTenant=()=>{
// 		SetWorkFlow({flowName : 'TENANTS', token : localStorage.getItem('X-Authorization')})
// 		setActiveStep((prevActiveStep) => prevActiveStep + 1);
// 	}

// 	const breadcrumbs = [
// 		<Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
// 			Dashboard
// 		</Typography>,
// 		<Typography key={steps[activeStep]} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
// 			{steps[activeStep]}
// 		</Typography>
// 	];

// 	const renderFormContent = () => {
// 		switch (activeStep) {
// 			case 0:
// 				//Business Details
// 				return (
// 					<Box component="div" style={{ width: "100%" }}>
// 						<BusinessDetailsForm next={handleNext} />
// 					</Box>
// 				);
// 			case 1:

// 				//Create units
// 				return (
// 					<Box component="div" sx={{ width: "100%" }}>
// 						{/* <CreateUnitsForm next={handleNext}/> */}
// 						<DefineUnits  next={handleNext}/>
// 					</Box>
// 				);
// 			case 2:

// 				//Create Service
// 				return (
// 					<Box component="div" sx={{ width: "100%" }}>
// 						<CreatServiceForm next={handleNext} back={handleBack} />
// 						{/*<ServiceSetup/>*/}
// 					</Box>
// 				);
// 			case 3:
// 				return (
// 					<Box component="div" sx={{ width: "100%" }}>
// 						<PaymentSettingUp next={handleGoTenant} />
// 					</Box>
// 				)
// 			case 4:
// 				return (
// 					<Box component="div" sx={{ width: "100%" }}>
// 						<AddTenant setShowBreadCrumbs={setShowBreadCrumbs} setShowActiveStepper={setShowActiveStepper} />
// 					</Box>
// 				)
// 			default:
// 				return null;
// 		}
// 	};
// 	const navigate = useNavigate();
// 	return (
// 		<Box component="div" sx={{ fontFamily: 'Poppins', width: "100%", margin: 1, display: "flex", flexDirection: "column" }}>

// 			{/*Header title*/}
// 			{showBreadCrumbs &&
// 				<Grid container justifyContent={'flex-start'} style={{ marginBottom: '20px' }} direction={'column'} spacing={2}>
// 					<Grid item style={{ fontSize: '25px', fontWeight: 600, color: '#032541' }}>
// 						<img alt="" style={{ marginRight: '3px' }} src={back} onClick={() => navigate("/createBusiness")} /><span>Create Business</span>
// 					</Grid>
// 					<Grid item>
// 						<Breadcrumbs
// 							separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}
// 							aria-label="breadcrumb">
// 							{breadcrumbs}
// 						</Breadcrumbs>
// 					</Grid>
// 				</Grid>
// 			}

// 			{showActiveStepper &&
// 				<Stepper activeStep={activeStep} alternativeLabel style={{ width: '100%' }}>
// 					{steps?.map((label, index) => {
// 						return (
// 							<Step key={label}>
// 								<StepLabel StepIconProps={{ classes: { completed: 'step-icon-completed', text: index !== activeStep ? "step-icon-text" : null, }, style: { color: index === activeStep ? '#032541' : '#f5f6f7', width: "2.188rem", height: "2.188rem", } }}>
// 									{label}
// 								</StepLabel>
// 							</Step>
// 						);
// 					})}
// 				</Stepper>
// 			}

// 			<Box component="div">
// 				{renderFormContent()}
// 			</Box>

// 			{/*<Box component="div" sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>*/}
// 			{/*	<Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>*/}
// 			{/*		Back*/}
// 			{/*	</Button>*/}
// 			{/*	<Button onClick={handleNext}>*/}
// 			{/*		{activeStep === steps.length - 1 ? 'Finish' : 'Next'}*/}
// 			{/*	</Button>*/}
// 			{/*</Box>*/}
// 		</Box>
// 	)
// }

// export default RentalMultiSetup;


import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import back from "../School/Images/back-icn.svg";
import HttpComponent from "../School/MakeRequest";
import PaymentSettingUp from '../paymentmodule/paymentsettingup';
import BusinessDetailsForm from "./components/businessSetup";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SetWorkFlow from './Utility/updateSetupWorkFlow';

const RentalMultiSetup = () => {
	const steps = ['Business Details', 'Payment Options'];
	const [activeStep, setActiveStep] = useState(0);
	const { X_Authorization } = useSelector((store) => store.user);
	const [showActiveStepper, setShowActiveStepper] = useState(true);
	const [showBreadCrumbs, setShowBreadCrumbs] = useState(true);

	const checkFormStatus = () => {
		HttpComponent({
			method: 'GET',
			url: '/api/get_setup_status',
			body: null,
			token: X_Authorization,
		}).then((data) => {
			if (data.status === 201) {
				let state = data.response.data.workflowState;
				if (state === "BASIC") {
					setActiveStep(0);
				} else if (state?.includes("PAYMENTOPTIONS")) {
					setActiveStep(1);
				}
			}
		}).catch((error) => {
			console.error(error.message);
		});
	};

	useEffect(() => {
		checkFormStatus();
	}, []);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleGoTenant = () => {
		SetWorkFlow({ flowName: 'TENANTS', token: localStorage.getItem('X-Authorization') });
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const breadcrumbs = [
		<Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
			Dashboard
		</Typography>,
		<Typography key={steps[activeStep]} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
			{steps[activeStep]}
		</Typography>
	];

	const renderFormContent = () => {
		switch (activeStep) {
			case 0:
				// Business Details
				return (
					<Box component="div" style={{ width: "100%" }}>
						<BusinessDetailsForm next={handleNext} />
					</Box>
				);
			case 1:
				// Payment Options
				return (
					<Box component="div" sx={{ width: "100%" }}>
						<PaymentSettingUp next={handleGoTenant} />
					</Box>
				);
			default:
				return null;
		}
	};

	const navigate = useNavigate();
	return (
		<Box component="div" sx={{ fontFamily: 'Poppins', width: "100%", margin: 1, display: "flex", flexDirection: "column" }}>
			{/* Header title */}
			{showBreadCrumbs &&
				<Grid container justifyContent={'flex-start'} style={{ marginBottom: '20px' }} direction={'column'} spacing={2}>
					<Grid item style={{ fontSize: '25px', fontWeight: 600, color: '#032541' }}>
						<img alt="" style={{ marginRight: '3px' }} src={back} onClick={() => navigate("/createBusiness")} /><span>Create Business</span>
					</Grid>
					<Grid item>
						<Breadcrumbs
							separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: ' Poppins', color: "#e1e5e8" }} />}
							aria-label="breadcrumb">
							{breadcrumbs}
						</Breadcrumbs>
					</Grid>
				</Grid>
			}

			{showActiveStepper &&
				<Stepper activeStep={activeStep} alternativeLabel style={{ width: '100%' }}>
					{steps.map((label, index) => {
						return (
							<Step key={label}>
								<StepLabel StepIconProps={{ classes: { completed: 'step-icon-completed', text: index !== activeStep ? "step-icon-text" : null, }, style: { color: index === activeStep ? '#032541' : '#f5f6f7', width: "2.188rem", height: "2.188rem", } }}>
									{label}
								</StepLabel>
							</Step>
						);
					})}
				</Stepper>
			}

			<Box component="div">
				{renderFormContent()}
			</Box>

			{/* Navigation buttons */}
			<Box component="div" sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
				<Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
					Back
				</Button>
				<Button onClick={handleNext} disabled={activeStep === steps.length - 1 && activeStep === 1}>
					{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
				</Button>
			</Box>
		</Box>
	);
}

export default RentalMultiSetup;