import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {ExpandLess} from "@mui/icons-material";
import {ExpandMore} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import List from "@mui/material/List";
import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {AccountingModal} from "../../components/Modals/Accounting/accountingModal";
import SettingsIcon from "@mui/icons-material/Settings";

const useStyles = makeStyles((theme) => ({nested: {paddingLeft: theme.spacing(4),},}));

export default function MerchantConfiguration (props) {
	const baseUrl = process.env.REACT_APP_BASE_URL;
	let businessId = localStorage.getItem("businessId");

	let accountConfigsUrl = `${baseUrl}/api/get_accounting_configs?bId=${businessId}`;

	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const [accountingModal, setAccountingModal] = useState(false);
	const [accountingData, setAccountingData] = useState([]);
	const getConfig = async () => {
		const response = await fetch(accountConfigsUrl, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				"X-Authorization": localStorage.getItem("X-Authorization"),
			},
		});
		const res = await response.json();
		setAccountingData(res.data);
	};

	useEffect(() => {
		getConfig()
			.then(() => {console.log('Success Getting Account Configs')})
			.catch((e) => {console.log('Error Getting Account Configs', e.message)})
	}, []);

	const handleAccountingClose = () => setAccountingModal(false);



	const handleClick = () => {setOpen(!open);};
	const userGroup = localStorage.getItem('group')
	const location = useLocation();
	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"

	return (
		<div>
			{/*Users*/}
			<ListItem button onClick={handleClick} style={{ color: open ? '#04f2fc' : '#fff' }}>
				<div style={{marginRight : '5%'}}>
					<SettingsIcon />
				</div>
				<ListItemText primary="Configurations" style={{fontFamily : 'Poppins'}}/>
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>

			{/*Collapsed Users*/}
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>

					{userGroup === 'Merchant' || userGroup === 'Owner' ?
						<div>
							<ListItem button className={classes.nested}>
								<Link style={{textDecoration: 'none', color: isActiveLink("/businessConfig/") ? activeColor : '#fff'}} to="/businessConfig/">
									<ListItemText primary="Business Config" />
								</Link>
							</ListItem>
							<ListItem button className={classes.nested}>
								<Link style={{textDecoration: 'none',color: isActiveLink("/stockConfig/") ? activeColor : '#fff'}} to="/stockConfig/">
									<ListItemText primary="Stock Config" />
								</Link>
							</ListItem>
							<ListItem button className={classes.nested}>
								<Link style={{textDecoration: 'none',color: isActiveLink("/accountConfig/") ? activeColor : '#fff'}} to="/accountConfig/">
									<ListItemText primary="Accounting Config" />
								</Link>
							</ListItem>
							<ListItem button className={classes.nested}>
								<Link style={{textDecoration: 'none',color: isActiveLink("/config/auth/enable2fa") ? activeColor : '#fff'}} to="/config/auth/enable2fa">
									<ListItemText primary="2FA Config" />
								</Link>
							</ListItem>
							<ListItem button className={classes.nested}>
								<Link style={{textDecoration: 'none',color: isActiveLink("/shopifyConfig") ? activeColor : '#fff'}} to="/shopifyConfig">
									<ListItemText primary="Zed E-commerce " />
								</Link>
							</ListItem>
							<ListItem button className={classes.nested}>
								<Link style={{textDecoration: 'none',color: isActiveLink("/payablesConfig/") ? activeColor : '#fff'}} to="/payablesConfig">
									<ListItemText primary="Payables Config" />
								</Link>
							</ListItem>
							<ListItem button className={classes.nested}>
								<Link style={{textDecoration: 'none',color: isActiveLink("/evoucher_booking/") ? activeColor : '#fff'}} to="/evoucher_booking">
									<ListItemText primary="Booking & Evoucher Config" />
								</Link>
							</ListItem>
							{/* <ListItem button className={classes.nested}>
								<Link style={{textDecoration: 'none',color: isActiveLink("/evoucher/") ? activeColor : '#fff'}} to="/evoucher">
									<ListItemText primary="Evoucher" />
								</Link>
							</ListItem> */}
							<ListItem button className={classes.nested}>
								<Link style={{textDecoration: 'none',color: isActiveLink("/quickbooks/") ? activeColor : '#fff'}} to="/quickbooks">
									<ListItemText primary="QuickBooks" />
								</Link>
							</ListItem>
							{/* <Link style={{textDecoration: 'none', color: isActiveLink("#") ? activeColor : '#fff'}} to="#">
										<ListItem button className={classes.nested}>
											<ListItemText onClick={() => {setAccountingModal(true)}} primary="QuickBooks" />
										</ListItem>
									</Link>
								<Link style={{textDecoration: 'none', color: isActiveLink("#") ? activeColor : '#fff'}} to="#">
									<ListItem button className={classes.nested}>
										<ListItemText onClick={() => {setAccountingModal(true)}} primary="QuickBooks" />
									</ListItem>
								</Link> */}
							{/*Accounting Modal*/}
							<AccountingModal accountingOpen={accountingModal} onClose={handleAccountingClose} accountingData={props.accountingData}/>
						</div> : null}

				</List>
			</Collapse> 
		</div>
	)
}