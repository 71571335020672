/* eslint-disable react-hooks/rules-of-hooks */
import CustomTable from "../../../../School/CustomTable";
import { Button, Grid, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import CurrencyFormatter from "../../../../../utils/currencyFormatter";
import HttpComponent from "../../../../School/MakeRequest";

export default function ListTenants(props) {
  try {
    const { X_Authorization } = useSelector((store) => store.user);

    // Handle Radio Button
    const [selectedValue, setSelectedValue] = useState();

    //check on the selected  unit type row
    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };

    const columns = [
      {
        field: "tenantsName",
        headerAlign: "left",
        align: "left",
        flex: 1,
        renderHeader: () => {
          return <strong style={{ fontSize: "12px", color: "#032541", fontWeight: 600 }}>Tenant Name</strong>;
        },
        renderCell: (params) => {
          return (
            <div>
              {/* {console.log(params, 'Here is the params')} */}
              <Link>
              <span style={{ marginLeft: "5px" }}>{params.value}</span>
              </Link>
              <Link  value={params.tenantId} onChange={handleChange} color={"error"} />
              {/* <Radio value={params.tenantId} size={"small"} checked={selectedValue === params.tenantId} onChange={handleChange} color={"error"} /> */}
          
            </div>
          );
        },
      },
      {
        field: "occupiedOn",
        headerAlign: "left",
        align: "left",
        flex: 1,
        renderHeader: () => {
          return <strong style={{ fontSize: "12px", color: "#032541", fontWeight: 600 }}>Occupied On</strong>;
        },
      },
      {
        field: "unitAmount",
        headerAlign: "left",
        align: "left",
        flex: 1,
        renderHeader: () => {
          return <strong style={{ fontSize: "12px", color: "#032541", fontWeight: 600 }}>Amount</strong>;
        },
        renderCell: (params) => {
          return <span>{unitAmountData ? CurrencyFormatter(unitAmountData, "KES") : "N/A"}</span>;
        },
      },
    ];

    // loader
    const [loader, setLoader] = useState(false);

    // Data
    const [data, setData] = useState([]);
    const [unitAmountData, setUnitAmountData] = useState([]);

    // Search functionality
    const [dataToShow, setDataToShow] = useState([]);

    const [formData, setFormData] = useState({ search: "" });
    const handleInputChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    //search data

    const setSearchData = () => {
      let searchable = [...data];
      if (formData.search !== "") {
        let result = searchable.filter((unit) => unit.unitType.toLowerCase()?.includes(formData.search) || unit.unitTypePrefix?.includes(formData.search));
        setDataToShow(result);
      } else {
        setDataToShow(data);
      }
    };
    function fetchTenants() {
      try {
        HttpComponent({
          method: "GET",
          url: `/api/v1/unitHistory?unitId=${props.unitid.unitId}&unitTypeId=${props.detail.id}&search=&limit=1000&page=1`,
          token: X_Authorization,
        })
          .then((data) => {
            console.log(data, "Avaibale Tenants");
            if (data.status === 200) {
              setData(data.response.data);
              setUnitAmountData(data.response.unitAmount)
              props.setDataToShow(data.response.data)
            } else {
              console.log(`Error getting Units`);
            }
          })
          .catch((e) => {
            console.log(`Error getting transaction BreakDown`, e.message);
          });
      } catch (e) {
        console.log(`Error getting Units`, e.message);
      }
    }
    //78t86789676
    // function handleNext() {
    //   props.listHouseUnits();
    // }

    useEffect(() => {
      setSearchData();
    }, [formData.search, data]);

    // Data useEffect
    useEffect(() => {
      fetchTenants();
    }, []);
    console.log(data, "tenants info");

    return (
      <div>
        <CustomTable fontSize={"10px"} data={dataToShow} columns={columns} rowsPerPage={5} loading={loader} />
        <Grid container justifyContent={"flex-end"} spacing={3}>
          <Grid item>
            <Button style={{ padding: "5px", textTransform: "none", color: "#fff", background: "#032541", height: "45px", fontSize: "16px", fontWeight: "600", width: "146px" }}>Next</Button>
          </Grid>
        </Grid>
      </div>
    );
  } catch (e) {
    console.log(`Something Went Wring with List tenants`, e.message);
  }
}
