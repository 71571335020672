import React, { useEffect, useState, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Grid, Select, Tab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { alpha, styled } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import deactivateGroup from "../../common/images/deactivate.svg";
import success from "../../common/images/success.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import activateGroup from "../../common/images/activate.svg";
import { ElIf, If } from "rc-if-else";
import deactivate from "../../common/images/deactivate.svg";
import { SessionModal } from "../Modals/SessionExpiredModal/sessionModal";
import { SuccessModal } from "../Modals/SuccessModal/successModal";
import { QuestionModal } from "../Modals/QuestionModal/questionModal";
import CustomSearchInput from "../School/CustomSearchInput";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import moment from "moment-timezone";
import HttpComponent from "../School/MakeRequest";

// session Expired
const sessionStyle = {
  borderRadius: "36px",
  boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
  backgroundColor: "#fff",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "660px",
  height: "570px",
  p: 4,
};

const baseUrl = process.env.REACT_APP_BASE_URL;



const style = {
  width: "40%",
  position: "absolute",
  transform: "translate(-50%, -50%)",
  left: "50%",
  top: "50%",
  overflow: "auto",
  borderRadius: "15px",
  backgroundColor: "#fff",
  fontFamily: "Poppins",
};

const SuccessModalStyle = {
  width: "506px",
  height: "506px",
  margin: "10% Auto 0 Auto",
  padding: "86px 24px 97.7px 61px",
  borderRadius: "36px",
  boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
  backgroundColor: "#fff",
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    transformOrigin={{ vertical: "top", horizontal: "right" }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": { padding: "4px 0" },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

// Custom Tab Styling
const AntTabs = styled(TabList)({
  borderBottom: "0px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#ffffff00",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontSize: "12px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2.75",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6e7074",
    fontFamily: ["Poppins"].join(","),
    "&:hover": {
      color: "#032541",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#dc3545",
      fontWeight: 600,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "blue",
    },
  })
);

// Conditional Data grid columns
//Active Columns
const activeColumns = [
  // { field: 'id', headerName: 'ID' },
  {
    field: `firstName`,
    headerName: "First Name",
    align: "center",
    flex: 1,
    headerClassName: "super-app-theme-header",
    headerAlign: "center",
    editable: false,
  },
  {
    field: `secondName`,
    headerName: "Second Name",
    align: "center",
    flex: 1,
    headerClassName: "super-app-theme-header",
    headerAlign: "center",
    editable: false,
  },
  {
    field: "userName",
    headerName: "Username",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userEmail",
    headerName: "Email",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userPhone",
    headerName: "Phone",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userGroup",
    headerName: "User Group",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: true,
  },
  {
    field: "userBranch",
    headerName: "User Branch",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: true,
  },
  {
    field: "Actions",
    headerName: "Actions",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    renderCell: (params, event) => {
      //DropDown Handler
      const [anchorEl, setAnchorEl] = React.useState(null);
      const ActionOpen = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleActionClose = () => {
        setAnchorEl(null);
      };

      const { row } = params;

      // session Modal Config
      const [sessionOpen, setSessionOpen] = React.useState(false);
      const handleSessionOpen = () => setSessionOpen(true);
      const handleSessionClose = () => setSessionOpen(false);
      const handleLogin = () => {
        window.location.href = "/logout";
      };

      // Edit  Modal Config
      const [open, setOpen] = useState(false);
      const handleClickOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);

      // Deactivate  Modal Config
      const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
      const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
      const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

      // Success Deactivation Modal Config
      const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] =
        useState(false);
      const successDeactivationModalHandleOpen = () =>
        setSuccessDeactivationModalOpen(true);
      const successDeactivationModalHandleClose = () =>
        setSuccessDeactivationModalOpen(false);

      // Success Edit Modal Control
      const [successOpen, setSuccessOpen] = React.useState(false);
      const handleSuccessOpen = () => setSuccessOpen(true);
      const handleSuccessClose = () => setSuccessOpen(false);

      // Data Getters and Setters
      // groups Setters and Getters
      const [groups, setGroups] = useState([]);

      // mapping groups
      const groupOptions = groups.map((group) => {
        return {
          groupName: group.name,
          groupType: group.name,
        };
      });

      // Branches Setters and Getters
      const [branches, setBranches] = useState([]);

      // branches
      const fetchBranches = async () => {
        try {
          // const branchesUrl = baseUrl + "/api/getBusinessBranchesByBid"

          const response = await fetch(
            baseUrl + "/api/getBusinessBranchesByBid",
            {
              method: "GET",
              headers: {
                "X-Authorization": localStorage.getItem("X-Authorization"),
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );
          const data = await response.json();
          if (response.status === 200) {
            setBranches(data.data);
          }
          // else if (response.status === 401) {
          //   window.location.href = "/logout";
          // }
        } catch (e) {
          console.log(e);
        }
      };
      useEffect(() => {
        fetchBranches();
      }, []);

      // User Creation setters and getters
      const [firstName, setFirstName] = useState(row.firstName);
      const [secondName, setSecondName] = useState(row.secondName);
      const [userName, setUserName] = useState(row.userName);
      const [userEmail, setUserEmail] = useState(row.userEmail);
      const [userPhone, setUserPhone] = useState(row.userPhone);
      const [userGroup, setUserGroup] = useState(row.userGroup);
      const [userBranch, setUserBranch] = useState("");

      console.log(userBranch , 'user branch');
      //Group Update Url
      const updateUrl = baseUrl + "/api/updateUser/" + row.id;
      const updateUserg = baseUrl + "/api/updateUser/" + row.userId;
      const deleteUrl = baseUrl + "/api/deleteUser/" + row.id;
      console.log(updateUrl);
      // Fetching groups
      const fetchGroups = async () => {
        try {
          const response = await fetch(baseUrl + "/api/get_business_roles", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          });
          const data = await response.json();
          console.log(data , 'groups found >>>>')
          if (response.status === 200) {
            setGroups(data.data);
          } else if (response.status === 401) {
            handleSessionOpen();
          }
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        fetchGroups();
      }, []);

      //Update Group Function
      const update = async (e) => {
        e.preventDefault();
        const editResponse = await fetch(updateUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          
          body: JSON.stringify({
            firstName: firstName,
            secondName: secondName,
            userName: userName,
            userEmail: userEmail,
            userPhone: userPhone,
            userGroup: userGroup,
            modifiedAtBy: localStorage.getItem("username"),
            modifiedAt: Date.now(),
          }),
        });
        if (editResponse.status === 202) {
          handleClose();
          handleSuccessOpen();
        } else if (editResponse.status === 401) {
          handleSessionOpen();
        }
      };

      const updateUser = async (e) => {
        e.preventDefault();
        const editResponse = await fetch(updateUserg, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({
            firstname: firstName,
            secondname: secondName,
            userName: userName,
            userEmail: userEmail,
            userPhone: userPhone,
            userGroup: userGroup,
            branchId:userBranch,
            modifiedAtBy: localStorage.getItem("username"),
            modifiedAt: Date.now(),
          }),
        });
        if (editResponse.status === 200) {
          handleClose();
          handleSuccessOpen();
        } else if (editResponse.status === 400) {
          handleSessionOpen();
        }
      };


      // Deactivate Groups Update Function
      const deactivateUpdate = useCallback(async (e) => {
        e.preventDefault();
        try {
          const deactivateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              userState: "Deactivation",
              requestedBy: localStorage.getItem("username"),
              deactivatedAt: Date.now(),
            }),
          });
          if (deactivateResponse.status === 202) {
            DeactivateModalHandleClose();
            successDeactivationModalHandleOpen();
          } else if (deactivateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      });

      return (
        <div>
          {/*sessionModal*/}
          <SessionModal
            onClose={handleSessionClose}
            sessionOpen={sessionOpen}
          />

          {/*Edit Group Modal*/}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box
                style={{
                  width: "100%",
                  borderRadius: "16px 16px 0px 0px",
                  backgroundColor: "#dc3545",
                  padding: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  Edit a User
                </span>
              </Box>

              <Box style={{ width: "100%", padding: "20px" }}>
                <form onSubmit={update}>
                  <Box style={{ width: "100%" }}>
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#b4bcc4",
                      }}
                    >
                      First Name
                    </label>
                    <Box>
                      <input
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        maxLength="25"
                        required
                        style={{
                          objectFit: "contain",
                          borderRadius: "4px",
                          border: "solid 1px #dfdede",
                          backgroundColor: "#fff",
                          padding: "5px",
                          outline: "none",
                          width: "80%",
                        }}
                        type="text"
                      />
                    </Box>
                  </Box>

                  <Box>
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        //   lineHeight: "3.14",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#b4bcc4",
                      }}
                    >
                      Second Name
                    </label>
                    <Box style={{ width: "100%" }}>
                      <input
                        value={secondName}
                        onChange={(e) => setSecondName(e.target.value)}
                        maxLength="15"
                        required
                        style={{
                          objectFit: "contain",
                          borderRadius: "4px",
                          border: "solid 1px #dfdede",
                          backgroundColor: "#fff",
                          padding: "5px",
                          outline: "none",
                          width: "80%",
                        }}
                        type="text"
                      />
                    </Box>
                  </Box>
                  <Box style={{ width: "100%" }}>
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        //   lineHeight: "3.14",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#b4bcc4",
                      }}
                    >
                      Username
                    </label>
                    <Box>
                      <input
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        maxLength="10"
                        style={{
                          objectFit: "contain",
                          borderRadius: "4px",
                          border: "solid 1px #dfdede",
                          backgroundColor: "#fff",
                          fontFamily: "Poppins",
                          padding:"5px",
                          width:"80%",
                          outline:"none"
                        }}
                        type="text"
                      />
                    </Box>
                  </Box>

                  <Box>
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        //   lineHeight: "3.14",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#b4bcc4",
                      }}
                    >
                      Email
                    </label>
                    <Box>
                      <input
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                        maxLength="50"
                        required
                        style={{
                          objectFit: "contain",
                          borderRadius: "4px",
                          border: "solid 1px #dfdede",
                          backgroundColor: "#fff",
                          fontFamily: "Poppins",
                          padding:"5px",
                          outline:"none",
                          width:"80%"
                        }}
                        type="email"
                      />
                    </Box>
                  </Box>

                  <Box>
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#b4bcc4",
                      }}
                    >
                      Phone No.
                    </label>
                    <br></br>
                    <input
                      readOnly
                      value="+254"
                      style={{
                        //   margin: "10px 0 0",
                        objectFit: "contain",
                        borderRadius: "4px",
                        border: "solid 1px #dfdede",
                        backgroundColor: "#fff",
                        fontFamily: "Poppins",
                        padding:"5px",
                        width:"40%",
                        outline:"none"
                      }}
                    />
                    {"\t"}
                    <input
                      value={userPhone?.replace("+254", "")}
                      required
                      onChange={(e) => setUserPhone(e.target.value)}
                      maxLength="9"
                      style={{
                        //   margin: "10px 0 0",
                        objectFit: "contain",
                        borderRadius: "4px",
                        border: "solid 1px #dfdede",
                        backgroundColor: "#fff",
                        fontFamily: "Poppins",
                        padding:"5px",
                        outline:"none",
                        width:"40%"
                      }}
                      type="text"
                    />
                  </Box>
                  <Box>
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",

                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#b4bcc4",
                      }}
                    >
                      User Group
                    </label>
                    <Box>
                      <select
                        value={userGroup}
                        onChange={(e) => setUserGroup(e.target.value)}
                        maxLength="25"
                        style={{
                          objectFit: "contain",
                          borderRadius: "4px",
                          border: "solid 1px #dfdede",
                          backgroundColor: "#fff",
                          fontFamily: "Poppins",
                          padding:"5px",
                          outline:"none",
                          width:"80%"
                        }}
                        type="text"
                      >
                        <option value={userGroup}>{userGroup}</option>
                        {groupOptions.map((groupData) => (
                          <option group={groupData.Id}>
                            {groupData.groupType}
                          </option>
                        ))}
                      </select>
                    </Box>
                  </Box>

                  <Box style={{margin:"0px 0px 10px 0px"}}>
                    <label
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",

                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#b4bcc4",
                      }}
                    >
                      {" "}
                      User Branch
                    </label>
                    <Box >
                      <select
                        value={userBranch}
                        onChange={(e) => setUserBranch(e.target.value)}
                        maxLength="25"
                        style={{
                          objectFit: "contain",
                          borderRadius: "4px",
                          border: "solid 1px #dfdede",
                          backgroundColor: "#fff",
                          fontFamily: "Poppins",
                          padding:"5px",
                          outline:"none",
                          width:"80%"
                        }}
                        type="text"
                      >
                        <option>Select Branch</option>
                        {branches.map((branchData) => (
                          <option value={branchData._id}>
                            {branchData.branchName}
                          </option>
                        ))}
                      </select>
                    </Box>
                  </Box>

                  <Box style={{width:"100%",display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <Button
                      type="close"
                      onClick={handleClose}
                      style={{
                        width: "90px",
                        height: "33.1px",
                        backgroundColor: "#dc3545",
                        color: "#fff",
                        margin:"0px 20px 0px 0px"
                      }}
                    >
                      Close
                    </Button>
                    <Button
                    onClick={updateUser}
                      type="submit"
                      style={{
                        width: "90px",
                        height: "33.1px",
                        backgroundColor: "#032541",
                        color: "#fff",
                        margin:"0px 0px 0px 20px"
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
          </Modal>

          {/*Deactivate Group Modal*/}
          <QuestionModal
            deactivateModalOpen={deactivateModalOpen}
            onClose={DeactivateModalHandleClose}
            formAction={deactivateUpdate}
            actionImage={deactivateGroup}
            action="Deactivate"
            item={row.userName}
            processName={"Deactivate"}
          />

          {/*Status Update Modals*/}
          {/*Success Deactivation Modal*/}
          <SuccessModal
            onClose={successDeactivationModalHandleClose}
            editConfigOpen={successDeactivationModalOpen}
            modalRow={row.userName}
            successObject={"User"}
            successType={"Deactivated"}
          />

          {/*Success Edit Groups*/}
          <SuccessModal
            onClose={handleSuccessClose}
            editConfigOpen={successOpen}
            modalRow={row.userName}
            successObject={"User"}
            successType={"Updated"}
          />

          {/*Action Button Menu*/}
          <Button
            sx={{ backgroundColor: "#dc3545" }}
            id="demo-customized-button"
            aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={ActionOpen ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Action
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
            anchorEl={anchorEl}
            open={ActionOpen}
            onClose={handleActionClose}
          >
            {/*Conditional Modal Popups*/}
            {/*Check if is on Active Tab*/}
            <MenuItem
              disableRipple
              onClick={() => {
                handleClickOpen();
                handleActionClose();
              }}
            >
              <EditIcon sx={{ color: "black" }} />
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                DeactivateModalHandleOpen();
                handleActionClose();
              }}
              disableRipple
            >
              <PersonRemoveIcon />
              Deactivate
            </MenuItem>
          </StyledMenu>
        </div>
      );
    },
  },
];

// New Columns
const newColumns = [
  // { field: 'id', headerName: 'ID' },
  {
    field: "fullName",
    headerName: "Full Name",
    align: "center",
    flex: 1,
    headerClassName: "super-app-theme-header",
    headerAlign: "center",
    editable: false,
  },
  {
    field: "userName",
    headerName: "Username",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userEmail",
    headerName: "Email",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userPhone",
    headerName: "Phone",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userGroup",
    headerName: "User Group",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: true,
  },
  {
    field: "userBranch",
    headerName: "User Branch",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: true,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "createdBy",
    headerName: "Created By",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },

  // Actions Button
  {
    field: "Actions",
    headerName: "Actions",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    renderCell: (params, event) => {
      //DropDown Handler
      const [anchorEl, setAnchorEl] = React.useState(null);
      const ActionOpen = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleActionClose = () => {
        setAnchorEl(null);
      };

      const { row } = params;

      // session Modal Config
      const [sessionOpen, setSessionOpen] = React.useState(false);
      const handleSessionOpen = () => setSessionOpen(true);
      const handleSessionClose = () => setSessionOpen(false);
      const handleLogin = () => {
        window.location.href = "/logout";
      };

      // Activate Group Modal Config
      const [ActivateModalOpen, setActivateModalOpen] = useState(false);
      const ActivateModalHandleOpen = () => setActivateModalOpen(true);
      const ActivateModalHandleClose = () => setActivateModalOpen(false);

      // Deactivate Group Modal Config
      const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
      const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
      const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

      // Status Updates Modals
      // Success Activation Modal Config
      const [successActivationModalOpen, setSuccessActivationModalOpen] =
        useState(false);
      const successActivationModalHandleOpen = () =>
        setSuccessActivationModalOpen(true);
      const successActivationModalHandleClose = () =>
        setSuccessActivationModalOpen(false);

      // Success Deactivation Modal Config
      const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] =
        useState(false);
      const successDeactivationModalHandleOpen = () =>
        setSuccessDeactivationModalOpen(true);
      const successDeactivationModalHandleClose = () =>
        setSuccessDeactivationModalOpen(false);

      //Group Update Url
      const updateUrl = baseUrl + "/api/updateUser/" + row.id;

      // Activate Groups Update Function
      const activateUpdate = async (e) => {
        e.preventDefault();
        try {
          const activateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              userState: "Active",
              modifiedAt: Date.now(),
              requestedBy: localStorage.getItem("username"),
            }),
          });
          if (activateResponse.status === 202) {
            ActivateModalHandleClose();
            successActivationModalHandleOpen();
          } else if (activateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      // Deactivate Groups Update Function
      const deactivateUpdate = async (e) => {
        e.preventDefault();
        try {
          const deactivateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              userState: "Deactivation",
              requestedBy: localStorage.getItem("username"),
              deactivatedAt: Date.now(),
              approvedBy: localStorage.getItem("username"),
            }),
          });
          if (deactivateResponse.status === 202) {
            DeactivateModalHandleClose();
            successDeactivationModalHandleOpen();
          } else if (deactivateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      return (
        <div>
          {/*sessionModal*/}
          <SessionModal
            onClose={handleSessionClose}
            sessionOpen={sessionOpen}
          />

          {/*Activate Group Modal*/}
          <QuestionModal
            deactivateModalOpen={ActivateModalOpen}
            onClose={ActivateModalHandleClose}
            formAction={activateUpdate}
            actionImage={activateGroup}
            action="Activate"
            item={row.userName}
            processName={"Activate"}
          />

          {/*Deactivate Group Modal*/}
          <QuestionModal
            deactivateModalOpen={deactivateModalOpen}
            onClose={DeactivateModalHandleClose}
            formAction={deactivateUpdate}
            actionImage={deactivateGroup}
            action="Deactivate"
            item={row.userName}
            processName={"Deactivate"}
          />

          {/*Status Update Modals*/}
          {/*Success Deactivation Modal*/}
          <SuccessModal
            onClose={successDeactivationModalHandleClose}
            editConfigOpen={successDeactivationModalOpen}
            modalRow={row.userName}
            successObject={"User"}
            successType={"Deactivated"}
          />

          {/*Success Activation Modal*/}
          <SuccessModal
            onClose={successActivationModalHandleClose}
            editConfigOpen={successActivationModalOpen}
            modalRow={row.userName}
            successObject={"User"}
            successType={"Activated"}
          />

          {/*Action Button Menu*/}
          <Button
            sx={{ backgroundColor: "#dc3545" }}
            id="demo-customized-button"
            aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={ActionOpen ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Action
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
            anchorEl={anchorEl}
            open={ActionOpen}
            onClose={handleActionClose}
          >
            <MenuItem
              disableRipple
              onClick={() => {
                ActivateModalHandleOpen();
                handleActionClose();
              }}
            >
              <EditIcon sx={{ color: "black" }} />
              Approve
            </MenuItem>
            <MenuItem
              onClick={() => {
                DeactivateModalHandleOpen();
                handleActionClose();
              }}
              disableRipple
            >
              <PersonRemoveIcon />
              Decline
            </MenuItem>
          </StyledMenu>
        </div>
      );
    },
  },
];

// Edited Columns
const editedColumns = [
  // { field: 'id', headerName: 'ID' },
  {
    field: "fullName",
    headerName: "Full Name",
    align: "center",
    flex: 1,
    headerClassName: "super-app-theme-header",
    headerAlign: "center",
    editable: false,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userName",
    headerName: "Username",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userEmail",
    headerName: "Email",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userPhone",
    headerName: "Phone",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userGroup",
    headerName: "User Group",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: true,
  },
  {
    field: "userBranch",
    headerName: "User Branch",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: true,
  },
  {
    field: "modifiedBy",
    headerName: "Edited By",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "modifiedAt",
    headerName: "Date Modified",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "businessName",
    headerName: "Business Name",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },

  // Action Button.
  {
    field: "Actions",
    headerName: "Actions",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    renderCell: (params, event) => {
      //DropDown Handler
      const [anchorEl, setAnchorEl] = React.useState(null);
      const ActionOpen = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleActionClose = () => {
        setAnchorEl(null);
      };

      const { row } = params;

      // session Modal Config
      const [sessionOpen, setSessionOpen] = React.useState(false);
      const handleSessionOpen = () => setSessionOpen(true);
      const handleSessionClose = () => setSessionOpen(false);
      const handleLogin = () => {
        window.location.href = "/logout";
      };

      // Activate Group Modal Config
      const [ActivateModalOpen, setActivateModalOpen] = useState(false);
      const ActivateModalHandleOpen = () => setActivateModalOpen(true);
      const ActivateModalHandleClose = () => setActivateModalOpen(false);

      // Edit Group Modal Config
      const [open, setOpen] = useState(false);
      const handleClickOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);

      // Deactivate Group Modal Config
      const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
      const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
      const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

      // DeactivateRequest Group Modal Config
      const [deactivateReqModalOpen, setDeactivateReqModalOpen] =
        useState(false);
      const DeactivateReqModalHandleOpen = () =>
        setDeactivateReqModalOpen(true);
      const DeactivateReqModalHandleClose = () =>
        setDeactivateReqModalOpen(false);

      // Delete Group Modal Config
      const [deleteModalOpen, setDeleteModalOpen] = useState(false);
      const DeleteModalHandleOpen = () => setDeleteModalOpen(true);
      const DeleteModalHandleClose = () => setDeleteModalOpen(false);

      // Status Updates Modals
      // Success Activation Modal Config
      const [successActivationModalOpen, setSuccessActivationModalOpen] =
        useState(false);
      const successActivationModalHandleOpen = () =>
        setSuccessActivationModalOpen(true);
      const successActivationModalHandleClose = () =>
        setSuccessActivationModalOpen(false);

      // Success Deactivation Modal Config
      const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] =
        useState(false);
      const successDeactivationModalHandleOpen = () =>
        setSuccessDeactivationModalOpen(true);
      const successDeactivationModalHandleClose = () =>
        setSuccessDeactivationModalOpen(false);

      // Success Deletion Modal Config
      const [successDeletionModalOpen, setSuccessDeletionModalOpen] =
        useState(false);
      const successDeletionModalHandleOpen = () =>
        setSuccessDeletionModalOpen(true);
      const successDeletionModalHandleClose = () =>
        setSuccessDeletionModalOpen(false);

      // Data Getters and Setters
      const [groupName, setGroupName] = useState(row.userName);
      const [createdAt, setCreatedAt] = useState(row.createdAt);
      const [userName, setuserName] = useState(row.userName);
      const [userEmail, setuserEmail] = useState(row.userEmail);

      //Group Update Url
      const updateUrl = baseUrl + "/api/updateUser/" + row.id;
      const deleteUrl = baseUrl + "/api/deleteUser/" + row.id;

      //Update Group Function
      //Update Group Function
      const update = async () => {
        const response = await fetch(updateUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
          body: JSON.stringify({
            groupName: groupName,
            userName: userName,
            userEmail: userEmail,
            createdAt: createdAt,
            requestedBy: localStorage.getItem("username"),
            modifiedAt: Date.now(),
          }),
        });
        const data = await response.json();
        if (response.status === 202) {
          console.log("Group Updated");
        } else if (response.status === 401) {
          handleSessionOpen();
        } else {
          alert("Error Updating Group");
        }
      };


      // Activate Groups Update Function
      const activateUpdate = async () => {
        try {
          const activateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              userState: "Active",
              modifiedAt: Date.now(),
              requestedBy: localStorage.getItem("username"),
            }),
          });
          const data = activateResponse.json();
          if (activateResponse.status === 202) {
            console.log("Updated!");
          } else if (activateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      // Deactivate Groups Update Function
      const deactivateUpdate = async () => {
        try {
          const deactivateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              userState: "Deactivation",
              requestedBy: localStorage.getItem("username"),
              deactivatedAt: Date.now(),
              approvedBy: localStorage.getItem("username"),
            }),
          });
        } catch (e) {
          console.log(e);
        }
      };

      // Deactivate Request Groups Update Function
      const deactivateReqUpdate = async () => {
        try {
          const deactivateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              userState: "Inactive",
              deactivatedAt: Date.now(),
              approvedBy: localStorage.getItem("username"),
            }),
          });
        } catch (e) {
          console.log(e);
        }
      };

      // Delete Groups Update Function
      const deleteUpdate = async () => {
        try {
          const deleteResponse = await fetch(deleteUrl, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          });
        } catch (e) {
          console.log(e);
        }
      };
      return (
        <div>
          {/*sessionModal*/}
          <SessionModal
            onClose={handleSessionClose}
            sessionOpen={sessionOpen}
          />

          {/*Edit Group Modal*/}
          <Modal
            className="container container-fluid"
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                width: "553px",
                height: "618px",
                margin: "9% auto 0px auto",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "553x",
                  height: "90px",
                  margin: "0 0 9px",
                  padding: "36px 30px 33px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <label>Edit a Group</label>
              </div>
              {/*Content Group and Form*/}
              <div
                align="center"
                style={{
                  width: "404px",
                  height: "414.1px",
                  margin: "29px 60px 41.9px 70px",
                }}
              >
                <form onSubmit={update}>
                  {/*Serial No.*/}
                  <label
                    style={{
                      height: "20px",
                      margin: "0 340px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Serial No.
                  </label>
                  <input
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                    placeholder="Serial"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*POS createdAt*/}
                  <label
                    style={{
                      width: "100px",
                      height: "20px",
                      margin: "0 328px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    POS createdAt.
                  </label>
                  <input
                    value={createdAt}
                    onChange={(e) => setCreatedAt(e.target.value)}
                    placeholder="createdAt"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*PayBill*/}
                  <label
                    style={{
                      width: "110px",
                      height: "20px",
                      margin: "0 304px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Pay Bill/Till No.
                  </label>
                  <input
                    value={userName}
                    onChange={(e) => setuserName(e.target.value)}
                    placeholder="userName"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*Description*/}
                  <label
                    style={{
                      width: "78px",
                      height: "20px",
                      margin: "0 324px 10px 0",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Description
                  </label>
                  <input
                    value={userEmail}
                    onChange={(e) => setuserEmail(e.target.value)}
                    placeholder="Description"
                    style={{
                      width: "404px",
                      height: "39px",
                      margin: "10px 0 0",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  />

                  {/*Buttons*/}
                  <div align="right">
                    {/*  Close  */}
                    <Button
                      onClick={handleClose}
                      variant="outlined"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        textAlign: "left",
                        color: "#032541",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 30px 0 0",
                        padding: "9px 29px 8.1px 30px",
                        border: "solid 1px #032541",
                      }}
                    >
                      Close
                    </Button>
                    {/*  save  */}
                    <Button
                      type="submit"
                      variant="standard"
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 0 0 30px",
                        padding: "9px 32px 8.1px 31px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          {/*Activate Group Modal*/}
          <Modal
            centered
            align={"center"}
            className="container container-fluid"
            open={ActivateModalOpen}
            onClose={ActivateModalHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                width: "553px",
                height: "306px",
                margin: "10% 23px 8px 39px",
                padding: "0 0 57.9px",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "553px",
                  height: "90px",
                  margin: "0 0 50px",
                  padding: "35px 36px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <label
                  style={{
                    width: "140px",
                    height: "20px",
                    margin: "0 325px 0 0",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Activate Group
                </label>
              </div>
              <div>
                <form onSubmit={activateUpdate}>
                  <img
                    style={{
                      width: "70.4px",
                      height: "70.4px",
                      objectFit: "contain",
                    }}
                    src={activateGroup}
                    alt={"activate Group"}
                  />
                  <label
                    style={{
                      height: "24px",
                      margin: "0 1.1px 0 14px",
                      fontFamily: "Poppins",
                      fontSize: "17px",
                      fontWeight: "500",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.47",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Do you want to Activate <b>{row.userName}?</b>
                  </label>
                  <div align="right">
                    <Button
                      onClick={ActivateModalHandleClose}
                      variant="outlined"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        textAlign: "left",
                        color: "#dc3545",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 30px 0 0",
                        padding: "9px 29px 8.1px 30px",
                        border: "solid 1px #dc3545",
                      }}
                    >
                      Cancel
                    </Button>
                    {/*  save  */}
                    <Button
                      type="submit"
                      variant="standard"
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 16px 0 30px",
                        padding: "9px 32px 8.1px 31px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Activate
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          {/*Deactivate Group Modal*/}
          <Modal
            centered
            align={"center"}
            className="container container-fluid"
            open={deactivateModalOpen}
            onClose={DeactivateModalHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                width: "553px",
                height: "306px",
                margin: "10% 23px 8px 39px",
                padding: "0 0 57.9px",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "553px",
                  height: "90px",
                  margin: "0 0 50px",
                  padding: "35px 36px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <label
                  style={{
                    height: "20px",
                    margin: "0 325px 0 0",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Deactivate Group
                </label>
              </div>
              <div>
                <form onSubmit={deactivateUpdate}>
                  <img
                    style={{
                      width: "70.4px",
                      height: "70.4px",
                      objectFit: "contain",
                    }}
                    src={deactivateGroup}
                    alt={"activate Group"}
                  />
                  <label
                    style={{
                      height: "24px",
                      margin: "0 1.1px 0 14px",
                      fontFamily: "Poppins",
                      fontSize: "17px",
                      fontWeight: "500",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.47",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Do you want to Deactivate <b>{row.userName}?</b>
                  </label>
                  <div align="right">
                    <Button
                      onClick={DeactivateModalHandleClose}
                      variant="outlined"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        textAlign: "left",
                        color: "#dc3545",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 30px 0 0",
                        padding: "9px 29px 8.1px 30px",
                        border: "solid 1px #dc3545",
                      }}
                    >
                      Cancel
                    </Button>
                    {/*  save  */}
                    <Button
                      type="submit"
                      variant="standard"
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 16px 0 30px",
                        padding: "9px 32px 8.1px 31px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Deactivate
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          {/*Deactivation Request Group Modal*/}
          <Modal
            centered
            align={"center"}
            className="container container-fluid"
            open={deactivateReqModalOpen}
            onClose={DeactivateReqModalHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                width: "665px",
                height: "604px",
                margin: "10% 47px 5px 96px",
                padding: "0 0 9px",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "665px",
                  height: "90px",
                  margin: "0 0 3px",
                  padding: "35px 30px 34px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <label
                  style={{
                    height: "20px",
                    margin: "0 325px 0 0",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Deactivate Group User Group
                </label>
              </div>
              <form onSubmit={deactivateReqUpdate}>
                <div>
                  <div>
                    <label
                      style={{
                        eight: "44px",
                        margin: "48px 106px 37px 84px",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                      }}
                    >
                      To deactivate Group <b>{row.userName}</b>, Select the
                      Reason and describe why you are deactivating.
                    </label>
                  </div>
                  <label
                    style={{
                      width: "41px",
                      height: "16px",
                      margin: "0px 537px 10px 84px",
                      fontFamily: "Poppins",
                      fontSize: "11px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "4",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Reason
                  </label>
                  <Select
                    value={reasonCode}
                    onChange={(e) => setReasonCode(e.target.value)}
                    defaultValue=""
                    id="grouped-select"
                    label="Grouping"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      textAlign: "left",
                      width: "496px",
                      height: "39px",
                      margin: "10px 85px 20px 84px",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {reasonData.map((reason) => (
                      <MenuItem
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                        key={reason.code}
                        value={reason.code}
                      >
                        {reason.code} {reason.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <label
                    style={{
                      width: "62px",
                      height: "16px",
                      margin: "0px 537px 10px 84px",
                      fontFamily: "Poppins",
                      fontSize: "11px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "4",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Description
                  </label>
                  <textarea
                    id="w3review"
                    name="w3review"
                    rows="4"
                    cols="50"
                    placeholder="Enter Description"
                    style={{
                      width: "505px",
                      height: "106px",
                      margin: "10px 76px 43px 84px",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  ></textarea>
                  <div align="right">
                    <Button
                      onClick={DeactivateReqModalHandleClose}
                      variant="outlined"
                      style={{
                        fontSize: "11px",
                        color: "#032541",
                        width: "90px",
                        height: "33.1px",
                        margin: "0px 30px 62.9px 13px",
                        padding: "9px 29px 8.1px 30px",
                        border: "solid 1px #032541",
                      }}
                    >
                      Cancel
                    </Button>
                    {/*  save  */}
                    <Button
                      type="submit"
                      variant="standard"
                      style={{
                        fontSize: "11px",
                        color: "#fff",
                        width: "90px",
                        height: "33.1px",
                        margin: "0px 76px 62.9px 30px",
                        padding: "9px 15px 8.1px 15px",
                        backgroundColor: "#dc3545",
                      }}
                    >
                      Deactivate
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Modal>

          {/*Delete Group Modal*/}
          <Modal
            centered
            align={"center"}
            className="container container-fluid"
            open={deleteModalOpen}
            onClose={DeleteModalHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                width: "553px",
                height: "306px",
                margin: "10% 23px 8px 39px",
                padding: "0 0 57.9px",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "553px",
                  height: "90px",
                  margin: "0 0 50px",
                  padding: "35px 36px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <label
                  style={{
                    height: "20px",
                    margin: "0 325px 0 0",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Delete Group
                </label>
              </div>
              <div>
                <form onSubmit={deleteUpdate}>
                  <img
                    style={{
                      width: "70.4px",
                      height: "70.4px",
                      objectFit: "contain",
                    }}
                    src={deactivateGroup}
                    alt={"activate Group"}
                  />
                  <label
                    style={{
                      height: "24px",
                      margin: "0 1.1px 0 14px",
                      fontFamily: "Poppins",
                      fontSize: "17px",
                      fontWeight: "500",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.47",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Do you want to delete <b>{row.userName}?</b>
                  </label>
                  <div align="right">
                    <Button
                      onClick={DeleteModalHandleClose}
                      variant="outlined"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        textAlign: "left",
                        color: "#032541",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 30px 0 0",
                        padding: "9px 29px 8.1px 30px",
                        border: "solid 1px #032541",
                      }}
                    >
                      Cancel
                    </Button>
                    {/*  save  */}
                    <Button
                      type="submit"
                      variant="standard"
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontSize: "11px",
                        width: "90px",
                        height: "33.1px",
                        margin: "30px 16px 0 30px",
                        padding: "9px 32px 8.1px 31px",
                        backgroundColor: "#dc3545",
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          {/*Status Update Modals*/}
          {/*Success Deactivation Modal*/}
          <Modal
            centered
            align={"center"}
            className="container container-fluid"
            open={successDeactivationModalOpen}
            onClose={successDeactivationModalHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                width: "506px",
                height: "506px",
                margin: "10% 16px 23px 45px",
                padding: "87px 75px 92.7px 34px",
                borderRadius: "36px",
                boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
                backgroundColor: "#fff",
              }}
            >
              <img src={success} alt={"success deactivation"} />
              <label
                style={{
                  width: "356px",
                  height: "65px",
                  margin: "37.8px 0 0",
                  fontFamily: "Poppins",
                  fontSize: "25px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.2",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: "#6e7074",
                }}
              >
                <b>{row.userName}</b> Deactivated successfully.
              </label>
              <Button
                onClick={successDeactivationModalHandleClose}
                style={{
                  fontFamily: "Poppins",
                  fontSize: "25px",
                  fontWeight: "600",
                  color: "#fff",
                  width: "124.5px",
                  height: "52.3px",
                  padding: "8px 44.5px 9.3px 35px",
                  backgroundColor: "#032541",
                }}
              >
                OK
              </Button>
            </div>
          </Modal>

          {/*Success Deletion Modal*/}
          <Modal
            centered
            align={"center"}
            className="container container-fluid"
            open={successDeletionModalOpen}
            onClose={successDeletionModalHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                width: "506px",
                height: "506px",
                margin: "10% 16px 23px 45px",
                padding: "87px 75px 92.7px 34px",
                borderRadius: "36px",
                boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
                backgroundColor: "#fff",
              }}
            >
              <img src={success} alt={"success deletion"} />
              <label
                style={{
                  width: "356px",
                  height: "65px",
                  margin: "37.8px 0 0",
                  fontFamily: "Poppins",
                  fontSize: "25px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.2",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: "#6e7074",
                }}
              >
                <b>{row.userName}</b> Deleted successfully.
              </label>
              <Button
                onClick={successDeletionModalHandleClose}
                style={{
                  fontFamily: "Poppins",
                  fontSize: "25px",
                  fontWeight: "600",
                  color: "#fff",
                  width: "124.5px",
                  height: "52.3px",
                  padding: "8px 44.5px 9.3px 35px",
                  backgroundColor: "#032541",
                }}
              >
                OK
              </Button>
            </div>
          </Modal>

          {/*Success Activation Modal*/}
          <Modal
            centered
            align={"center"}
            className="container container-fluid"
            open={successActivationModalOpen}
            onClose={successActivationModalHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                width: "506px",
                height: "506px",
                margin: "10% 16px 23px 45px",
                padding: "87px 75px 92.7px 34px",
                borderRadius: "36px",
                boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
                backgroundColor: "#fff",
              }}
            >
              <img src={success} alt={"success deletion"} />
              <label
                style={{
                  width: "356px",
                  height: "65px",
                  margin: "37.8px 0 0",
                  fontFamily: "Poppins",
                  fontSize: "25px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.2",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: "#6e7074",
                }}
              >
                <b>{row.userName}</b> Activated successfully.
              </label>
              <Button
                onClick={successActivationModalHandleClose}
                style={{
                  fontFamily: "Poppins",
                  fontSize: "25px",
                  fontWeight: "600",
                  color: "#fff",
                  width: "124.5px",
                  height: "52.3px",
                  padding: "8px 44.5px 9.3px 35px",
                  backgroundColor: "#032541",
                }}
              >
                OK
              </Button>
            </div>
          </Modal>

          {/*Action Button Menu*/}
          <Button
            sx={{ backgroundColor: "#dc3545" }}
            id="demo-customized-button"
            aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={ActionOpen ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Action
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
            anchorEl={anchorEl}
            open={ActionOpen}
            onClose={handleActionClose}
          >
            {/*Conditional Modal Popups*/}
            {/*Check if is on Active Tab*/}
            <If condition={localStorage.getItem("tabValue") === "Active"}>
              <MenuItem
                disableRipple
                onClick={() => {
                  handleClickOpen();
                  handleActionClose();
                }}
              >
                <EditIcon sx={{ color: "black" }} />
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  DeactivateModalHandleOpen();
                  handleActionClose();
                }}
                disableRipple
              >
                <PersonRemoveIcon />
                Deactivate
              </MenuItem>

              {/*Check if is on New Tab*/}
              <ElIf condition={localStorage.getItem("tabValue") === "New"}>
                <MenuItem
                  disableRipple
                  onClick={() => {
                    ActivateModalHandleOpen();
                    handleActionClose();
                  }}
                >
                  <EditIcon sx={{ color: "black" }} />
                  Approve
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    DeactivateModalHandleOpen();
                    handleActionClose();
                  }}
                  disableRipple
                >
                  <PersonRemoveIcon />
                  Decline
                </MenuItem>
              </ElIf>

              {/*Check if is on Inactive Tab*/}
              <ElIf condition={localStorage.getItem("tabValue") === "Inactive"}>
                <MenuItem
                  disableRipple
                  onClick={() => {
                    ActivateModalHandleOpen();
                    handleActionClose();
                  }}
                >
                  <EditIcon sx={{ color: "black" }} />
                  Activate
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    DeleteModalHandleOpen();
                    handleActionClose();
                  }}
                  disableRipple
                >
                  <PersonRemoveIcon />
                  Delete
                </MenuItem>
              </ElIf>

              {/*Check if is on Pending Tab*/}
              <ElIf condition={localStorage.getItem("tabValue") === "Pending"}>
                <MenuItem
                  disableRipple
                  onClick={() => {
                    handleClickOpen();
                    handleActionClose();
                  }}
                >
                  <EditIcon sx={{ color: "black" }} />
                  Activate
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleActionClose();
                  }}
                  disableRipple
                >
                  <PersonRemoveIcon />
                  Delete
                </MenuItem>
              </ElIf>

              {/*Check if is on Deactivation Tab*/}
              <ElIf
                condition={localStorage.getItem("tabValue") === "Deactivation"}
              >
                <MenuItem
                  disableRipple
                  onClick={() => {
                    DeactivateReqModalHandleOpen();
                    handleActionClose();
                  }}
                >
                  <EditIcon sx={{ color: "black" }} />
                  Approve
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    ActivateModalHandleOpen();
                    handleActionClose();
                  }}
                  disableRipple
                >
                  <PersonRemoveIcon />
                  Decline
                </MenuItem>
              </ElIf>
            </If>
          </StyledMenu>
        </div>
      );
    },
  },
];

// Deactivation request Columns
const deactivationColumns = [
  {
    field: "fullName",
    headerName: "Full Name",
    align: "center",
    flex: 1,
    headerClassName: "super-app-theme-header",
    headerAlign: "center",
    editable: false,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userName",
    headerName: "Username",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userEmail",
    headerName: "Email",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userPhone",
    headerName: "Phone",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userGroup",
    headerName: "User Group",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: true,
  },
  {
    field: "userBranch",
    headerName: "User Branch",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: true,
  },
  {
    field: "requestedBy",
    headerName: "Requested By",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "deactivatedAt",
    headerName: "Date Deactivated",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "businessName",
    headerName: "Business Name",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },

  {
    field: "Actions",
    headerName: "Actions",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    renderCell: (params, event) => {
      //DropDown Handler
      const [anchorEl, setAnchorEl] = React.useState(null);
      const ActionOpen = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleActionClose = () => {
        setAnchorEl(null);
      };

      const { row } = params;

      // session Modal Config
      const [sessionOpen, setSessionOpen] = React.useState(false);
      const handleSessionOpen = () => setSessionOpen(true);
      const handleSessionClose = () => setSessionOpen(false);
      const handleLogin = () => {
        window.location.href = "/logout";
      };

      // Activate Group Modal Config
      const [ActivateModalOpen, setActivateModalOpen] = useState(false);
      const ActivateModalHandleOpen = () => setActivateModalOpen(true);
      const ActivateModalHandleClose = () => setActivateModalOpen(false);

      // Deactivate Group Modal Config
      const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
      const DeactivateModalHandleOpen = () => setDeactivateModalOpen(true);
      const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);

      // DeactivateRequest Group Modal Config
      const [deactivateReqModalOpen, setDeactivateReqModalOpen] =
        useState(false);
      const DeactivateReqModalHandleOpen = () =>
        setDeactivateReqModalOpen(true);
      const DeactivateReqModalHandleClose = () =>
        setDeactivateReqModalOpen(false);

      // Status Updates Modals
      // Success Activation Modal Config
      const [successActivationModalOpen, setSuccessActivationModalOpen] =
        useState(false);
      const successActivationModalHandleOpen = () =>
        setSuccessActivationModalOpen(true);
      const successActivationModalHandleClose = () =>
        setSuccessActivationModalOpen(false);

      // Success Deactivation Modal Config
      const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] =
        useState(false);
      const successDeactivationModalHandleOpen = () =>
        setSuccessDeactivationModalOpen(true);
      const successDeactivationModalHandleClose = () =>
        setSuccessDeactivationModalOpen(false);

      //Group Update Url
      const updateUrl = baseUrl + "/api/updateUser/" + row.id;
      //value Setters and Getters
      const [reasonData, setReasonData] = useState([]);
      const [reasonCode, setReasonCode] = useState("");
      const [reasonDescription, setReasonDescription] = useState("");

      //Reason Update Url
      const reasonUrl = baseUrl + "/api/reasons";

      // Getting Reason Codes
      const getReasonCodes = async () => {
        try {
          const response = await fetch(reasonUrl, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          });
          const data = await response.json();
          if (response.status === 200) {
            setReasonData(data.reasons);
          } else if (response.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };
      useEffect(() => {
        getReasonCodes();
      }, []);

      // Activate Groups Update Function
      const activateUpdate = async (e) => {
        e.preventDefault();
        try {
          const activateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              userState: "Active",
              modifiedAt: Date.now(),
              requestedBy: localStorage.getItem("username"),
            }),
          });
          if (activateResponse.status === 202) {
            ActivateModalHandleClose();
            successActivationModalHandleOpen();
          } else if (activateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      // Deactivate Groups Update Function
      const deactivateUpdate = async (e) => {
        e.preventDefault();
        try {
          const deactivateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              userState: "Deactivation",
              requestedBy: localStorage.getItem("username"),
              deactivatedAt: Date.now(),
              approvedBy: localStorage.getItem("username"),
            }),
          });
          if (deactivateResponse.status === 202) {
            DeactivateModalHandleClose();
            successDeactivationModalHandleOpen();
          } else if (deactivateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      // Deactivate Request Groups Update Function
      const deactivateReqUpdate = async (e) => {
        e.preventDefault();
        try {
          const deactivateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              userState: "Inactive",
              deactivationCode: reasonCode,
              deactivationDescription: reasonDescription,
              deactivatedAt: Date.now(),
              approvedBy: localStorage.getItem("username"),
            }),
          });
          if (deactivateResponse.status === 202) {
            DeactivateReqModalHandleClose();
            successDeactivationModalHandleOpen();
          } else if (deactivateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      return (
        <div>
          {/*sessionModal*/}
          <SessionModal
            onClose={handleSessionClose}
            sessionOpen={sessionOpen}
          />

          {/*Activate Group Modal*/}
          <QuestionModal
            deactivateModalOpen={ActivateModalOpen}
            onClose={ActivateModalHandleClose}
            formAction={activateUpdate}
            actionImage={activateGroup}
            action="Activate"
            item={row.userName}
            processName={"Activate"}
          />

          {/*Deactivate Group Modal*/}
          <QuestionModal
            deactivateModalOpen={deactivateModalOpen}
            onClose={DeactivateModalHandleClose}
            formAction={deactivateUpdate}
            actionImage={deactivateGroup}
            action="Deactivate"
            item={row.userName}
            processName={"Deactivate"}
          />

          {/*Deactivation Request Group Modal*/}
          <Modal
            centered
            align={"center"}
            className="container container-fluid"
            open={deactivateReqModalOpen}
            onClose={DeactivateReqModalHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div
              style={{
                width: "665px",
                height: "604px",
                margin: "10% 47px 5px 96px",
                padding: "0 0 9px",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  width: "665px",
                  height: "90px",
                  margin: "0 0 3px",
                  padding: "35px 30px 34px",
                  borderRadius: "16px",
                  backgroundColor: "#dc3545",
                }}
              >
                <label
                  style={{
                    height: "20px",
                    margin: "0 325px 0 0",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Deactivate Group User Group
                </label>
              </div>
              <form onSubmit={deactivateReqUpdate}>
                <div>
                  <div>
                    <label
                      style={{
                        eight: "44px",
                        margin: "48px 106px 37px 84px",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                      }}
                    >
                      To deactivate Group <b>{row.userName}</b>, Select the
                      Reason and describe why you are deactivating.
                    </label>
                  </div>
                  <label
                    style={{
                      width: "41px",
                      height: "16px",
                      margin: "0px 537px 10px 84px",
                      fontFamily: "Poppins",
                      fontSize: "11px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "4",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Reason
                  </label>
                  <Select
                    value={reasonCode}
                    onChange={(e) => setReasonCode(e.target.value)}
                    defaultValue=""
                    id="grouped-select"
                    label="Grouping"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      textAlign: "left",
                      width: "496px",
                      height: "39px",
                      margin: "10px 85px 20px 84px",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  >
                    <MenuItem
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                      value=""
                    >
                      <em>None</em>
                    </MenuItem>
                    {reasonData.map((reason) => (
                      <MenuItem
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          textAlign: "left",
                        }}
                        key={reason.code}
                        value={reason.code}
                      >
                        {reason.code} {reason.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <label
                    style={{
                      width: "62px",
                      height: "16px",
                      margin: "0px 537px 10px 84px",
                      fontFamily: "Poppins",
                      fontSize: "11px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "4",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Description
                  </label>
                  <textarea
                    value={reasonDescription}
                    onChange={(e) => setReasonDescription(e.target.value)}
                    id="w3review"
                    name="w3review"
                    rows="4"
                    cols="50"
                    placeholder="Enter Description"
                    style={{
                      padding: "10px",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      textAlign: "left",
                      width: "505px",
                      height: "106px",
                      margin: "10px 76px 43px 84px",
                      objectFit: "contain",
                      borderRadius: "4px",
                      border: "solid 1px #dfdede",
                      backgroundColor: "#fff",
                    }}
                  ></textarea>
                  <div align="right">
                    <Button
                      onClick={DeactivateReqModalHandleClose}
                      variant="outlined"
                      style={{
                        fontSize: "11px",
                        color: "#032541",
                        width: "90px",
                        height: "33.1px",
                        margin: "0px 30px 62.9px 13px",
                        padding: "9px 29px 8.1px 30px",
                        border: "solid 1px #032541",
                      }}
                    >
                      Cancel
                    </Button>
                    {/*  save  */}
                    <Button
                      type="submit"
                      variant="standard"
                      style={{
                        fontSize: "11px",
                        color: "#fff",
                        width: "90px",
                        height: "33.1px",
                        margin: "0px 76px 62.9px 30px",
                        padding: "9px 15px 8.1px 15px",
                        backgroundColor: "#dc3545",
                      }}
                    >
                      Deactivate
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Modal>

          {/*Status Update Modals*/}
          {/*Success Deactivation Modal*/}
          <SuccessModal
            onClose={successDeactivationModalHandleClose}
            editConfigOpen={successDeactivationModalOpen}
            modalRow={row.userName}
            successObject={"User"}
            successType={"Deactivated"}
          />

          {/*Success Activation Modal*/}
          <SuccessModal
            onClose={successActivationModalHandleClose}
            editConfigOpen={successActivationModalOpen}
            modalRow={row.userName}
            successObject={"User"}
            successType={"Activated"}
          />

          {/*Action Button Menu*/}
          <Button
            sx={{ backgroundColor: "#dc3545" }}
            id="demo-customized-button"
            aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={ActionOpen ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Action
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
            anchorEl={anchorEl}
            open={ActionOpen}
            onClose={handleActionClose}
          >
            <MenuItem
              disableRipple
              onClick={() => {
                DeactivateReqModalHandleOpen();
                handleActionClose();
              }}
            >
              <EditIcon sx={{ color: "black" }} />
              Approve
            </MenuItem>
            <MenuItem
              onClick={() => {
                ActivateModalHandleOpen();
                handleActionClose();
              }}
              disableRipple
            >
              <PersonRemoveIcon />
              Decline
            </MenuItem>
          </StyledMenu>
        </div>
      );
    },
  },
];

// Inactive Columns
const inactiveColumns = [
  // { field: 'id', headerName: 'ID' },
  {
    field: "fullName",
    headerName: "Full Name",
    align: "center",
    flex: 1,
    headerClassName: "super-app-theme-header",
    headerAlign: "center",
    editable: false,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userName",
    headerName: "Username",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userEmail",
    headerName: "Email",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userPhone",
    headerName: "Phone",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "userGroup",
    headerName: "User Group",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: true,
  },
  {
    field: "userBranch",
    headerName: "User Branch",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: true,
  },
  {
    field: "requestedBy",
    headerName: "Requested By",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "deactivatedAt",
    headerName: "Date Deactivated",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "approvedBy",
    headerName: "Approved By",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },
  {
    field: "businessName",
    headerName: "Business Name",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
  },

  // Action Button.
  {
    field: "Actions",
    headerName: "Actions",
    flex: 1,
    headerAlign: "center",
    align: "center",
    editable: false,
    renderCell: (params, event) => {
      //DropDown Handler
      const [anchorEl, setAnchorEl] = React.useState(null);
      const ActionOpen = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleActionClose = () => {
        setAnchorEl(null);
      };

      const { row } = params;

      // session Modal Config
      const [sessionOpen, setSessionOpen] = React.useState(false);
      const handleSessionOpen = () => setSessionOpen(true);
      const handleSessionClose = () => setSessionOpen(false);
      const handleLogin = () => {
        window.location.href = "/logout";
      };

      // Activate Group Modal Config
      const [ActivateModalOpen, setActivateModalOpen] = useState(false);
      const ActivateModalHandleOpen = () => setActivateModalOpen(true);
      const ActivateModalHandleClose = () => setActivateModalOpen(false);

      // Delete Group Modal Config
      const [deleteModalOpen, setDeleteModalOpen] = useState(false);
      const DeleteModalHandleOpen = () => setDeleteModalOpen(true);
      const DeleteModalHandleClose = () => setDeleteModalOpen(false);

      // Status Updates Modals
      // Success Activation Modal Config
      const [successActivationModalOpen, setSuccessActivationModalOpen] =
        useState(false);
      const successActivationModalHandleOpen = () =>
        setSuccessActivationModalOpen(true);
      const successActivationModalHandleClose = () =>
        setSuccessActivationModalOpen(false);

      // Success Deletion Modal Config
      const [successDeletionModalOpen, setSuccessDeletionModalOpen] =
        useState(false);
      const successDeletionModalHandleOpen = () =>
        setSuccessDeletionModalOpen(true);
      const successDeletionModalHandleClose = () =>
        setSuccessDeletionModalOpen(false);

      //Group Update Url
      const updateUrl = baseUrl + "/api/updateUser/" + row.id;
      const deleteUrl = baseUrl + "/api/deleteUser/" + row.id;

      // Activate Groups Update Function
      const activateUpdate = async (e) => {
        e.preventDefault();
        try {
          const activateResponse = await fetch(updateUrl, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({
              userState: "Active",
              modifiedAt: Date.now(),
              requestedBy: localStorage.getItem("username"),
            }),
          });
          if (activateResponse.status === 202) {
            ActivateModalHandleClose();
            successActivationModalHandleOpen();
          } else if (activateResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      // Delete Groups Update Function
      const deleteUpdate = async (e) => {
        e.preventDefault();
        try {
          const deleteResponse = await fetch(deleteUrl, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          });
          if (deleteResponse.status === 202) {
            DeleteModalHandleClose();
            successDeletionModalHandleOpen();
          } else if (deleteResponse.status === 401) {
            handleSessionOpen();
          }
        } catch (e) {
          console.log(e);
        }
      };

      return (
        <div>
          {/*sessionModal*/}
          <SessionModal
            onClose={handleSessionClose}
            sessionOpen={sessionOpen}
          />

          {/*Activate Group Modal*/}
          <QuestionModal
            deactivateModalOpen={ActivateModalOpen}
            onClose={ActivateModalHandleClose}
            formAction={activateUpdate}
            actionImage={activateGroup}
            action="Activate"
            item={row.userName}
            processName={"Activate"}
          />

          {/*Delete Group Modal*/}
          <QuestionModal
            deactivateModalOpen={deleteModalOpen}
            onClose={DeleteModalHandleClose}
            formAction={deleteUpdate}
            actionImage={deactivateGroup}
            action="Delete"
            item={row.userName}
            processName={"Delete"}
          />

          {/*Success Deletion Modal*/}
          <SuccessModal
            onClose={successDeletionModalHandleClose}
            editConfigOpen={successDeletionModalOpen}
            modalRow={row.userName}
            successObject={"User"}
            successType={"Deleted"}
          />

          {/*Success Activation Modal*/}
          <SuccessModal
            onClose={successActivationModalHandleClose}
            editConfigOpen={successActivationModalOpen}
            modalRow={row.userName}
            successObject={"User"}
            successType={"Activated"}
          />

          {/*Action Button Menu*/}
          <Button
            sx={{ backgroundColor: "#dc3545" }}
            id="demo-customized-button"
            aria-controls={ActionOpen ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={ActionOpen ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Action
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
            anchorEl={anchorEl}
            open={ActionOpen}
            onClose={handleActionClose}
          >
            {/*Check if is on Inactive Tab*/}
            <MenuItem
              disableRipple
              onClick={() => {
                ActivateModalHandleOpen();
                handleActionClose();
              }}
            >
              <EditIcon sx={{ color: "black" }} />
              Activate
            </MenuItem>
            <MenuItem
              onClick={() => {
                DeleteModalHandleOpen();
                handleActionClose();
              }}
              disableRipple
            >
              <PersonRemoveIcon />
              Delete
            </MenuItem>
          </StyledMenu>
        </div>
      );
    },
  },
];

// App Entry
const Users = () => {
  // session Modal Config
  const [sessionOpen, setSessionOpen] = React.useState(false);
  const handleSessionOpen = () => setSessionOpen(true);
  const handleSessionClose = () => setSessionOpen(false);
  const handleLogin = () => {
    window.location.href = "/logout";
  };

  // user Adding modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // handle tab Changes
  const [tabValue, setTabValue] = React.useState("Active");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const [dataGridPageSize, setNewPageSize] = useState(10)

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: dataGridPageSize,
    // search : searchValue
  });

  // User Creation setters and getters
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userGroup, setUserGroup] = useState("");
  const [assignedBusiness, setAssignedBusiness] = useState("");

  // Groups Setters and Getters
  const [groups, setGroups] = useState([]);
  const [businessRoles, setBusinessRoles] = useState([]);
  const bsCategory = localStorage.getItem('businessCategory')
  console.log('bsCategory...data', bsCategory)

  function getBusinessRoles() {
    try {
        HttpComponent({
            method: 'POST',
            url: `/api/get_business_roles`,
            token: localStorage.getItem('X-Authorization')
        }).then((data) => {
            if (data?.status === 200) {
                if (data.status === 200) {
                    setBusinessRoles(data.response?.data)
                }
            }
        })
    } catch (error) {
        console.warn(error)
    }
}
useEffect(() => {
    getBusinessRoles();
}, [])

  const normalBsRole = [
    {
        key: 'Owner',
        role: "Owner"
    },
    {
        key: 'Cashier',
        role: "Cashier"
    },
    {
        key: 'Supervisor',
        role: "Supervisor"
    },
    {
        key: 'StoreOperator',
        role: 'StoreOperator'
    },
    {
        key: 'Accountant',
        role: "Accountant"
    },
    {
        key:'Verifier',
        role:'Verifier'
    },
    {
        key:'StoreManager',
        role:'StoreManager'
    }
]
const RentalBsRoles = [
    {
        key: 'Landlord',
        role: "Landlord"
    },
    {
        key: 'Tenant',
        role: "Tenant"
    },
    {
        key: 'Caretaker',
        role: "Caretaker"
    }
]

const SchoolBsRoles = [
    {
        key: 'Principal',
        role: "Principal"
    },
    {
        key: 'Teacher',
        role: "Teacher"
    },
    {
        key: 'Cashier',
        role: "Cashier"
    },
    {
        key: 'Director',
        role: "Director"
    },
    {
        key: 'Accountant',
        role: "Accountant"
    },
    {
      key:'ZPM School Admin',
      role:'ZPM School Admin'
    }
]

  const options = bsCategory === 'Rental' ? RentalBsRoles : bsCategory === 'School' ? SchoolBsRoles : normalBsRole
  console.log('options....data', options)

  // Tabs Setters and Getters
  const [activeTab, setActiveTabs] = useState([]);
  const [newTab, setNewTabs] = useState([]);
  const [inactiveTab, setInactiveTab] = useState([]);
  const [pendingTab, setPendingTab] = useState([]);
  const [deactivationTab, setDeactivationTabs] = useState([]);

  // Success Deactivation Modal Config
  const [successDeactivationModalOpen, setSuccessDeactivationModalOpen] =
    useState(false);
  const successDeactivationModalHandleOpen = () =>
    setSuccessDeactivationModalOpen(true);
  const successDeactivationModalHandleClose = () =>
    setSuccessDeactivationModalOpen(false);

  // Existing user Modal Config
  // Success Deactivation Modal Config
  const [existingUserModal, setExistingUserModalOpen] = useState(false);
  const existingUserModalHandleOpen = () => setExistingUserModalOpen(true);
  const existingUserModalHandleClose = () => setExistingUserModalOpen(false);

  // Urls
  const fetchUrl = baseUrl + "/api/listBusinessUsers";
  // {{baseUrl}}ACTIVE/?limit=10&page=1
  const postUrl = baseUrl + "/api/createUser";
  const newUsr = baseUrl + "/api/addNewUser"
  const [totalUsers, setTotalUsers] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");

  // Get all users
  // Check if Tab is on Active
  if (tabValue === "Active") {
    // Fetch Active Tabs
    const ActiveTab = async () => {
      try {
        const ActiveResponse = await fetch(baseUrl + `/api/listUsers/Active?limit=${dataGridPageSize}&page=${pageState.page}&searchValue=${searchValue}`, {          
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });
        const data = await ActiveResponse.json();
        if (ActiveResponse.status === 200) {
          setActiveTabs(data.data);
          setTotalUsers(data.count);
          setPageState((old) => ({
                        ...old,
                        isLoading: false,
                    }));
          console.log(data.data);
          localStorage.setItem("tabValue", "Active");
        } else if (ActiveResponse.status === 401) {
          handleSessionOpen();
        }
      } catch (e) {
        console.log(e);
      }
    };
    useEffect(() => {
      ActiveTab();
    }, [tabValue, pageState.page, dataGridPageSize, searchValue]);
  }

  // Check if tab is on New
  else if (tabValue === "New") {
    // Fetch New Tabs yet to be Approved
    const NewTabs = async () => {
      try {
        const newResponse = await fetch(fetchUrl + "/New", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });
        const data = await newResponse.json();
        console.log(data , 'new user')
        if (newResponse.status === 200) {
          setNewTabs(data.data);
          localStorage.setItem("tabValue", "New");
        } else if (newResponse.status === 401) {
          handleSessionOpen();
        }
      } catch (e) {
        console.log(e);
      }
    };
    useEffect(() => {
      NewTabs();
    }, [tabValue]);
  }

  // Check if tab is on Pending
  else if (tabValue === "Pending") {
    // Fetch Edits Approval Tabs
    const PendingTabs = async () => {
      try {
        const inactiveResponse = await fetch(fetchUrl + "/Pending", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });
        const data = await inactiveResponse.json();
        if (inactiveResponse.status === 200) {
          setPendingTab(data.data);
          localStorage.setItem("tabValue", "Pending");
        } else if (inactiveResponse.status === 401) {
          handleSessionOpen();
        }
      } catch (e) {
        console.log(e);
      }
    };
    useEffect(() => {
      PendingTabs();
    }, [tabValue]);
  }

  // Check if tab is on Deactivation
  else if (tabValue === "Deactivation") {
    // Fetch Pending Deactivation Tabs
    const DeactivationTabs = async () => {
      try {
        const inactiveResponse = await fetch(fetchUrl + "/Deactivation", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });
        const data = await inactiveResponse.json();
        if (inactiveResponse.status === 200) {
          setDeactivationTabs(data.data);
          localStorage.setItem("tabValue", "Deactivation");
        } else if (inactiveResponse.status === 401) {
          handleSessionOpen();
        }
      } catch (e) {
        console.log(e);
      }
    };
    useEffect(() => {
      DeactivationTabs();
    }, [tabValue]);
  }

  // Check if tab is on Inactive
  else if (tabValue === "Inactive") {
    // Fetch Deactivated Tabs
    const InactiveTabs = async () => {
      try {
        const inactiveResponse = await fetch(fetchUrl + "/Inactive", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });
        const data = await inactiveResponse.json();
        if (inactiveResponse.status === 200) {
          setInactiveTab(data.data);
          localStorage.setItem("tabValue", "Inactive");
        } else if (inactiveResponse.status === 401) {
          handleSessionOpen();
        }
      } catch (e) {
        console.log(e);
      }
    };
    useEffect(() => {
      InactiveTabs();
    }, [tabValue]);
  }

  console.log("Active USers", activeTab);

  // user Creation
  async function userAddHandler(e) {
    e.preventDefault();
    try {
      const addResponse = await fetch(`${postUrl}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          firstName: firstName,
          secondName: secondName,
          userName: userName,
          userEmail: userEmail,
          userPhone: "0" + userPhone,
          userGroup: userGroup,
          createdBy: localStorage.getItem("username"),
          assignedBusiness: localStorage.getItem("businessId"), // businessId
          assignToBusiness: "assignToBusiness",
          userState: "Active",
          loginState: "Active",
          businessShortCode: "",
          businessName: localStorage.getItem('businessName'),
          Equitel: "",
          Paybill: "",
          Till: "",
          Vooma: "",
        }),
      });
      if (addResponse.status === 201) {
        handleClose();
        successDeactivationModalHandleOpen();
        setFirstName("");
        setSecondName("");
        setUserName("");
        setUserEmail("");
        setUserPhone("");
        setUserGroup("");
      } else if (addResponse.status === 505) {
        handleClose();
        existingUserModalHandleOpen();
      } else if (addResponse.status === 401) {
        handleSessionOpen();
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Fetching groups
  const fetchGroups = async () => {
    try {
      const response = await fetch(baseUrl + "/api/viewAllGroups/Active", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        setGroups(data.data);
      } else if (response.status === 401) {
        handleSessionOpen();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchGroups();
  }, []);

  // mapping groups
  const groupOptions = options.map((group) => {
    return {
      groupName: group.name,
      groupType: group.name,
    };
  });
  

  // User Mapping to Data-grids
  // Active Tabs Row Data
  const activeRowData = activeTab.map((activeTabMap) => {
    return {
      id: activeTabMap._id,
      firstName: activeTabMap.firstName,
      secondName: activeTabMap.secondName,
      userName: activeTabMap.userName,
      userEmail: activeTabMap.userEmail,
      userPhone: activeTabMap.userPhone,
      userGroup: activeTabMap.userRole,
      createdAt: activeTabMap.createdAt,
      userId:   activeTabMap.userId,
      userBranch: activeTabMap.branchName,
    };
  });

  // New Tabs Row Data
  const newRowData = newTab.map((newTabMap) => {
    return {
      id: newTabMap._id,
      firstName: newTabMap.firstName,
      secondName: newTabMap.secondName,
      fullName: newTabMap.firstName + " " + newTabMap.secondName,
      userName: newTabMap.userName,
      userEmail: newTabMap.userEmail,
      userPhone: newTabMap.userPhone,
      userGroup: newTabMap.userRole,
      createdAt: newTabMap.createdAt,
      createdBy: newTabMap.createdBy,
    };
  });

  // Inactive Tabs Row Data
  const inactiveRowData = inactiveTab.map((inactiveTabMap) => {
    return {
      id: inactiveTabMap._id,
      firstName: inactiveTabMap.firstName,
      secondName: inactiveTabMap.secondName,
      fullName: inactiveTabMap.firstName + " " + inactiveTabMap.secondName,
      userName: inactiveTabMap.userName,
      userEmail: inactiveTabMap.userEmail,
      userPhone: inactiveTabMap.userPhone,
      userGroup: inactiveTabMap.userRole,
      createdAt: inactiveTabMap.createdAt,
      deactivatedAt: inactiveTabMap.deactivatedAt,
      requestedBy: inactiveTabMap.requestedBy,
      approvedBy: inactiveTabMap.approvedBy,
    };
  });

  // Pending Tabs Row Data
  const pendingRowData = pendingTab.map((pendingTabMap) => {
    return {
      id: pendingTabMap._id,
      firstName: pendingTabMap.firstName,
      secondName: pendingTabMap.secondName,
      fullName: pendingTabMap.firstName + " " + pendingTabMap.secondName,
      userName: pendingTabMap.userName,
      userEmail: pendingTabMap.userEmail,
      userPhone: pendingTabMap.userPhone,
      userGroup: pendingTabMap.userRole,
      createdAt: pendingTabMap.createdAt,
      modifiedAt: pendingTabMap.modifiedAt,
      modifiedBy: pendingTabMap.modifiedBy,
    };
  });

  // Deactivation Tabs Row Data
  const deactivationRowData = deactivationTab.map((deactivateTabMap) => {
    return {
      id: deactivateTabMap._id,
      firstName: deactivateTabMap.firstName,
      secondName: deactivateTabMap.secondName,
      fullName: deactivateTabMap.firstName + " " + deactivateTabMap.secondName,
      userName: deactivateTabMap.userName,
      userEmail: deactivateTabMap.userEmail,
      userPhone: deactivateTabMap.userPhone,
      userGroup: deactivateTabMap.userRole,
      createdAt: deactivateTabMap.createdAt,
      deactivatedAt: deactivateTabMap.deactivatedAt,
      requestedBy: deactivateTabMap.requestedBy,
    };
  });

  // page size setter and getter
  // const [pageSize, setPageSize] = React.useState(10);
  console.log('pageSize', pageSize)
  // const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const [dValue, onDValueChange] = useState([
    moment(new Date()).format("YYYY-MM-DD"),
    // moment(addDays(new Date(), 1)).format("YYYY-MM-DD"),
  ]);

  useEffect(() => {
    let filteredData = activeRowData;

    filteredData = filterBySearchBox(filteredData);
    setFilteredData(filteredData);
  }, [tabValue, searchValue]);

  useEffect(() => {
    let filteredData = activeRowData;

    filteredData = filterBySearchBox(filteredData);
    setFilteredData(filteredData);
    // console.log("uE", filteredData)
  }, [activeTab, searchValue]);

  const handleSearchBox = (event) => {
    setSearchValue(event.target.value);
    console.log(">>>>>>>>>", searchValue);
  };

  // const filterBySearchBox = (data) => {
  //   return data.filter((item) => {
  //     if (
  //       item.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
  //       item.secondName.toLowerCase().includes(searchValue.toLowerCase()) ||
  //       item.userName.toLowerCase().includes(searchValue.toLowerCase())
  //     ) {
  //       return item;
  //     }
  //   });
  // };
  
  const filterBySearchBox = (data) => {
    return data.filter((query) => {
      if (searchValue === "zed") {
        setSearchValue("")
        return activeRowData;
      } else if (query.firstName.toLowerCase().includes(searchValue.toLowerCase())) {
        return query;
      } else if (query.secondName.toLowerCase().includes(searchValue.toLowerCase())) {
        return query;
      } else if (query.userEmail.toLowerCase().includes(searchValue.toLowerCase())) {
        return query;
      } else if (query.userName.toLowerCase().includes(searchValue.toLowerCase())) {
        return query;
      } else if (query.userPhone.toLowerCase().includes(searchValue.toLowerCase())) {
        return query;
      }
    });
  };

  // useEffect(() => {
  //   let filteredData = activeRowData;
  //   filteredData = filterBySearchBox(filteredData);
  //   setFilteredData(filteredData);
  // }, [searchValue]);

  // CSV Export Format
  const csvColumns = [
    { label: "First Name", key: "firstName" },
    { label: "Second Name", key: "secondName" },
    { label: "Username", key: "userName" },
    { label: "Email", key: "userEmail" },
    { label: "Phone Number", key: "userPhone" },
    { label: "User Group", key: "userGroup" },
  ];

  // CSV Export
  const csvReport = {
    data: filteredData,
    headers: csvColumns,
    filename:
      // paymentType.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.csv`,
      "USERS" + `_${dValue[0]}_report.csv`,
  };

  // columns selection for Excel and PDF Export
  const pdfData = filteredData.map((user) => {
    return {
      firstName: user.firstName,
      SecondName: user.secondName,
      userName: user.userName,
      email: user.userEmail,
      phoneNumber: user.userPhone,
      userGroup: user.userGroup,
    };
  });

  //Excel Export Format
  const xlsxReport = (e, data, filteredData) => {
    // console.log(data);
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

    /* generate XLSX file and send to client */
    XLSX.writeFile(
      wb,
      // paymentType.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.xlsx`
      "USERS" + `_${dValue[0]}_report.xlsx`
    );
  };

  //PDF export Format
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    const title =
      // paymentType.toUpperCase() + ` SALE REPORT FROM ${dValue[0]} TO ${dValue[1]}`;
      "USERS" + ` AS AT ${dValue[0]}`;

    const headers = [
      [
        "First Name",
        "Second Name",
        "Username",
        "Email",
        "Phone Number",
        "User Group",
      ],
    ];

    const data = pdfData.map((data) => [
      data?.firstName,
      data?.secondName,
      data?.userName,
      data?.email,
      data?.phoneNumber,
      data?.userGroup,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(
      // paymentType.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.pdf`
      "Users" + ` _${dValue[0]} report.pdf`
    );
  };

  return (
    <div style={{ fontFamily: "Poppins" }}>
      <div>
        <div>
          <div>
            <Box className="container-fluid">
              {/*Success Deactivation Modal*/}
              <SuccessModal
                onClose={successDeactivationModalHandleClose}
                editConfigOpen={successDeactivationModalOpen}
                modalRow={userName}
                successObject={"User"}
                successType={"Created"}
              />

              {/*sessionModal*/}
              <SessionModal
                onClose={handleSessionClose}
                sessionOpen={sessionOpen}
              />

              {/*Existing user Modal*/}
              <Modal
                centered
                align={"center"}
                className="container container-fluid"
                open={existingUserModal}
                onClose={existingUserModalHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div
                  style={{
                    width: "506px",
                    height: "506px",
                    margin: "10% 16px 23px 45px",
                    padding: "87px 75px 92.7px 34px",
                    borderRadius: "36px",
                    boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
                    backgroundColor: "#fff",
                  }}
                >
                  <img src={deactivate} alt={"success deactivation"} />
                  <label
                    style={{
                      width: "356px",
                      height: "65px",
                      margin: "37.8px 0 0",
                      fontFamily: "Poppins",
                      fontSize: "25px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.2",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#6e7074",
                    }}
                  >
                    user created  successfully !
                  </label>
                  <Button
                    onClick={() => {
                      existingUserModalHandleClose();
                      window.location.reload(true);
                    }}
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "25px",
                      fontWeight: "600",
                      color: "#fff",
                      width: "124.5px",
                      height: "52.3px",
                      padding: "8px 44.5px 9.3px 35px",
                      backgroundColor: "#032541",
                    }}
                  >
                    OK
                  </Button>
                </div>
              </Modal>

              <Grid container>
                <Grid item marginTop="22px" marginBottom="22px">
                  <span
                    style={{
                      width: "78px",
                      height: "35px",
                      margin: "0px 41px 22px 12px",
                      fontFamily: "Poppins",
                      fontSize: "25px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.32",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#dc3545",
                    }}
                  >
                    Users
                  </span>
                </Grid>
              </Grid>
              <Grid container justifyContent={'space-between'} marginBottom="22px">
                <Grid item >
                  <span
                    style={{
                      width: "265px",
                      height: "28px",
                      margin: "21px 0px 39px 12px",
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.65",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    Dashboard
                  </span>
                  <span
                    style={{
                      width: "265px",
                      height: "28px",
                      margin: "21px 0px 39px 12px",
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.65",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#6e7074",
                    }}
                  >
                    \
                  </span>
                  <span
                    style={{
                      width: "265px",
                      height: "28px",
                      margin: "21px 0px 39px 12px",
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.65",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Users
                  </span>
                </Grid>

                <Grid item></Grid>

                <Grid item>
                  {localStorage.getItem("group") !== "Admin" ? (
                    <Button
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "9px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "2.78",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#f5f5f5",
                        width: "136px",
                        height: "37px",
                        padding: "12px 8.1px 12px 11px",
                        borderRadius: "8px",
                        backgroundColor: "#032541",
                      }}
                      onClick={handleOpen}
                      startIcon={<AddIcon />}
                    >
                      Add User
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>

              <Grid container columns={12} justifyContent="space-between" alignItems="center" sx={{ marginTop: "10px" }}>
                {/* Search Input */}
                <Grid item xs={6} sm={4} md={3}>
                  <CustomSearchInput name="search" value={searchValue} placeholder="Search:" onChange={handleSearchBox} />
                </Grid>

                {/* Export Buttons */}
                <Grid item>
                  <CSVLink style={{borderRadius: "6px",fontWeight: 600,color: "#fff",width: "50px",height: "37px",margin: "0 12px 0 0",padding: "9px 10px 8px 11px",backgroundColor: "#05d8e1",}}{...csvReport}>
                    CSV
                  </CSVLink>
                  <Button onClick={(e) => {xlsxReport(e, pdfData);}} sx={{fontWeight: 600,color: "#fff",width: "50px",height: "37px",margin: "0 12px",padding: "9px 7px 8px",backgroundColor: "#032541",}}>
                    Excel
                  </Button>
                  <Button onClick={() => {exportPDF();}} sx={{fontWeight: 600,color: "#fff",width: "50px",height: "37px",margin: "0 0 0 12px",padding: "9px 12px 8px",backgroundColor: "#dc3545",}}>
                    PDF
                  </Button>
                </Grid>
              </Grid>

              {/* <Grid container columns={1} justifyContent={'flex-end'} marginTop="10px">
                <Grid item alignContent={"left"} sx={{ margin: "0px 0px 0px 12px" }}>
                  <CSVLink style={{borderRadius: "6px",fontWeight: 600,color: "#fff",width: "50px",height: "37px",margin: "0 12px 0 0",padding: "9px 10px 8px 11px",backgroundColor: "#05d8e1",}}{...csvReport}>
                    {" "}
                    CSV{" "}
                  </CSVLink>
                  <Button onClick={(e) => {xlsxReport(e, pdfData);}} sx={{fontWeight: 600,color: "#fff",width: "50px",height: "37px",margin: "0 12px",padding: "9px 7px 8px",backgroundColor: "#032541",}} >
                    Excel
                  </Button>
                  <Button onClick={() => {exportPDF();}}sx={{fontWeight: 600,color: "#fff",width: "50px",height: "37px",margin: "0 0 0 12px",padding: "9px 12px 8px",backgroundColor: "#dc3545",}}>
                    PDF
                  </Button>
                </Grid>
              </Grid>

              <Grid container columns={1} justifyContent={'flex-end'} sx={{ marginTop: "22px" }}>
                <Grid item xs={0.9} md={0.755}></Grid>
                <Grid item>
                  <CustomSearchInput name={"search"} value={searchValue} placeholder="Search:" onChange={handleSearchBox} />
                  {/* <label
                    style={{
                      width: "35px",
                      height: "12px",
                      fontFamily: "Poppins",
                      fontSize: "9px",
                      fontWeight: "bold",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "2.78",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Search:
                  </label>
                  <input
                    value={searchValue}
                    onChange={handleSearchBox}
                    style={{
                      fontSize: "12px",
                      color: "#272d3b",
                      borderRadius: "6px",
                      width: "238px",
                      height: "36px",
                      margin: "0 0 0 20.4px",
                      padding: "7px 107.7px 6.4px 11.1px",
                      objectFit: "contain",
                      border: "solid 1px #032541",
                    }}
                    placeholder="search here"
                  ></input> */}
                {/* </Grid> */}
              {/* </Grid> */} 

              {/* <Grid
                sx={{
                  height: "500px",
                  margin: "50px 0 0 0",
                  border: "solid 0px transparent",
                  backgroundColor: "#fff",
                }}
              >
                <DataGrid
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    color: "#272d3b",
                  }}
                  rows={filteredData}
                  columns={activeColumns}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  pagination
                ></DataGrid>
              </Grid> */}

              {/* </Grid> */}

              {/*Tabbed Data Grid*/}
              <Box
                sx={{
                  "& .super-app-theme--header": {
                    color: "#032541",
                    fontWeight: "600",
                    fontSize: "10px",
                  },
                }}
              >
                <TabContext value={tabValue}>
                  <Box>
                    <AntTabs
                      onChange={handleTabChange}
                      aria-label="User tabs"
                      TabIndicatorProps={{ hidden: false }}
                      textColor="primary"
                    >
                      <AntTab label="Active User " value="Active" />
                      <AntTab label="New User " value="New" />
                      <AntTab label="Edited User " value="Pending" />
                      <AntTab
                        label="Deactivation request"
                        value="Deactivation"
                      />
                      <AntTab label="Inactive User " value="Inactive" />
                    </AntTabs>
                  </Box>
                  <TabPanel value="Active">
                    <DataGrid
                      style={{
                        height: "500px",
                        width: "auto",
                        overflow: "visible",
                      }}
                      // rows={activeRowData}
                      rows={filteredData}
                      columns={activeColumns}
                      // pageSize={pageSize}
                                          loading={pageState.isLoading}
                      page={pageState.page - 1}
                      pageSize={dataGridPageSize}  
                      // onPageSizeChange={(newPageSize) => {
                      //   setPageSize(newPageSize);
                      //   setCurrentPage(1);
                      // }}
                      // onPageChange={handlePageChange}
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      pagination
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        color: "#272d3b",
                      }}
                      onPageChange={(newPage) => {
                        setPageState((old) => ({
                          ...old,
                          page: newPage + 1,
                          pageSize: dataGridPageSize,
                        }));
                      }}
                      onPageSizeChange={(newPageSize) => {
                        setNewPageSize(newPageSize)
                        setPageSize(newPageSize)
                      }}
                      rowCount={totalUsers}
                      paginationMode="server"
                    ></DataGrid>
                  </TabPanel>
                  <TabPanel value="New">
                    <DataGrid
                      style={{ height: "500px", width: "auto" }}
                      rows={newRowData}
                      columns={newColumns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      pagination
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        color: "#272d3b",
                      }}
                    ></DataGrid>
                  </TabPanel>
                  <TabPanel value="Pending">
                    <DataGrid
                      style={{ height: "500px", width: "auto" }}
                      rows={pendingRowData}
                      columns={editedColumns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      pagination
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        color: "#272d3b",
                      }}
                    ></DataGrid>
                  </TabPanel>
                  <TabPanel value="Deactivation">
                    <DataGrid
                      style={{ height: "500px", width: "auto" }}
                      rows={deactivationRowData}
                      columns={deactivationColumns}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      pagination
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        color: "#272d3b",
                      }}
                    ></DataGrid>
                  </TabPanel>
                  <TabPanel value="Inactive">
                    <DataGrid
                      style={{
                        height: "500px",
                        width: "auto",
                        fontWeight: "bold",
                      }}
                      rows={inactiveRowData}
                      columns={inactiveColumns}
                      pageSize={10}
                  
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      rowsPerPageOptions={[5, 10, 20, 50, 100]}
                      pagination
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "10px",
                        color: "#272d3b",
                      }}
                    ></DataGrid>
                  </TabPanel>
                </TabContext>
              </Box>
            </Box>

            {/*Add user Modal*/}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="container-fluid"
            >
              <Box sx={style}>
                <Box
                  sx={{
                    width: "665px",
                    height: "90px",
                    margin: "0 0 8px",
                    padding: "35px 30px 34px",
                    borderRadius: "16px",
                    backgroundColor: "#dc3545",
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    style={{
                      width: "156px",
                      height: "21px",
                      margin: "0 433px 0 0",
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#fff",
                    }}
                  >
                    Add a User
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "605.2px",
                    height: "560px",
                    margin: "2.3px 29.8px 0 30px",
                    padding: "24.7px 54.2px 54.1px 54px",
                    backgroundColor: "#f5f7ff",
                  }}
                >
                  <form onSubmit={userAddHandler}>
                    <label
                      style={{
                        width: "153px",
                        height: "20px",
                        margin: "0 343px 10px 0",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "3.14",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#b4bcc4",
                      }}
                    >
                      First Name
                    </label>
                    <input
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      maxLength="25"
                      required
                      style={{
                        width: "496px",
                        height: "39px",
                        margin: "0px 0 0",
                        objectFit: "contain",
                        borderRadius: "4px",
                        border: "solid 1px #dfdede",
                        backgroundColor: "#fff",
                      }}
                      type="text"
                    />
                    <label
                      style={{
                        width: "153px",
                        height: "20px",
                        margin: "0 343px 10px 0",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "3.14",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#b4bcc4",
                      }}
                    >
                      Second Name
                    </label>

                    <input
                      value={secondName}
                      onChange={(e) => setSecondName(e.target.value)}
                      maxLength="15"
                      required
                      style={{
                        width: "496px",
                        height: "39px",
                        margin: "0px 0 0",
                        objectFit: "contain",
                        borderRadius: "4px",
                        border: "solid 1px #dfdede",
                        backgroundColor: "#fff",
                      }}
                      type="text"
                    />
                    <label
                      style={{
                        width: "153px",
                        height: "20px",
                        margin: "0 343px 10px 0",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "3.14",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#b4bcc4",
                      }}
                    >
                      Username
                    </label>

                    <br></br>

                    <input
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      maxLength="10"
                      style={{
                        width: "496px",
                        height: "39px",
                        margin: "0px 0 0",
                        objectFit: "contain",
                        borderRadius: "4px",
                        border: "solid 1px #dfdede",
                        backgroundColor: "#fff",
                        fontFamily: "Poppins",
                      }}
                      type="text"
                    />

                    <br></br>

                    <label
                      style={{
                        width: "153px",
                        height: "20px",
                        margin: "0 343px 10px 0",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "3.14",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#b4bcc4",
                      }}
                    >
                      Email
                    </label>

                    <br></br>

                    <input
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                      maxLength="50"
                      required
                      style={{
                        width: "496px",
                        height: "39px",
                        margin: "0px 0 0",
                        objectFit: "contain",
                        borderRadius: "4px",
                        border: "solid 1px #dfdede",
                        backgroundColor: "#fff",
                        fontFamily: "Poppins",
                      }}
                      type="email"
                    />

                    <br></br>

                    <label
                      style={{
                        width: "153px",
                        height: "20px",
                        margin: "0 343px 10px 0",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "3.14",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#b4bcc4",
                      }}
                    >
                      Phone No.
                    </label>

                    <br></br>

                    <input
                      readOnly
                      value="+254"
                      style={{
                        width: "50px",
                        height: "39px",
                        margin: "0px 0 0",
                        objectFit: "contain",
                        borderRadius: "4px",
                        border: "solid 1px #dfdede",
                        backgroundColor: "#fff",
                        fontFamily: "Poppins",
                      }}
                    />
                    {"\t"}
                    <input
                      value={userPhone}
                      required
                      onChange={(e) => setUserPhone(e.target.value)}
                      maxLength="9"
                      style={{
                        width: "440px",
                        height: "39px",
                        margin: "0px 0 0",
                        objectFit: "contain",
                        borderRadius: "4px",
                        border: "solid 1px #dfdede",
                        backgroundColor: "#fff",
                        fontFamily: "Poppins",
                      }}
                      type="text"
                    />

                    <br></br>

                    <label
                      style={{
                        width: "153px",
                        height: "20px",
                        margin: "10px 343px 10px 0",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "3.14",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#b4bcc4",
                      }}
                    >
                      User Group
                    </label>

                    <br></br>

                    <select
                      value={userGroup}
                      onChange={(e) => setUserGroup(e.target.value)}
                      maxLength="25"
                      style={{
                        width: "496px",
                        height: "39px",
                        margin: "0px 0 0",
                        objectFit: "contain",
                        borderRadius: "4px",
                        border: "solid 1px #dfdede",
                        backgroundColor: "#fff",
                        fontFamily: "Poppins",
                      }}
                      type="text"
                    >
                      <option value="">Select Group</option>
                      {businessRoles.map((groupData) => (
                        <option key={groupData.type} value={groupData?.name}>
                          {groupData.name}
                        </option>
                      ))}
                    </select>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Button
                      type="close"
                      onClick={handleClose}
                      style={{
                        width: "90px",
                        height: "33px",
                        margin: "-60px 30px 0 0",
                        padding: "9px 30px 8.1px 29px",
                        backgroundColor: "#dc3545",
                        color: "#fff",
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      style={{
                        width: "90px",
                        height: "33px",
                        margin: "-60px 0 0 30px",
                        padding: "9px 32px 8.1px 31px",
                        backgroundColor: "#032541",
                        color: "#fff",
                      }}
                    >
                      Save
                    </Button>
                  </form>
                </Box>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
