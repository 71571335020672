import { Box, Breadcrumbs, Button, Grid, InputLabel, TextField, Typography } from "@mui/material"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import SearchComponentFreeSolo from "../studentsponsors/searchFree";
import { useEffect, useState } from "react";
import { debounce } from "lodash"
import HttpComponent from "../MakeRequest";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import CircularProgress from '@mui/material/CircularProgress';


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>ZPM Reconcilliation Report</Typography>
]

const ReconciliationPage = () => {
    const navigate = useNavigate()
    const [searchValue, setSearchValue] = useState('')
    const [selectedStudent, setSelectedStudent] = useState()
    const [allStudents, setAllStudents] = useState([])
    const [amount, setAmount] = useState('')
    const [isReconciling, setIsReconciling] = useState(false)
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [isLoading, setIsloading] = useState(false)
    const handleSearchChange = (event, newValue, reason) => {
        if (reason === 'selectOption' && typeof newValue === 'object') {
            setSelectedStudent(newValue)
        }
        setSearchValue(newValue);
    };

    //get students in bs
    function getStudentinBs() {
        try {
            setIsloading(true)
            HttpComponent({
                method: "GET",
                url: `/api/get_students_in_school?status=ACTIVE&searchValue=${searchValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((res) => {
                if (res?.status === 200) {
                    setIsloading(false)
                    setAllStudents(res?.response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        } 
    }
    const debouncedSearch = debounce(() => {
        getStudentinBs();
    }, 500);

    useEffect(() => {
        if (searchValue) {
            debouncedSearch();
        } else {
            setAllStudents([]);
        }
        return () => {
            debouncedSearch.cancel();
        };
    }, [searchValue]);


    function ReconcileTransac() {
        try {
            setIsReconciling(true)
            HttpComponent({
                method: "POST",
                url: `/api/v1/create_reconcilliation`,
                body: {
                    billableItemId: selectedStudent?.studentId,
                    amount: amount
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    setIsReconciling(false)
                    setSuccessShow({ state: true, message: data?.response?.message })
                    setTimeout(() => {
                        navigate(-1)
                    }, 2000)
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })
        } catch (error) {
            console.log(error)
            setErrorShow({ state: true, message: error?.message })
        } 
    }

    return (
        <Grid container width={'100%'} flex={1} gap={1} flexDirection={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>ZPM Reconcilliation Report</Typography>
            </Grid>
            <Grid item mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item width={'50%'} >
                <Typography variant="h6" fontWeight={'700'}>Student Details</Typography>
            </Grid>
            <Grid item mt={2} width={'50%'}>
                <SearchComponentFreeSolo loading={isLoading} onChange={handleSearchChange} value={searchValue} inputType="text" label={'Search  Student name of student ID'} freeSolo={true} disableClearable={true} options={allStudents} />
            </Grid>
            {selectedStudent && <>
                <Grid item mb={1} width={'50%'} mt={2}>
                    <Grid container width={'100%'} gap={1}>
                        <Grid item width={'49%'}>
                            <InputLabel style={{ fontWeight: "500", fontSize: "14px", marginLeft: "", marginBottom: '5px' }}>Student Name</InputLabel>
                            <TextField style={{ width: "100%" }} disabled value={selectedStudent?.parentName} name='firstName' id="outlined" placeholder="Student Name" multiline required />
                        </Grid>
                        <Grid item width={'49%'}>
                            <InputLabel style={{ fontWeight: "500", fontSize: "14px", marginLeft: "", marginBottom: '5px' }}>Student ID</InputLabel>
                            <TextField style={{ width: "100%" }} disabled value={selectedStudent?.itemNumber} name='studentId' id="outlined" placeholder="Student ID" multiline required />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item mt={1} display={'flex'} flexDirection={'column'} width={'50%'}>
                    <InputLabel style={{ fontWeight: "500", fontSize: "14px", marginLeft: "", marginBottom: '5px' }}>Amount</InputLabel>
                    <TextField style={{ width: "100%" }} value={amount} name='email' onChange={(e) => setAmount(e.target.value)} id="outlined" placeholder="Amount" multiline required />
                </Grid>
                <Grid item mt={2} display={'flex'} width={'50%'} justifyContent={'flex-end'} alignItems={'center'}>
                    <Button onClick={() => navigate(-1)} style={{ color: '#dc3545', textTransform: 'inherit', width: '146px', height: '50px', border: '1px solid #dc3545' }}>Cancel</Button>
                    <Button onClick={()=>ReconcileTransac()} disabled={!amount || !selectedStudent} style={!amount || !selectedStudent ? { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: 'rgba(3, 37, 65, 0.4)' } : { width: '146px', marginLeft: '10px', textTransform: "inherit", height: '50px', color: '#fff', backgroundColor: '#032541' }}>
                        {isReconciling ? (
                            <Box component={'div'} sx={{ display: 'flex', alignItems: 'center', color:'#fff' }} gap={2}>
                              <CircularProgress size={24} sx={{ color: '#fff' }} /> Reconcilling
                            </Box>
                        ) : "Reconcile"}
                    </Button>
                </Grid>
            </>
            }
        </Grid>
    )
}

export default ReconciliationPage