import { ArrowBackIos } from "@mui/icons-material";
import { Badge, Box, Button, Checkbox, Grid, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import Breadcrumbcomponent from "../../products/stockComponents/breadcrumb";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../MakeRequest";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";

const Zpmreconcilliation = ({ zpmreconcillationdata }) => {
  const navigate = useNavigate();
  const breadcrumbs = [
    <span key={1}>Dashboard</span>,
    <span key={2} style={{ color: "#dc3545" }}>
      Configurations
    </span>,
  ];

  const handleCancelling = () => {
    navigate(`/school/configuration`);
  };

  const [searchValue, setSearchValue] = useState("");
  const [users, setUsers] = useState([]);
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });

  const [exisitngUserIds, setExistingUserIds] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  console.log("selectedIds ------>", selectedIds)
  const handleselection = (id) => {
   
    const newIds = [...selectedIds];
    const getIndex = newIds.findIndex((nid) => nid === id);
    const newusers = [...users]
    if (getIndex === -1) {
      selectedIds.push(id);
      // return false;
    } else {
      selectedIds.splice(getIndex, 1);
      // return true;
    }
    setSelectedIds(selectedIds);

    // handle user selections:
    newusers.map((user) => {
      if(user.userId === id) {
        if(user.isSelected){
          user.isSelected = false
        }else {
          user.isSelected = true
        }
      }
    })
    setUsers(newusers)
  };

  console.log("this_users", users)
  
  useEffect(() => {
    HttpComponent({
      method: "GET",
      url: `/api/listUsers/Active?isCustomer=true&limit=5&page=1&searchValue=${searchValue}`,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      if (data.status === 200) {
        if (!zpmreconcillationdata) {
          zpmreconcillationdata = [];
        }
        let zpmusers = zpmreconcillationdata[0]?.users || [];
        setSelectedIds(zpmreconcillationdata[0]?.users || [])
        let users = data.response.data;
        users = users?.map((user) => {
          user.isSelected = false;
          if (zpmusers?.includes(user?.userId)) {
            user.isSelected = true;
          }
          return user;
        });
        setUsers(users);
      } else {
        setUsers([]);
        setErrorShow({ ...errorShow, state: true, message: "Error getting users" });
        setTimeout(() => {
          setErrorShow({ state: false, message: "" });
        }, 3000);
      }
    });
  }, [searchValue]);

  const handlesetupconfig = () => {
    if (!selectedIds || selectedIds?.length < 1) {
      setErrorShow({ ...errorShow, state: true, message: "Please select a user" });
      setTimeout(() => {
        setErrorShow({ state: false, message: "" });
      }, 3000);
      return;
    }
    HttpComponent({
      method: "POST",
      url: `/api/v1/enablezpmreconcile`,
      token: localStorage.getItem("X-Authorization"),
      body: { users: selectedIds },
    }).then((data) => {
      if (data?.status === 200) {
        setSuccessShow({ ...successShow, state: true, message: "Configs set successfully" });
        setTimeout(() => {
          setSuccessShow({ message: "", state: false });
          navigate(-1);
        }, 3000);
      }
    });
  };

  const handleselected = (id) => {
    console.log("user?.userId", id)
    const newUsers = [...users]

    // const index = ;

    // console.log("index -->", index)
  // 
  }
  return (
    <div>
      <Grid2 container direction={"column"} width={"100%"}>
        <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
        <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
        <Grid mb={3} width={"50%"}>
          <Box onClick={() => navigate(-1)} sx={{ display: "flex", gap: "10px", alignItems: "center", cursor: "pointer" }} mb={4}>
            <ArrowBackIos sx={{ color: "#032541" }} />
            <Typography sx={{ color: "#032541", fontSize: "25px", fontWeight: 600 }}>ZPM Reconcilliation Config</Typography>
          </Box>

          <Box mb={2}>
            <Breadcrumbcomponent breadcrumbs={breadcrumbs} />
          </Box>

          <Box mb={2}>
            <Typography sx={{ color: "#9c9ea4", fontSize: "16px" }}>Assign Users</Typography>
          </Box>
          <Box mb={2}>
            <Typography sx={{ color: "#9c9ea4", fontSize: "16px" }}>Assign Users to reconcile and approve Zed Pocket Money accounts</Typography>
          </Box>
          <Box mb={2}>
            <TextField onChange={(e) => setSearchValue(e.target.value)} sx={{ width: "100%" }} placeholder="Search..." />
          </Box>
        </Grid>

        <Grid item mb={5} width={"50%"}>
          {users &&
            users?.length > 0 &&
            users?.map((user, index) => {
              return (
                <Box key={user?.userId} sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                  <Checkbox checked={user?.isSelected} onChange={() => handleselection(user?.userId)} key={index} color="success" />
                  {/* <Badge badgeContent={"checked"} color="error"> */}
                    <Typography key={user?.userId}>{user?.firstName + " " + user?.secondName}</Typography>
                  {/* </Badge> */}
                </Box>
              );
            })}
        </Grid>

        <Grid item width={"50%"} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "30px" }}>
            <Button onClick={() => handleCancelling()} style={{ color: "#DC3545", border: "1px solid #DC3545", padding: "8px 30px 8px 30px" }}>
              Cancel
            </Button>
            <Button onClick={() => handlesetupconfig()} style={{ color: "#FFFFFF", backgroundColor: "#032541", padding: "8px 30px 8px 30px" }}>
              Enable
            </Button>
          </Box>
        </Grid>
      </Grid2>
    </div>
  );
};

export default Zpmreconcilliation;
