import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Slide, Typography } from "@mui/material"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { forwardRef, useEffect, useState } from "react";
import WarningImg from "../../images/warning.png"
import HttpComponent from "../School/MakeRequest";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteBranchDetails } from "../../features/branchSlice";
import { deleteBusinessCategoryDetails } from "../../features/businessCategorySlice";
import { deleteBusinessBranches } from "../../features/businessBranchesSlice";
import { logoutUser } from "../../features/userSlice";
import { resetSideBarDefault } from "../../features/sideBarSlice";
import { reversePageLoadState } from "../../features/autorefreshonpageload";
import { handleReset } from "../../features/tenantsDataSlice";
import { handleClearServicesData } from "../../features/servicesSlice";
import { clearAdditions } from "../../features/customerSlice";
import { removeSchoolTypeName } from "../../features/schoolType";
import { handleResetStaff } from "../../features/staffSlice";
import { handleResetDetail } from "../../features/businessAddPartner";
import { resetVerifyToken } from "../../features/verifyToken";

const breadcrumbs = [
    <Typography key={1} style={{ color: '#707070', fontSize: '14px', fontFamily: 'Poppins' }}>
        Dashboard
    </Typography>,
    <Typography key={1} style={{ color: '#707070', fontSize: '14px', fontFamily: 'Poppins' }}>
        My Profile
    </Typography>,
    <Typography key={4} style={{ color: '#dc3545', fontSize: '14px', fontFamily: 'Poppins' }}>
        Delete Account
    </Typography>,
];

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteUserAccountPage = () => {
    const [deleteReasons, setDeleteReasons] = useState([])
    const [successShow, setSuccessShow] = useState({ state: false, message: '' });
    const [errorShow, setErrorShow] = useState({ state: false, message: '' });
    const [deleteAccountLoading, setDeleteAccountLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [reason, setReason] = useState('')
    const [loading, setIsLoading] = useState(false)
    const [newReason, setNewReason] = useState("")
    const [openDialog, setDialog] = useState(false)
    const handleOpenDialog = () => {
        setDialog(true)
    }
    const handleCloseDialog = () => {
        setDialog(false)
    }
    function getReasons() {
        setIsLoading(true)
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/getReasons`,
                token: localStorage.getItem('X-Authorization')
            }).then((response) => {
                if (response?.status === 200 || response?.status === 201) {
                    setIsLoading(false)
                    setDeleteReasons(response?.response?.data)
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        getReasons()
    }, [])
    function UpdateDeleteDeleteAcc() {
        setDeleteAccountLoading(true)
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/deleteAccount`,
                token: localStorage.getItem('X-Authorization'),
                body: {
                    reason: reason ? reason : newReason
                }
            }).then((response) => {
                if (response?.status === 200 || response?.status === 201) {
                    setIsLoading(false)
                    setTimeout(() => {
                        dispatch(deleteBranchDetails());
                        dispatch(deleteBusinessCategoryDetails());
                        dispatch(deleteBusinessBranches());
                        dispatch(logoutUser());
                        dispatch(resetSideBarDefault());
                        dispatch(reversePageLoadState())
                        dispatch(handleReset())
                        dispatch(handleClearServicesData())
                        dispatch(clearAdditions())
                        dispatch(removeSchoolTypeName())
                        dispatch(handleResetStaff())
                        dispatch(handleResetDetail())
                        dispatch(resetVerifyToken())
                        localStorage.clear();
                        navigate('/');
                    }, 1500)
                }
            })
        } catch (error) {
            console.error(error)
        } finally {
            setDeleteAccountLoading(false)
        }
    }
    return (
        <Grid container flexDirection={'column'} width={'100%'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon style={{ color: '#707070' }} onClick={() => navigate(-1)} />
                <Typography variant="h4" style={{ color: '#032541', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Poppins' }}>
                    Delete Account
                </Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Breadcrumbs
                    style={{ fontFamily: 'Poppins', fontSize: '14px' }}
                    aria-label="breadcrumb"
                    separator={<FiberManualRecordIcon style={{ fontSize: '0.625rem', fontFamily: 'Poppins', color: '#e1e5e8' }} />}
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item width={'50%'}>
                <Typography variant="h6">Delete Account</Typography>
                <Typography variant="body2">Can you please share with us what was not working? Will be
                    so grateful to be aware of what it is and fix it.</Typography>
            </Grid>
            <FormControl sx={{ paddingY: 2 }}>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                >
                    {deleteReasons?.map((item) => {
                        return (
                            <FormControlLabel value={item} onChange={(e) => setReason(e.target.value)} control={<Radio />} label={item} />
                        )
                    })}
                </RadioGroup>
            </FormControl>
            <Grid item width={'50%'}>
                {reason === 'Others' &&
                    <TextareaAutosize
                        style={{ width: '100%', padding: '10px 10px' }}
                        placeholder="Explanation"
                        minRows={5}
                        value={newReason}
                        onChange={(e) => setNewReason(e.target.validationMessage)}
                    />}
                <Box component={'div'} display={'flex'} justifyContent={'flex-end'} alignContent={'center'}>
                    {deleteAccountLoading ? null : <Button onClick={handleOpenDialog} style={{ textTransform: 'inherit', background: '#032541', color: '#fff', width: '120px' }}>Submit</Button>}
                </Box>
            </Grid>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                PaperProps={{
                    style: {
                        height: "300px",
                        width: "600px",
                        boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                    },
                }}
                BackdropProps={{ style: { backgroundColor: "transparent", opacity: "1", backdropFilter: "blur(1px)", } }}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent sx={{ display: "flex", alignItems: "center", width: '100%', justifyContent: 'center' }}>
                    <Box component={'div'} display={'flex'} alignItems={'center'} gap={2} justifyContent={'center'}>
                        <img src={WarningImg} alt='approve' />
                        <Box component={'div'}>
                            <Box component={'div'} sx={{ display: "flex", gap: 1, flexDirection: "column", width: '100%' }}>
                                <Typography sx={{ color: "#032541", fontWeight: '700', fontSize: "14px" }} variant="subtitle1">We are sad to see you go</Typography>
                                <Typography sx={{ color: "#032541", fontWeight: '200', fontSize: "13px" }} variant="subtitle2">Are you sure you want to delete your account?</Typography>
                                <Typography sx={{ color: '#707070', fontSize: "12px" }} variant="body2">Your data will be permanently deleted from our system. This action cannot be undone!</Typography>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ display: "flex", pb: 3, justifyContent: "center", alignContent: "center", alignItems: "center", width: '100%' }}>
                    <Button onClick={handleCloseDialog} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "130px", marginRight: '10px' }}>Cancel</Button>
                    <Button onClick={UpdateDeleteDeleteAcc} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "130px", paddingLeft: "10px" }}>Delete Account</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default DeleteUserAccountPage