import { Autocomplete, Collapse, Grid, Tab, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import React, { useEffect, useState } from "react";
import CustomSearchInput from "../../../components/School/CustomSearchInput";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HttpComponent from "../../../components/School/MakeRequest";
import { PendingRows } from './CustomRowTable'
import { Link, useNavigate } from "react-router-dom";
import AutocompleteComponent from "../../../common/autoComplete";
import { makeStyles } from "@mui/styles";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import Checkbox from "@mui/material/Checkbox";
import IconButton from '@mui/material/IconButton';
import DateFormatter from '../../../utils/dateFormatter';
import ExportMenu from "../../School/ExportMenu";
import { CustomizedDate } from "../../customerAccounts/customerComponents/customDate";
import CustomSelectField from "../../School/CustomSelectField";
import { currencyconverter } from "../../../common/currencyconverter";


const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES" }).format(value);


const AntTabs = styled(TabList)({ borderBottom: "none", "& .MuiTabs-indicator": { backgroundColor: "none" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none", maxWidth: 200, [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1), width: '100%',
    fontSize: "18px", background: '#f5f6f7', borderRadius: '5px', fontStretch: "normal", fontStyle: "normal",
    letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#dc3545", backgroundColor: '#fef7f8', borderBottom: '', fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: "none" },
}));


const useStyles = makeStyles((theme) => ({
    address_text_common: {
        fontSize: "14px",
        fontWeight: "700",
        color: '#032541'
    },
    table_titles: {
        fontSize: "14px",
        fontWeight: "700",
    },
    table_body: {
        fontSize: "13px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    footer_text: {
        fontSize: "10px",
        fontWeight: "300",
        padding: theme.spacing(0.25),
    },
    totals: {
        fontSize: "14px",
        padding: theme.spacing(0.25),
    },
    p1: {
        padding: theme.spacing(0.25),
    },
    p2: {
        padding: theme.spacing(0.5),
    },
    p3: {
        padding: theme.spacing(1),
    },
    mt4: {
        marginTop: theme.spacing(4),
    },
    m1: {
        margin: theme.spacing(1),
    },
    textLeft: {
        textAlign: "left",
    },
    textCenter: {
        textAlign: "center",
    },
    textRight: {
        textAlign: "right",
    },
}));



export default function SupplierVendorTabs(props) {

    const [tabValue, setTabValue] = useState('Pending')
    const [searchValue, setSearchValue] = useState('')
    const [dataGridPageSize, setPageSize] = useState(10);
    const [datapending, setDatapending] = useState([])
    const [openCollapse, setOpenCollapse] = useState(false)
    const navigate = useNavigate()

    const navigateRow = (row) => {
        navigate(`/payables/transcation/${row?._id}`)
    }


    const classes = useStyles()
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const [days, setDays] = useState([
        { value: "Default", label: "Default" },
        { value: "Today", label: "Today" },
        { value: "Yesterday", label: "Yesterday" },
        { value: "Last 7 Days", label: "Last 7 Days" },
        { value: "This Month", label: "This Month" },
        { value: "Last Month", label: "Last Month" },
        { value: "Custom Range", label: "Custom Range" },
    ]);

    const [handleDaysChange, setHandleDaysChange] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [exportData, setExportData] = useState({ headers: [], data: [] });
    const [csvExport, setCsvExport] = useState({ data: [], headers: [] });


    useEffect(() => {
        switch (handleDaysChange) {
            case "Today":
                const today = new Date();
                setStartDate(today);
                setEndDate(today);
                break;
            case "Yesterday":
                let date = new Date();
                const yeseterday = new Date(date.setDate(date.getDate() - 1))
                setStartDate(yeseterday);
                setEndDate(yeseterday);
                break;
            case "Last 7 days":
                function Last7Days() {
                    var result = [];
                    for (var i = 0; i < 7; i++) {
                        var d = new Date();
                        result.push(d.setDate(d.getDate() - i));
                    }
                    const lastSevenFirst = new Date(result[0]);
                    const lastSevenLast = new Date(result[result.length - 1])

                    setStartDate(lastSevenLast);
                    setEndDate(lastSevenFirst);
                }
                Last7Days();
                break;
            case "This Month":
                const date1 = new Date();
                const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1)
                const currentDay = new Date()
                setStartDate(firstDay);
                setEndDate(currentDay);

                break;
            case "Last Month":
                const date2 = new Date();
                const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1)
                const lastDayLastMonth = new Date(date2.setDate(1))
                setStartDate(firstDayLastMonth);
                setEndDate(lastDayLastMonth);
                break;
            case "Default":
                setStartDate("");
                setEndDate("");
                break;
            case "Custom Range":
                setStartDate(dateFrom);
                setEndDate(dateTo);
                break;
            default:
                return;
        }
        fetchTranscations();
    }, [handleDaysChange, dateFrom, dateTo]);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    //file data
    const getCommonFileDataFields = (row) => ({
        "Request ID": row?.ftpNumber,
        "Date Requested": DateFormatter(row?.dateCreated),
        "Supplier": row?.supplierName,
        "Amount": currencyconverter(row?.amount),
        "Raised By": row?.inputer?.name,
        "Status": row?.status,
    });
    const fileData = pageState?.data?.map(row => {
        const commonFields = getCommonFileDataFields(row);
        switch (tabValue) {
            case 'Disbursed':
                return { ...commonFields, "Date Disbursed": DateFormatter(row?.dateDisbursed) };
            case 'Declined':
                return { ...commonFields, "Date Declined": DateFormatter(row?.dateDeclined) };
            default:
                return commonFields;
        }
    });

    const fileDataPDF = []
    const csvColumns = []
    const fileHeaders = [[]]


    // get all business

    const [allBusiness, setAllBusiness] = useState([])
    const [selectedValue, setSelectValue] = useState("");
    const [selectInputValue, setInputValue] = useState("");

    function getAllBusiness() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_available_businesses?searchValue=${selectedValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data?.status === 200) {
                    const foundBusiness = data?.response?.data?.map((business) => {
                        return {
                            name: business.businessName,
                            bsNo: business?.businessNumber,
                        }
                    })
                    setAllBusiness(foundBusiness)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getAllBusiness()
    }, [selectedValue])

    let url
    const formattedStartDate = startDate ? new Date(startDate).toISOString() : '';
    const formattedEndDate = endDate ? new Date(endDate).toISOString() : '';
    if (tabValue === 'Pending') {
        url = `/api/v1/funds_transfer/pending_transfer?type=supplier&page=${pageState.page}&limit=${dataGridPageSize}&search=${searchValue}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`

    } else if (tabValue === 'Disbursed') {
        url = `/api/v1/funds_transfer/disbursed_transfer?type=supplier&page=${pageState.page}&limit=${dataGridPageSize}&search=${searchValue}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
    } else if (tabValue === 'Declined') {
        url = `/api/v1/funds_transfer/declined_transfer?type=supplier&page=${pageState.page}&limit=${dataGridPageSize}&search=${searchValue}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
    } else if (tabValue === 'Failed') {
        url = `/api/v1/funds_transfer/failed_transfer?type=supplier&page=${pageState.page}&limit=${dataGridPageSize}&search=${searchValue}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
    } else if (tabValue === 'Cancelled') {
        url = `/api/v1/funds_transfer/cancelled_transfer?type=supplier&page=${pageState.page}&limit=${dataGridPageSize}&search=${searchValue}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
    }

    function fetchTranscations() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: url,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                // console.log(data, 'dataaaaaaaaaaaa')
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                    if (tabValue === 'Pending') {
                        setDatapending(data?.response?.data)

                    }
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchTranscations()
    }, [tabValue, pageState.page, dataGridPageSize, searchValue, startDate, endDate])
    const [selectedRow, setSelectedRow] = useState([])

    useEffect(() => {
        const pdfData = pageState?.data?.map((cat) => [
            cat?.ftpNumber,
            DateFormatter(cat?.dateCreated),
            cat?.supplierName,
            currencyconverter(cat?.amount),
            cat?.inputer?.name,
            // cat?.status,
        ]);

        const pdfHeaders = [['Request ID', 'Date Requested', 'Supplier', 'Amount (KES)', 'Raised By']];

        setExportData({ headers: pdfHeaders, data: pdfData });

        const csvHeaders = [
            { label: 'Request ID', key: 'Request ID' },
            { label: 'Date Requested', key: 'Date Requested' },
            { label: 'Supplier', key: 'Supplier' },
            { label: 'Amount (KES)', key: 'Amount (KES)' },
            { label: 'Raised By', key: 'Raised By' },
            { label: 'Status', key: 'Status' },
        ];

        const csvData = pageState?.data?.map((cat) => ({
            "Request ID": cat?.ftpNumber,
            "Date Requested": DateFormatter(cat?.dateCreated),
            "Supplier": cat?.supplierName,
            "Amount (KES)": currencyconverter(cat?.amount),
            "Raised By": cat?.inputer?.name,
            "Status": cat?.status,
        }));

        setCsvExport({ data: csvData, headers: csvHeaders });
    }, [pageState?.data, dataGridPageSize, tabValue]);

    const handleSelectRowClick = (event, row) => {
        const newSelectedRow = [...selectedRow];
        const selectedIndex = selectedRow.findIndex((id) => id === row?._id);
        console.log(selectedIndex, 'selected index')
        if (selectedIndex === -1) {
            newSelectedRow.push(row?._id);
        }
        else if (selectedIndex >= 0) {
            newSelectedRow.splice(selectedIndex, 1);
        }
        setSelectedRow(newSelectedRow);
    };

    console.log(selectedRow, 'selected row supplier Tabs')
    const checkstatus = (row) => {
        let status = selectedRow.findIndex((item) => item === row._id)
        if (status === -1) {
            return false
        }
        else {
            return true
        }
    }

    const newDataToParent = () => {
        let data = {
            selectedRowLength: selectedRow.length,
            tabValue: tabValue,
            selectedRow: selectedRow
        }
        props.parentCallBack(data)
    }


    useEffect(() => {
        console.log(props, 'props in child component');
        newDataToParent();
    }, [selectedRow, tabValue])

    function RenderPendingRows() {
        const [openRowIndex, setOpenRowIndex] = useState(null);

        const handleRowToggle = (index) => {
            console.log(index, 'index')
            setOpenRowIndex(openRowIndex === index ? null : index);
        };

        return (
            <>
                {datapending?.length > 0 ? datapending.map((row, index) => (
                    <React.Fragment key={row?._id}>
                        <TableRow hover key={row?._id} sx={{ '& > *': { borderBottom: 'unset' } }}>
                            <TableCell>
                                {row?.canDoAction && (
                                    <Checkbox
                                        checked={checkstatus(row)}
                                        onChange={(event) => handleSelectRowClick(event, row)}
                                    />
                                )}
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    sx={{ marginRight: '10px' }}
                                    onClick={() => handleRowToggle(index)}
                                >
                                    {openRowIndex === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                                <Link to={`/payables/transcation/${row?._id}`} style={{ color: '#000000', fontSize: '14px', fontWeight: 500 }}>
                                    {row?.ftpNumber}
                                </Link>
                            </TableCell>
                            <TableCell onClick={() => navigateRow(row)} component="th" scope="row">{DateFormatter(row?.dateCreated)}</TableCell>
                            <TableCell onClick={() => navigateRow(row)} component={'th'} scope="row">{row?.supplierName}</TableCell>
                            <TableCell onClick={() => navigateRow(row)} component={'th'} scope="row">{numberFormat(row?.amount)}</TableCell>
                            <TableCell onClick={() => navigateRow(row)} component={'th'} scope="row">{row?.inputer?.name}</TableCell>
                            <TableCell onClick={() => navigateRow(row)} component={'th'} style={{ color: row?.status === "Pending" ? '#ff8503' : row?.status === "Cancelled" ? '#ff8503' : row?.status === "Failed" ? '#000000' : row?.status === "Declined" ? '#dc3545' : '#17ae7b', fontWeight: 'bold' }} scope="row">{row?.status}</TableCell>
                        </TableRow>
                        {openRowIndex === index && (
                            <TableRow>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                    <Collapse in={openRowIndex === index} timeout="auto" unmountOnExit>
                                        <Grid item display={'flex'} width={'100%'}>
                                            <Grid container display={'flex'} direction={'column'}>
                                                <Grid item mt={1}>
                                                    <Typography style={{ color: '#000000', fontSize: '16px', fontWeight: 'bold' }}>
                                                        Inputer
                                                    </Typography>
                                                </Grid>
                                                <Grid mt={1} item display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'30%'} mb={2}>
                                                    <Typography style={{ color: '#707070', fontSize: '14px', fontWeight: 500 }}>{row?.inputer?.name}</Typography>
                                                    <Typography style={{ color: '#707070', fontSize: '14px', fontWeight: 500 }}>{row?.inputer?.inputerCode}</Typography>
                                                    <Typography style={{ color: '#707070', fontSize: '14px', fontWeight: 500 }}>{DateFormatter(row?.inputer?.paymentCreatedTime)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {row?.expectedApproverEachLevel?.map((historyRow, index) => (
                                            <Grid key={index} mt={1} direction={'column'} display={'flex'} width={'30%'}>
                                                <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                                                    <Typography style={{ color: '#000', fontSize: '14px' }}>{historyRow?.levelDescription}</Typography>
                                                    <Typography style={{ color: historyRow?.levelStatus === 'Approved' ? '#17ae7b' : '#ff8503', fontSize: '14px', marginLeft: '20px' }}>{historyRow?.levelStatus}</Typography>
                                                </Grid>
                                                <Grid item mt={1} display={'flex'} direction={'column'}>
                                                    {historyRow?.approves?.map((approver, index) => (
                                                        <Grid key={index} item mb={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                                            <Typography style={{ color: '#707070', fontSize: '14px', fontWeight: 500 }}>{approver?.approverName}</Typography>
                                                            <Typography style={{ color: approver?.transactionStatus === 'Pending' ? '#f79009' : approver?.transactionStatus === 'Approved' ? '#17ae7b' : '#dc3545', fontSize: '14px' }}>{approver?.transactionStatus}</Typography>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        )}
                    </React.Fragment>
                )) : (
                    <Grid item={1} style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                        <NoRowsOverlay style={{ display: "flex", justifyContent: "center", alignContent: "center" }} />
                    </Grid>
                )}
            </>
        );
    }




    return (
        <Grid direction={'column'} container>
            <Grid item>
                <TabContext value={tabValue}>
                    <AntTabs onChange={handleChange} indicatorColor={""} sx={{ display: 'flex' }}>
                        <AntTab label="Pending Approval" value="Pending" />
                        <AntTab label="Disbursed" value="Disbursed" />
                        <AntTab label="Declined" value="Declined" />
                        <AntTab label="Failed" value="Failed" />
                        <AntTab label="Cancelled" value="Cancelled" />
                    </AntTabs>
                    <TabPanel value="Pending">
                        <Grid item>
                            <Grid container display={'flex'} direction={'column'}>
                                <Grid item display={'flex'} alignItems={'center'}>
                                    <Grid container alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                                        <Grid item>
                                            <CustomSearchInput value={searchValue} name={'searchValue'} placeholder={'Search'} onChange={(e) => setSearchValue(e.target.value)} />
                                        </Grid>
                                        <Grid item display={"flex"} gap={"10px"} alignItems={"center"}>
                                            {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}
                                            {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center" }} />}
                                        </Grid>
                                        <ExportMenu csvColumns={csvExport.headers} fileHeaders={exportData.headers} fileDataPDF={exportData.data} fileData={csvExport.data} fileName={`${tabValue} Payees`} title={`${tabValue} Payees`}/>
                                        {/* <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={fileData} fileName={`${tabValue} Payees`} title={`${tabValue} Payees`} /> */}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Request ID</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common} >Date Requested</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Supplier</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Amount (KES)</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Raised By</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* {pageState?.data?.map((transaction) => (
                                                <PendingRows key={transaction?.name} row={transaction} />
                                            ))} */}
                                            {RenderPendingRows()}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                        component={'div'}
                                        count={pageState?.total}
                                        rowsPerPage={dataGridPageSize}
                                        page={pageState.page}
                                        onPageChange={(newPage, event) => { setPageState((old) => ({ ...old, page: newPage })); }}
                                        onRowsPerPageChange={(e) => { setPageSize(+e.target.value); setPageState((old) => ({ ...old, page: 0 })) }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="Disbursed">
                        <Grid item>
                            <Grid container display={'flex'} direction={'column'}>
                                <Grid item display={'flex'} alignItems={'center'}>
                                    <Grid container alignItems={'center'} gap={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                                        <Grid item width={'20%'}>
                                            <CustomSearchInput sm value={searchValue} name={'searchValue'} placeholder={'Search'} onChange={(e) => setSearchValue(e.target.value)} />
                                        </Grid>
                                        <Grid item width={'20%'} mt={-1}>
                                            <AutocompleteComponent setSelectValue={setSelectValue} setInputValue={setInputValue} label={"Search business.."} data={allBusiness ? allBusiness : [{ name: "default", bsNo: 546778 }]} styles={{ width: "100%" }} />
                                        </Grid>
                                        <Grid item display={"flex"} gap={"10px"} width={'40%'} alignItems={"center"}>
                                            {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}
                                            {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center" }} />}
                                        </Grid>
                                        <ExportMenu csvColumns={csvExport.headers} fileHeaders={exportData.headers} fileDataPDF={exportData.data} fileData={csvExport.data} fileName={`${tabValue} Payees`} title={`${tabValue} Payees`}/>
                                        {/* <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={fileData} fileName={`${tabValue} Payees`} title={`${tabValue} Payees`} /> */}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Request ID</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common} >Date Requested</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Supplier</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Amount (KES)</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Raised By</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Status</TableCell>
                                                {tabValue === 'Disbursed' ? <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Date Disbursed</TableCell> : null}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pageState?.data?.map((transaction) => (
                                                <PendingRows key={transaction?.name} row={transaction} tabValue={tabValue} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                        component={'div'}
                                        count={pageState?.total}
                                        rowsPerPage={dataGridPageSize}
                                        page={pageState.page}
                                        onPageChange={(newPage, event) => { setPageState((old) => ({ ...old, page: newPage })); }}
                                        onRowsPerPageChange={(e) => { setPageSize(+e.target.value); setPageState((old) => ({ ...old, page: 0 })) }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="Declined">
                        <Grid item>
                            <Grid container display={'flex'} direction={'column'}>
                                <Grid item display={'flex'} alignItems={'center'}>
                                    <Grid container alignItems={'center'} gap={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                                        <Grid item width={'20%'}>
                                            <CustomSearchInput sm value={searchValue} name={'searchValue'} placeholder={'Search'} onChange={(e) => setSearchValue(e.target.value)} />
                                        </Grid>
                                        <Grid item width={'20%'} mt={-1}>
                                            <AutocompleteComponent setSelectValue={setSelectValue} setInputValue={setInputValue} label={"Search business.."} data={allBusiness ? allBusiness : [{ name: "default", bsNo: 546778 }]} styles={{ width: "100%" }} />
                                        </Grid>
                                        <Grid item display={"flex"} gap={"10px"} width={'40%'} alignItems={"center"}>
                                            {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}
                                            {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center" }} />}
                                        </Grid>
                                        <ExportMenu csvColumns={csvExport.headers} fileHeaders={exportData.headers} fileDataPDF={exportData.data} fileData={csvExport.data} fileName={`${tabValue} Payees`} title={`${tabValue} Payees`}/>
                                        {/* <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={fileData} fileName={`${tabValue} Payees`} title={`${tabValue} Payees`} /> */}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Request ID</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common} >Date Requested</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Supplier</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Amount (KES)</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Raised By</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Status</TableCell>
                                                {tabValue === 'Declined' ? <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Date Declined</TableCell> : null}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pageState?.data?.map((transaction) => (
                                                <PendingRows key={transaction?.name} row={transaction} tabValue={tabValue} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                        component={'div'}
                                        count={pageState?.total}
                                        rowsPerPage={dataGridPageSize}
                                        page={pageState.page}
                                        onPageChange={(newPage, event) => { setPageState((old) => ({ ...old, page: newPage })); }}
                                        onRowsPerPageChange={(e) => { setPageSize(+e.target.value); setPageState((old) => ({ ...old, page: 0 })) }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="Failed">
                        <Grid item>
                            <Grid container display={'flex'} direction={'column'}>
                                <Grid item display={'flex'} alignItems={'center'}>
                                    <Grid container alignItems={'center'} gap={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                                        <Grid item width={'20%'}>
                                            <CustomSearchInput sm value={searchValue} name={'searchValue'} placeholder={'Search'} onChange={(e) => setSearchValue(e.target.value)} />
                                        </Grid>
                                        <Grid item width={'20%'} mt={-1}>
                                            <AutocompleteComponent setSelectValue={setSelectValue} setInputValue={setInputValue} label={"Search business.."} data={allBusiness ? allBusiness : [{ name: "default", bsNo: 546778 }]} styles={{ width: "100%" }} />
                                        </Grid>
                                        <Grid item display={"flex"} gap={"10px"} width={'40%'} alignItems={"center"}>
                                            {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}
                                            {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center" }} />}
                                        </Grid>
                                        <ExportMenu csvColumns={csvExport.headers} fileHeaders={exportData.headers} fileDataPDF={exportData.data} fileData={csvExport.data} fileName={`${tabValue} Payees`} title={`${tabValue} Payees`}/>
                                        {/* <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={fileData} fileName={`${tabValue} Payees`} title={`${tabValue} Payees`} /> */}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Request ID</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common} >Date Requested</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Supplier</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Amount (KES)</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Raised By</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pageState?.data?.map((transaction) => (
                                                <PendingRows key={transaction?.name} row={transaction} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                        component={'div'}
                                        count={pageState?.total}
                                        rowsPerPage={dataGridPageSize}
                                        page={pageState.page}
                                        onPageChange={(newPage, event) => { setPageState((old) => ({ ...old, page: newPage })); }}
                                        onRowsPerPageChange={(e) => { setPageSize(+e.target.value); setPageState((old) => ({ ...old, page: 0 })) }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="Cancelled">
                        <Grid item>
                            <Grid container display={'flex'} direction={'column'}>
                                <Grid item display={'flex'} alignItems={'center'}>
                                    <Grid container alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                                        <Grid item>
                                            <CustomSearchInput value={searchValue} name={'searchValue'} placeholder={'Search'} onChange={(e) => setSearchValue(e.target.value)} />
                                        </Grid>
                                        <Grid item display={"flex"} gap={"10px"} alignItems={"center"}>
                                            {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}
                                            {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center" }} />}
                                        </Grid>
                                        <ExportMenu csvColumns={csvExport.headers} fileHeaders={exportData.headers} fileDataPDF={exportData.data} fileData={csvExport.data} fileName={`${tabValue} Payees`} title={`${tabValue} Payees`}/>
                                        {/* <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={fileData} fileName={`${tabValue} Payees`} title={`${tabValue} Payees`} /> */}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Request ID</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common} >Date Requested</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Supplier</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Amount (KES)</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Raised By</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', fontFamily: 'Poppins', fontSize: '14px' }} className={classes.address_text_common}>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pageState?.data?.map((transaction) => (
                                                <PendingRows key={transaction?.name} row={transaction} />
                                            ))}
                                            {/* {pageState?.data?.map((transaction) => (
                                                <PendingRows key={transaction?.name} row={transaction} />
                                            ))} */}
                                            {/* {RenderPendingRows()} */}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                        component={'div'}
                                        count={pageState?.total}
                                        rowsPerPage={dataGridPageSize}
                                        page={pageState.page}
                                        onPageChange={(newPage, event) => { setPageState((old) => ({ ...old, page: newPage })); }}
                                        onRowsPerPageChange={(e) => { setPageSize(+e.target.value); setPageState((old) => ({ ...old, page: 0 })) }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>
    )

}
