import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";

const CustomPhoneInput = ({ style, value, handlePhoneInputChange }) => {
  const currency = localStorage.getItem("localCurrency") || "KES";

  const [country, setCountry] = useState("ke");

  useEffect(() => {
    if (!currency) {
        setCountry("ke");
    }
    if (currency === "UGX") {
        setCountry("ug");
    }
    if (currency === "NGN") {
        setCountry("ng");
    }
    if (currency === "USD") {
      setCountry("us");
    }
    if (currency === "KES") {
      setCountry("ke");
    }
  }, [currency]);
  return (
    <div>
      {/* inputStyle={{ height: "3.438rem" }} */}
      <PhoneInput inputStyle={{ height: "3.438rem" }} style={style} required country={country} enableSearch={true} value={value} name={"phone"} onChange={handlePhoneInputChange} />
    </div>
  );
};

export default CustomPhoneInput;
