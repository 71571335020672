import { AnimatePresence , motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const PageTransition = ({ children }) => {
    const location = useLocation();
    return (
        <AnimatePresence mode="wait">
            <motion.div
                key={location.pathname}
                initial={{ opacity: 0, y: 20 }} 
                animate={{ opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeInOut" } }} 
                exit={{ opacity: 0, y: -40, transition: { duration: 0.6, ease: "easeInOut" } }} 
            >
            {children}
            </motion.div>
      </AnimatePresence>
      
    );
  };
export default PageTransition