import { Box, Grid, InputAdornment, List, ListItem, Switch, TextField } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import HttpComponent from "../School/MakeRequest"
import Enable2FAPage from "../School/config/enableOtpVerification"

const EnableTwoFactorInNormalBs = () => {
    const [is2FAEnabled, setIs2FAenabled] = useState(false)
    const navigate = useNavigate()
    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1);
    function businessInfo() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/get_business_info',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200) {
                    setIs2FAenabled(data?.response?.data?.is2factorEnabled)
                }
            })
        } catch (error) {
        }
    }
    useEffect(() => {
        businessInfo()
    }, [])
    return (
        <Grid container display={'flex'} width={'100%'} flexDirection={'column'}>
            {state === 'OTPPage' ? <Enable2FAPage is2FAEnabled={is2FAEnabled} /> :
                <>
                    <Grid container item justifyContent="flex-start" alignItems="left" style={{ width: "86%" }}>
                        <label style={{ height: "35px", margin: "24px 718px 24px 0", fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", fontStretch: "normal", fontStyle: "normal", lineHeight: "1.32", letterSpacing: "normal", textAlign: "left", color: "#dc3545" }}>Configuration</label>
                    </Grid>

                    {/*Page Sub title and Search */}
                    <Grid container item justifyContent="space-between" alignItems="left" style={{ width: "86%" }}>
                        <Grid item>
                            <div style={{ fontFamily: "Poppins", fontSize: "20px", fontWeight: "600" }}>
                                <span style={{ color: "#6e7074" }}>Dashboard \ </span>
                                <span style={{ color: "#032541" }}>Accounting Config</span>
                            </div>
                        </Grid>

                        {/*Search input*/}
                        <Grid item>
                            <span style={{ marginRight: "10px", fontFamily: "Poppins", fontSize: "15px", fontWeight: "bold", color: "#032541" }}>Search :</span>
                            <TextField
                                size="small"
                                placeholder={"search"}
                                style={{ height: "1px" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon style={{ width: "15px", height: "15px" }} />
                                        </InputAdornment>
                                    ),
                                }}
                            ></TextField>
                        </Grid>
                    </Grid>

                    {/*Headers*/}
                    <Grid container item justifyContent={"space-between"} alignContent={"center"} style={{ width: "86%", marginTop: "30px" }}>
                        <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
                            Configuration
                        </Grid>
                        <Grid item style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: "600", color: "#032541" }}>
                            Action
                        </Grid>
                    </Grid>
                    <Grid container style={{ width: "86%", marginTop: "30px" }}>
                        <Grid item style={{ width: "100%" }}>
                            <List>
                                <ListItem style={{ marginBottom: "10px", fontFamily: "Poppins", fontSize: "14px", borderRadius: "6px", border: "solid 1px rgba(193,193,193, .3)" }}>
                                    <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                                        <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Accounting</Box>
                                        <Box>
                                            <Switch checked={is2FAEnabled} onClick={() => navigate('/config/auth/enable2fa?OTPPage')} />
                                        </Box>
                                    </Box>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </>
            }
        </Grid>
    )
}

export default EnableTwoFactorInNormalBs