import { Grid, TextField, Button, Breadcrumbs, FormControl, InputLabel } from "@mui/material";
import React, { useState, useEffect } from "react";
import { warehousestyles } from "../styles";
import PhoneInput from "react-phone-input-2";
import HttpComponent from "../../School/MakeRequest";
import { SuccessAlertRightAligned, ErrorAlertRightAligned } from "../../bills/startorderModals/modals";
import { NavigateNext, ArrowBackIos } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router";
import SupplierTypes from "./supplierTypes";
import AddInZedSuppliers from "./addInZedSuppliers";
import GeoLocation from "../../../hooks/useLocation";
import AutocompleteComponent from "../../../common/autoComplete";
import CustomSelect from "../../School/CustomSelectField";
import CustomPhoneInput from "../../../common/customphoneinput";
import { GetDefaultCurrency } from "../../../common/currencyconverter";

export const Addsupplier = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const queryPayams = decodeURIComponent(window.location.search);

  const supplierId = queryPayams.slice(1)


  const [supplier, setSupplier] = useState({ username: "", email: "", location: "", bankAccount: "", bankName: "", bankCode: "" });
  const [customerPhone, setCustomerPhone] = useState("");
  const [supplierBsNo, setSupplierBsNo] = useState("")
  const [inZed, setInZed] = useState("notInZed")
  const [allInputsFilled, setInputField] = useState(false);
  const [success, setSucess] = useState({ state: false, message: "" });
  const [error, setError] = useState({ state: false, message: "" });
  const [selectedValue, setSelectedValue] = useState('')
  const [bankValue, setInputValue] = useState("");
  const [selectedBank, setSelectValue] = useState("")
  const [banks, setBanks] = useState([])
  const [verirfyAccount, setVerirfyAccount] = useState('');

  const getSupplierDetails = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setSupplier({ ...supplier, [name]: value });
  };
  const [banksOptions, setBanksOptions] = useState([])
  const [defaultCurrency, setDefaultCurrency] = useState(GetDefaultCurrency())
  const [banknamesearch, setBankNameSearch] = useState("vir")
  
  const getBanks = () => {
    try {
      HttpComponent({
        method: 'GET',
        url: defaultCurrency === "NGN" ? `/get_transfer_banks?accessBank=true&searchValue=${banknamesearch}` : `/get_transfer_banks` ,
        body: null,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        console.log(data, 'dataaaaaaaaaaaa')
        if (data.status === 200) {
          let banks = data.response.data
          banks = banks?.map((bank) => {
            return { 
              label:bank?.bankName,
              value:bank?.bankCode
            }
          }) 
          setBanksOptions(banks)
        }
      })
    } catch (error) {

    }
  }
  useEffect(() => {
    getBanks()
  }, [banknamesearch])


  useEffect(() => {
    if (supplier.username && supplier.email && selectedValue && customerPhone) {
      setInputField(true);
    }
  }, [supplier.username, supplier.email, selectedValue, customerPhone]);


  console.log(allInputsFilled , 'allInputsFilled')




  const createSupplier = () => {
    if(verirfyAccount !== supplier?.bankAccount){
      setError({ state: true, message: "Bank account number does not match" });
      setTimeout(() => {
        setError({ state: false });
      }, 2000);
      return
    }
    if(defaultCurrency === "NGN" && supplier?.bankName === "") {
      setError({ state: true, message: "Select Bank Account to proceed" });
      setTimeout(() => {
        setError({ state: false });
      }, 2000);
      return
    }
    if(supplier?.bankCode === "") {
      setError({ state: true, message: "BankCode is required" });
      setTimeout(() => {
        setError({ state: false });
      }, 2000);
      return
    }
    if(supplier?.bankName === "") {
      setError({ state: true, message: "BankName is required" });
      setTimeout(() => {
        setError({ state: false });
      }, 2000);
      return
    }
    HttpComponent({
      method: "POST",
      url: `/api/v1/supplier/new`,
      body: inZed === "notInZed" ? {
        name: supplier.username,
        phone: customerPhone[3]?.startsWith(0) ? "+254" + customerPhone?.substring(4) : `${customerPhone}`,
        email: supplier.email,
        location: selectedValue?.description,
        bankAccountNumber: supplier?.bankAccount,
        bankName: supplier?.bankName,
        bankCode: supplier?.bankCode,
        // selectedBank: selectedBank
        // accountNumber:supplier?.bankAccount
      } : { name: supplier.username, phone: customerPhone[3]?.startsWith(0) ? "+254" + customerPhone?.substring(4) : `${customerPhone}`, email: supplier.email, location: supplier.location, supplierBusinessNo: supplierBsNo, supplierType: inZed, paymentTerms: inZed === "inZed" ? " " : "" },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 201) {
          setSupplier({ username: "", email: "", location: "", bankAccount: "", bankName: "", bankCode: "" });
          setCustomerPhone("");
          setSucess({ state: true, message: "Supplier has been added sucessfully!" });
          navigate(`/suppliers`);
        }

        if (data.status === 400) {
          setError({ state: true, message: data.response.error || data.response.message });
        }
        setTimeout(() => {
          setSucess({ state: false });
          setError({ state: false });
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // get single substore details;

  const getSupplier = () => {
    // console.log('end point is running');
    HttpComponent({
      method: "GET",
      url: `/api/get_store_details?storeType=suppliers&storeId=${supplierId}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          console.log(data, "data===>>>get_store_details");
          const { name, phone, email, location, bankAccountNumber, bankName, bankCode } = data.response.data[0].suppliers;

          setSupplier({ username: name, email: email, location: location, bankAccount: bankAccountNumber, bankName: bankName, bankCode: bankCode });
          setCustomerPhone(phone);
          setSelectedValue(location)
          setVerirfyAccount(bankAccountNumber)
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    if (supplierId) {
      getSupplier();
    }
  }, [supplierId]);


  const changeBankCode = () => {
    let bankCode = banksOptions.filter((bank) => bank.value === supplier?.bankName)[0]?.bankCode
    console.log(bankCode, 'bankCode==>>>')
    setSupplier({ ...supplier, bankCode: bankCode?.bankCode })

  }
  useEffect(() => {
    changeBankCode()
  }, [supplier.bankName])





  const editSupplier = () => {
    if(verirfyAccount !== supplier?.bankAccount){
      setError({ state: true, message: "Bank account number does not match" });
      setTimeout(() => {
        setError({ state: false });
      }, 2000);
      return
    }
    HttpComponent({
      method: "POST",
      url: `/api/v1/supplier/edit/${supplierId}`,
      body: { phone: customerPhone, name: supplier.username, location: selectedValue?.description, email: supplier.email },
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        if (data.status === 200) {
          setSucess({ state: true, message: "Supplier has been edited sucessfully!" });
          setTimeout(() => {
            setSucess({ state: false });
            navigate(`/suppliers`)
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // hande customer phone:
  const handlePhoneInputChange = (e) => {
    setCustomerPhone(e)
  }


  const getSupplierBankDetails = (e) => {
    const value = e?.target?.value
    const name = e?.target?.name;
    setSupplier({ ...supplier, [name]: value });
  }

  return (
    <div style={warehousestyles.mainDivStyling}>
      <Grid container style={warehousestyles.gridContainerMargin}>
        <Grid item>
          <span style={warehousestyles.supplierText}>
            {" "}
            <ArrowBackIos onClick={() => navigate(-1)} /> Suppliers
          </span>
        </Grid>
      </Grid>
      <Grid container style={warehousestyles.gridContainerMargin}>
        <Grid item>
          <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
            <span>Dashboard</span>
            <span onClick={() => navigate(-1)}>Suppliers</span>
            {id === "newSupplier" ? <span style={{ color: "red" }}>Add Supplier</span> : <span style={{ color: "red" }}>Edit Supplier</span>}
          </Breadcrumbs>
        </Grid>
      </Grid>

      {id === "addsupplier" && <SupplierTypes />}
      {id === "supplierInZed" && <AddInZedSuppliers setSupplier={setSupplier} setCustomerPhone={setCustomerPhone} setSupplierBsNo={setSupplierBsNo} setInZed={setInZed} />}

      {id === "newSupplier" && (
        <Grid container direction={"column"} width={"100%"}>
          <Grid item style={warehousestyles.addsupplierGridItems}>
            <TextField name="username" value={supplier.username} onChange={getSupplierDetails} style={warehousestyles.addsupplierInputs} label="Supplier Name" variant="outlined" />
          </Grid>

          <Grid>
            <CustomPhoneInput style={{marginBottom: "20px", width:"35%"}} value={customerPhone} handlePhoneInputChange={handlePhoneInputChange} />
            {/* <PhoneInput name="phone" value={customerPhone} onChange={(phone) => setCustomerPhone(phone)} style={warehousestyles.addsupplierInputs} required country={"ke"} enableSearch={true} label={"Mobile Number"} multiline inputStyle={{ height: "55px", width: "70%", margin: "0px 0px 10px 0px" }} /> */}
          </Grid>
          <Grid item style={warehousestyles.addsupplierGridItems}>
            <TextField name="email" value={supplier.email} onChange={getSupplierDetails} style={warehousestyles.addsupplierInputs} label="Email Adress" variant="outlined" />
          </Grid>

          <Grid item style={warehousestyles.addsupplierGridItems}>
            <GeoLocation sx={{ width: "50%" }} onValueChange={(location) => setSelectedValue(location)} selectedValue={selectedValue} />
            {/* <TextField name="location" value={supplier.location} onChange={getSupplierDetails} style={warehousestyles.addsupplierInputs} label="Location" variant="outlined" /> */}
          </Grid>
          { defaultCurrency === "NGN" && 
          (<Grid item style={warehousestyles.addsupplierGridItems}>
            <TextField placeholder="Search banks..." style={warehousestyles.addsupplierInputs} value={banknamesearch} onChange={(e) => setBankNameSearch(e?.target?.value)} />
          </Grid>) }
       
          <Grid item style={warehousestyles.addsupplierGridItems}>
            {/* <AutocompleteComponent000000 label={'Search Bank'} styles={{width:"50%"}} setInputValue={setInputValue}  setSelectValue={setSelectValue} data={banks.length === 0 ? [{name:"No banks", age : 23}] : banks } /> */}
            <CustomSelect sx={{ width: "100%" }} medium value={supplier.bankName} onChange={getSupplierBankDetails} name={"bankName"} placeholder={"Select Bank Name"} options={banksOptions} style={warehousestyles.addsupplierInputs} />
          </Grid>

          <Grid item style={warehousestyles.addsupplierGridItems}>
            <TextField name="bankAccount" value={supplier?.bankAccount} onChange={getSupplierDetails} style={warehousestyles.addsupplierInputs} label="Bank Account Number" variant="outlined" />
          </Grid>
          <Grid item style={warehousestyles.addsupplierGridItems}>

            {supplier?.bankAccount !== verirfyAccount ? <>

              {/* <TextField style={warehousestyles.addsupplierInputs}
                error
                id="outlined-                               "
                placeholder='e.g 123456'
                type='number'
                value={verirfyAccount}
                onChange={(e) => setVerirfyAccount(e.target.value)}
                multiline
                required
              /> */}
              <TextField error name="bankAccount" value={verirfyAccount} onChange={(e) => setVerirfyAccount(e.target.value)} style={warehousestyles.addsupplierInputs} label="Bank Account Number" variant="outlined" />


            </> : <>

              <TextField  name="bankAccount" value={verirfyAccount} onChange={(e) => setVerirfyAccount(e.target.value)} style={warehousestyles.addsupplierInputs} label="Confirm Bank Account Number" variant="outlined" />


            </>}
          </Grid>


          <Grid item style={warehousestyles.addsupplierGridItems}>
            <div style={warehousestyles.rightAlignedButtonHolder}>

              {
                supplierId ?
                  <Button style={!allInputsFilled ? warehousestyles.createcustomerInactiveBtn : warehousestyles.createCustomerActiveBtn} onClick={() => (allInputsFilled ? editSupplier() : null)}>
                    update
                  </Button> : <Button style={!allInputsFilled ? warehousestyles.createcustomerInactiveBtn : warehousestyles.createCustomerActiveBtn} onClick={() => (allInputsFilled ? createSupplier() : null)}>
                    Add
                  </Button>
              }



            </div>
          </Grid>
        </Grid>
      )}

      {success && <SuccessAlertRightAligned message={success.message} sucess={success.state} />}

      {error && <ErrorAlertRightAligned error={error.state} message={error.message} />}
    </div>
  );
};
