import { Breadcrumbs, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HttpComponent from "../MakeRequest";
import CustomSearchInput from "../CustomSearchInput";
import Checkbox from '@mui/material/Checkbox';
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import StudentPromotionNotification from "./promotionsNotifications";
import lodash from "lodash";
//import { escape } from 'lodash';

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Student Promotion</Typography>,
    <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Promote Student</Typography>
]
export default function PromoteStudentNext() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [selectedSchoolGrade, setSelectedSchoolGrade] = useState('')
    const [nextGrade, setNextGrade] = useState('')
    const [nextTerm ,setNextTerm] = useState('')
    const [foundGrades, setFoundGrades] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [schoolTerms ,setSchoolTerms] = useState([])
    const [selectedTerm ,setSelectedTerm] = useState('')
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, selectedRows: [] })
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const navigate = useNavigate()
    const queryparams = decodeURIComponent(window.location.search);
    const [selectedChangeType ,setSelectedChangeType] = useState('grade')
    const stateDate = queryparams.slice(1)
    const state = stateDate.split('?')[2]
    const schoolType = localStorage.getItem("schoolType")

    useEffect(()=>{
        if(selectedChangeType === 'term'){
            getStudentInGradeTerm();
        }else if (selectedChangeType === 'grade'){
            getStudentInGrade();
        }
    },[selectedSchoolGrade,selectedTerm, selectedChangeType , dataGridPageSize, pageState?.pageSize,searchValue])
   

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(pageState?.data.map((row) => row?.studentId));
        }
        setSelectAll(!selectAll);
    };

    // get school terms //
 
    console.log(selectedSchoolGrade , 'selectedSchoolGrade')

    function getTermsinSchool() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/get_school_terms`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 201) {
                    setSchoolTerms(data?.response?.data)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getTermsinSchool()
    }, [])




    //get grades in a school

    function getGradesinSchool() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/get_school_grades`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 201) {
                    setFoundGrades(data?.response?.data)
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getGradesinSchool()
    }, [])


    //get students in grade

    function getStudentInGrade() {
        //setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/get_students_by_grade?grade=${lodash.escape(selectedSchoolGrade)}&page=${pageState.page}&limit=${dataGridPageSize}&searchValue${searchValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    const responsedata = data?.response?.data
                    const selectedRowIds = responsedata?.map((row) => row?.studentId);
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data, selectedRows: selectedRowIds })
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getStudentInGrade()
    }, [selectedSchoolGrade, dataGridPageSize, pageState?.pageSize])


    // get student in grade & term /api/get_students_by_grade_and_term?grade

    function getStudentInGradeTerm() {
        setPageState((old) => ({ ...old, isLoading: true }));
        if(selectedSchoolGrade && selectedTerm )
        try {
            HttpComponent({
                method: 'GET',
                url:`/api/get_students_by_grade_and_term?grade=${lodash.escape(selectedSchoolGrade)}&term=${lodash.escape(selectedTerm)}&page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    const responsedata = data?.response?.data
                    const selectedRowIds = responsedata?.map((row) => row?.studentId);
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data, selectedRows: selectedRowIds })
                }
            })

        } catch (error) {
            console.log(error , 'im the erorrrrrrrrrr')
        }
    }


    const handleRowSelect = (id) => {
        const selectedIndex = selectedRows.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }

        setSelectedRows(newSelected);
    };

    useEffect(() => {
        setSelectedRows(pageState?.data?.map((row) => row?.studentId));
    }, [pageState?.data]);

    useEffect(() => {
    }, [selectedRows]);






    //promote to next grade

    function promoteToNextGrade() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/students/changeLevel`,
                body: {
                    changeType: "grade",
                    fromGrade: selectedSchoolGrade,
                    toGrade: nextGrade,
                    studentIds: selectedRows
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 201) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    setTimeout(() => {
                        navigate(-1)
                    } , 1500)
                 
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })

        } catch (error) {

        }
    }
    //console.log(selectedSchoolGrade , 'yoooo')

    function promoteToNextTerm() {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/students/changeLevel`,
                body: {
                    changeType: "term",
                    fromGrade:selectedSchoolGrade,
                    toGrade:selectedSchoolGrade,
                    fromTerm: selectedTerm,
                    toTerm: nextTerm,
                    studentIds: selectedRows
                },
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 201) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    setTimeout(() => {
                        navigate(-1)
                    },1500)
                   
                } else {
                    setErrorShow({ state: true, message: data?.response?.message })
                }
            })

        } catch (error) {

        }
    }

    

    // student table
    const allStudentsColumns = [
        {
            field: 'selection',
            headerName: (
                <Checkbox
                    checked={selectedRows?.length === pageState?.data.length}
                    onChange={handleSelectAll}
                    inputProps={{ 'aria-label': 'Select all students' }}
                />
            ),
            sortable: false,
            width: 50,
            renderCell: (params) => (
                <Checkbox
                    checked={selectedRows?.includes(params?.row?.studentId)}
                    onChange={() => handleRowSelect(params?.row?.studentId)}
                    inputProps={{ 'aria-label': 'Select this student' }}
                />
            ),
        },
        {
            field: 'name', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Admission No</strong>) }, renderCell: (params) => {
                return (<><span style={{ marginLeft: "10px" }}>{params?.row?.itemNumber}</span></>)
            }
        },
        { field: 'description', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Student Name</strong>) }, renderCell: (params) => { return (<span>{params?.row?.firstName} {params?.row?.lastName}</span>) } },
        { field: 'year', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Year</strong>) }, renderCell: (params) => { return (<span>{params?.row?.year}</span>) } },
        { field: 'term', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Term</strong>) }, renderCell: (params) => { return (<span>{params?.row?.term}</span>) } },
    ]


    return (
        <Grid direction={'column'} container>
            {state === 'notificationtrans' ? <StudentPromotionNotification /> : <>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Grid item>
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Promote Students</Typography>
                </Grid>
                <Grid item display={'flex'} mt={2}>
                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                        <Grid item>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item mt={3}>
                    <FormControl style={{ width: '50%' }}>
                        <InputLabel id="demo-simple-select-label">Change Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedChangeType}
                            label="Change Type"
                            onChange={(e) => setSelectedChangeType(e.target.value)}
                        >
                           {schoolType === "University/College" ? (
                                <MenuItem value="term">Semester</MenuItem>
                            ) : (
                                <>
                                    <MenuItem value="grade">Grade</MenuItem>
                                    <MenuItem value="term">Term</MenuItem>
                                </>
                            )}
                            
                          
                        </Select>
                    </FormControl>
                </Grid>
                <Grid  item display={'flex'} justifyContent={'space-around'} width={'50%'} mt={3}>
                    <FormControl style={{ width: '50%' }}>
                        <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedSchoolGrade}
                            label="Grade"
                            onChange={(e) => setSelectedSchoolGrade(e.target.value)}
                        >
                            {foundGrades?.map((grade) => {
                                return (
                                    <MenuItem value={grade?.schoolGrades}>{grade?.schoolGrades}</MenuItem>
                                )
                            })}

                        </Select>
                    </FormControl>
                    {selectedChangeType === 'term' ? 
                    <FormControl style={{ width: '50%' ,marginLeft: "10px" }}>
                        <InputLabel id="demo-simple-select-label">Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedTerm}
                            label="Term"
                            onChange={(e) => setSelectedTerm(e.target.value)}
                        >
                           {schoolTerms?.map((grade) => {
                                return (
                                    <MenuItem value={grade?.schoolGrades}>{grade?.schoolGrades}</MenuItem>
                                )
                            })}

                        </Select>
                    </FormControl>: 
                    <FormControl style={{ width: '50%', marginLeft: "10px" }}>
                        <InputLabel id="demo-simple-select-label">To</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={nextGrade}
                            label="To"
                            onChange={(e) => setNextGrade(e.target.value)}
                        >
                            {foundGrades?.filter(item => item?.schoolGrades !== selectedSchoolGrade).map((grade) => {
                                return (
                                    <MenuItem value={grade?.schoolGrades}>{grade?.schoolGrades}</MenuItem>
                                )
                            })}

                        </Select>
                    </FormControl>
}
                </Grid>
                {selectedChangeType === 'term' ?
                <Grid  item display={'flex'} justifyContent={'space-around'} width={'50%'} mt={3}>
                    <FormControl style={{ width: '50%' }}>
                        <InputLabel id="demo-simple-select-label">From Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedTerm}
                            label="From Term"
                            disabled
                        >
                            <MenuItem value={selectedTerm}>{selectedTerm}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl style={{ width: '50%', marginLeft: "10px" }}>
                        <InputLabel id="demo-simple-select-label">To Term</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={nextTerm}
                            label="To Term"
                            onChange={(e) => setNextTerm(e.target.value)}
                        >
                            {schoolTerms?.filter(item => item?.schoolGrades !== selectedTerm).map((grade) => {
                                return (
                                    <MenuItem value={grade?.schoolGrades}>{grade?.schoolGrades}</MenuItem>
                                )
                            })}

                        </Select>
                    </FormControl>
                </Grid>:null}
                <Grid item display={'flex'} direction={'column'} width={'50%'} alignItems={'center'} mt={3}>
                    <Grid container display={'flex'}>
                        <Grid item>
                            <Typography sx={{ color: '#032541', fontWeight: 500, fontSize: '16px' }}>Search Students</Typography>
                        </Grid>
                    </Grid>
                    <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Grid item width={'100%'}>
                            <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'search'} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item width={'50%'}>
                    <DataGrid
                        components={{ NoRowsOverlay: NoRowsOverlay }}
                        sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100,]}
                        rowCount={pageState?.total}
                        loading={pageState?.isLoading}
                        pagination
                        page={pageState?.page - 1}
                        pageSize={dataGridPageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={allStudentsColumns}
                        rows={pageState?.data}
                        getRowId={row => row?.studentId}
                        checkboxSelection={false}
                    />
                </Grid>
                <Grid mt={2} item style={{ width: "50%" }} display={'flex'} justifyContent={'flex-end'}>
                    <Button disabled={selectedChangeType === 'term'} onClick={promoteToNextGrade} style={{ background: "#fff", color: "#032541", border: "1px solid #032541", width: "195px", marginRight: "5px", textTransform: "inherit" }}>Promote to Next Grade</Button>
                    <Button disabled={selectedChangeType === 'grade'} onClick={promoteToNextTerm} style={{ background: "#032541", textTransform: "inherit", width: "195px", color: "#fff" }}>Promote to Next Term</Button>
                </Grid>
            </>}
        </Grid>
    )

}