import {Breadcrumbs, Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React, {useEffect, useState} from "react";
import backImg from "../../../School/Images/back-icn.svg";
import {useNavigate, useParams} from "react-router-dom";
import cardIcon from "../../../../common/images/card-icn.svg";
import mpesaIcon from "../../../../common/images/mpesa-icn.svg";
import {useSelector} from "react-redux";
import HttpComponent from "../../../School/MakeRequest";
import DateFormatter from "../../../../utils/dateFormatter";
import CurrencyFormatter from "../../../../utils/currencyFormatter";
import {SuccessAlert} from "../../../snackBar Alerts/successAlert";
import {ErrorAlert} from "../../../snackBar Alerts/errorAlert";
import {TenantInvoiceActionModal} from "./modals/tenantInvoiceActionModal";
import { useTheme } from "@mui/material/styles";


// Bread Crumbs
const breadcrumbs = [
	<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Dashboard</Typography>,
	<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Tenants</Typography>,
	<Typography style={{fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.inactive">Invoices</Typography>,
	<Typography style={{color : '#dc3545', fontFamily : 'Poppins', fontSize : '14px'}} key="3" color="text.error">Preview Invoice</Typography>
];

export default function TenantPreviewInvoice(props) {
		const navigate = useNavigate()
		const theme = useTheme();
		const {invoiceNumber} = useParams()
		const isMobileUI = useMediaQuery(theme.breakpoints.down("sm"));

		const {X_Authorization} = useSelector((store) => store.user)
		const [invoiceData, setInvoiceData] = useState([])

		const {invoiceStatus, invoiceBalance, invoiceAmount, items, sentTo, businessEmail, businessLocation,
			businessName, businessPhone, customerName, customerPhoneNumber,itemNumber,houseType, unitType, createdAt} = invoiceData

		const [errorShow, setErrorShow] = useState({state: false, message: ""})
		const [successShow, setSuccessShow] = useState({state: false, message: ""})
		const [actionType, setActionType] = useState({state : false, popupType : "", amount : invoiceBalance, customerName : customerName})


		// Fetch Invoice Data
		function fetchInvoiceData() {
			try {
				HttpComponent({
					method : 'GET',
					url : `/api/get_invoice_by_invoice_number?invoiceNumber=${invoiceNumber}`,
					token : X_Authorization
				})
					.then((data) => {
						console.log(data ,'response ya invoice')
						if (data.status === 200) {setInvoiceData(data.response.data)
						} else {console.log(`Error On Getting Invoice Information`)}
					})
					.catch((e) => {console.log(`Error On Getting Invoice Information`, e.message)})
			} catch (e) {console.log(`Error On Getting Invoice Information`, e.message)}
		}

		console.log(invoiceData,'in')
		// Forfeit Invoice
		function forfeitInvoice() {
			try {
				HttpComponent({
					method : 'POST',
					url : `/api/v1/invoice/forfeit_or_cancel`,
					token : X_Authorization,
					body : {invoiceNumber, action : 'FORFEIT'}
				})
					.then((data) => {
						if (data.status === 200) {handleSuccessPostAction(data.response.message)}
						else {handleFailedPostAction(data.response.message); console.log(`Error On Forfeiting Invoice Information`)}
					})
					.catch((e) => {console.log(`Error On Forfeiting Invoice Information`, e.message)})
			} catch (e) {console.log(`Error On Forfeiting Invoice Information`, e.message)}
		}

		// Cancel Invoice
		function cancelInvoice() {
			try {
				HttpComponent({
					method : 'POST',
					url : `/api/v1/invoice/forfeit_or_cancel`,
					token : X_Authorization,
					body : {invoiceNumber, action : 'CANCEL'}
				})
					.then((data) => {
						if (data.status === 200) {handleSuccessPostAction(data.response.message)}
						else {handleFailedPostAction(data.response.message); console.log(`Error On Cancelling Invoice Information`)}
					})
					.catch((e) => {console.log(`Error On Cancelling Invoice Information`, e.message)})
			} catch (e) {console.log(`Error On Cancelling Invoice Information`, e.message)}
		}

		// Resend Invoice
		function resendInvoice() {
			HttpComponent({
				method: 'GET',
				url: `/api/resendInvoice?invoiceNumber=${invoiceNumber}`,
				body: null,
				token: X_Authorization
			}).then((data)=>{
				console.log(data ,'user to rreceive invoice')
				if(data.status === 200){setSuccessShow({state:true, message:`${invoiceNumber} successfully resent to ${data.response.data.sentTo}`})}
				else{setErrorShow({state:true, message:data.response.message})}
			}).catch((error)=>{
				console.error(error.message);
			})
		}
		

		// Handle Success Notification and redirection
		function handleSuccessPostAction(response) {
			setSuccessShow({state : true, message: response})
			setTimeout(() => {
				navigate(-1)
			}, 1500)
		}

		// Handle Error Notification
		function handleFailedPostAction(response) {
			setErrorShow({state : true, message: response})
		}

		// useEffect
		useEffect(() => {
			fetchInvoiceData()
		}, [])

		const postData = {invoiceNumber, action : actionType.popupType}
		function handleOnConfirm () {
			actionType?.popupType?.toUpperCase() === 'FORFEIT' ? forfeitInvoice() :
				actionType?.popupType?.toUpperCase() === 'CANCEL' ? cancelInvoice() : null
		}

		return (
			<div>

				{/*Action Modal*/}
				<TenantInvoiceActionModal onClose={() => {setActionType({...actionType, state : false})}} onConfirm={handleOnConfirm} data={postData} open={actionType.state} popupType={actionType.popupType} amount={actionType.amount} customerName={actionType.customerName}/>

				{/*Success Alert*/}
				<SuccessAlert vertical="top" horizontal="right" onClose={()=>setSuccessShow({...successShow, state:false})} open={successShow.state} message={successShow.message}/>

				{/*Error Alert*/}
				<ErrorAlert vertical="top" horizontal="right" onClose={()=>setErrorShow({...errorShow, state:false})} open={errorShow.state} message={errorShow.message}/>

				{/*Header Container*/}
				<Grid container justifyContent={'space-between'} mb={3}>


					{/*Header*/}
					<Grid item>
						<img alt="" style={{marginRight : '10px'}} src={backImg} onClick={()=>navigate(-1)} />
						<span style={{color : '#032541', fontWeight : 'bold', fontSize : '25px'}}>Preview Invoice</span>
						<Grid item>
							<Breadcrumbs style={{fontFamily : 'Poppins', fontSize : '14px'}} separator={<FiberManualRecordIcon style={{fontSize:"0.625rem", fontFamily : 'Poppins', color:"#e1e5e8"}} />} aria-label="breadcrumb">
								{breadcrumbs}
							</Breadcrumbs>
						</Grid>
					</Grid>

					{/*Cancel & Forfeit buttons*/}

					<Grid item>

						{/*BreadCrumb Container & Add More units*/}
						<Grid container justifyContent={'flex-end'} spacing={2}>
							{/*resend Invoice*/}
							{invoiceStatus === 'Paid' || 'CANCEL'  ? null : 

							<Grid item>
								<Button onClick={resendInvoice} style={{border : '1px solid #032541', textTransform : 'none', color : '#032541', background : '#fff', width : '146px', height : '45px', fontWeight : '600'}}>
									Resend Invoice
								</Button>
							</Grid>
							}

							{invoiceStatus === 'CANCEL' || invoiceStatus === 'FORFEIT' || invoiceStatus === 'Paid' || invoiceStatus === 'Partially Paid' ? null : <>
							{/*Cancel Invoice*/}
							<Grid item>
								<Button onClick={resendInvoice} style={{textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontWeight : '600'}}>
									Send Invoice
								</Button>
							</Grid>
							<Grid item>
								<Button
									style={{ width:isMobileUI ? "150px" : "180px", height: isMobileUI ? "40px" : "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white", marginRight: '10px' }}
									onClick={() => navigate("/parent/pay/" + invoiceNumber)}>
									Request Payment
								</Button>
							</Grid>
							<Grid item>
								<Button onClick={() => {setActionType({state: true, popupType : 'Cancel', amount : invoiceBalance, customerName})}} style={{border : 'solid 1px #dc3545', textTransform : 'none', color : '#dc3545', background : '#fff', width : '146px', height : '45px', fontWeight : '600'}} >
									Cancel Invoice
								</Button>
							</Grid>
							{/*Forfeit Invoice*/}
							<Grid item>
								<Button onClick={() => {setActionType({state: true, popupType : 'Forfeit', amount : invoiceBalance, customerName})}} style={{textTransform : 'none', color : '#fff', background : '#032541', width : '146px', height : '45px', fontWeight : '600'}}>
									Forfeit Invoice
								</Button>
							</Grid></>}

						</Grid>
					</Grid>
				</Grid>

				{/*Main Container*/}
				<Grid container justifyContent={'center'} alignContent={'center'} >

					{/*Invoice Header, Status & Number*/}
					<Grid container direction={'row'} justifyContent={'space-between'} style={{background : '#fafafa', padding : '25px', width:'95%'}}>

						{/*Name, Date*/}
						<Grid item>
							<Grid container justifyContent={'space-between'} direction={'column'}>
								<Grid item>
									<span style={{fontSize : '40px', fontWeight : 'bold', color : '#000'}}>INVOICE</span>
								</Grid>
								<Grid item mt={3}>
									<span style={{color : '#000', fontSize : '14px', fontWeight : 'bold'}}>Date:</span>
								</Grid>
								<Grid item>
									{DateFormatter(createdAt)}
								</Grid>
							</Grid>
						</Grid>

						{/*Invoice State & Number*/}
						<Grid item>
							<Grid container justifyContent={'space-between'} direction={'column'}>
								<Grid item style={{textAlign : 'right'}}>
									<span style={{fontSize : '40px', fontWeight : 'bold', color : invoiceStatus === 'Unpaid' ? '#dc3545' :
											invoiceStatus === 'Paid' ? 'rgba(23, 174, 123)' :
												invoiceStatus === 'Partially Paid' ? 'rgba(255, 133, 3)' :
													invoiceStatus === "FORFEIT" ? 'rgba(18, 104, 240)':
														invoiceStatus === "CANCEL" ? '#dc3545' : 'rgba(23, 174, 123)'}}>{invoiceStatus === 'CANCEL' ? 'CANCELLED' : invoiceStatus === 'FORFEIT' ? 'FORFEITED' : invoiceStatus}</span>
								</Grid>
								<Grid style={{ textAlign : 'right'}} item mt={3}>
									<span style={{color : '#000', fontSize : '14px', fontWeight : 'bold'}}>Invoice No:</span>
								</Grid>
								<Grid style={{ textAlign : 'right'}} item>
									<span>{invoiceNumber}</span>
								</Grid>
							</Grid>
						</Grid>

					</Grid>

					{/*Billing Information*/}
					<Grid mt={2} container direction={'row'} justifyContent={'space-between'} style={{padding : '25px', width:'95%'}}>

						{/*Apartment Name & Location & Email & Phone*/}
						<Grid item>
							<Grid container justifyContent={'space-between'} direction={'column'}>
								<Grid item>
									<span style={{fontSize : '18px', fontWeight : 'normal', color : '#000'}}>From</span>
								</Grid>
								<Grid item mt={1}>
									<span style={{color : '#000', fontSize : '13px', fontWeight : 'bold'}}>{businessName}</span>
								</Grid>
								<Grid item>
									<span style={{color : '#000', fontSize : '13px', fontWeight : 'normal'}}>{businessLocation}</span>
								</Grid>
								<Grid item>
									<span style={{color : '#000', fontSize : '13px', fontWeight : 'normal'}}>{businessEmail}</span>
								</Grid>
								<Grid item>
									<span style={{color : '#000', fontSize : '13px', fontWeight : 'normal'}}>{businessPhone}</span>
								</Grid>
							</Grid>
						</Grid>

						{/*Tenant Name & Location & Email & Phone*/}
						<Grid item>
							<Grid container justifyContent={'space-between'} direction={'column'}>
								<Grid item>
									<span style={{fontSize : '18px', fontWeight : 'normal', color : '#000'}}></span>
								</Grid>
								<Grid style={{ textAlign : 'right'}} item mt={1}>
									<span style={{color : '#000', fontSize : '13px', fontWeight : 'bold'}}>Billed to:</span>
								</Grid>
								<Grid style={{ textAlign : 'right'}} item>
									<span style={{color : '#000', fontSize : '13px', fontWeight : 'normal'}}>{customerName}</span>
								</Grid>
								<Grid style={{ textAlign : 'right'}} item>
									<span style={{color : '#000', fontSize : '13px', fontWeight : 'normal'}}>{sentTo}</span>
								</Grid>
								<Grid style={{ textAlign : 'right'}} item>
									<span style={{color : '#000', fontSize : '13px', fontWeight : 'normal'}}>{customerPhoneNumber}</span>
								</Grid>
							</Grid>
						</Grid>

					</Grid>

					{/*Items Header*/}
					<Grid container direction={'row'} justifyContent={'flex-start'} style={{border : 'dotted #b2b4bb 2px', paddingLeft : '25px', paddingTop : '8px', paddingBottom : '8px', width:'95%'}}>

						{/*House Number*/}
						<Grid item mr={15}>
							<span style={{fontSize : '16px', color : '#000'}}>HOUSE NUMBER: </span>
							<span style={{fontSize : '16px', color : '#000', fontWeight : 'bold'}}>{itemNumber}</span>
						</Grid>

						{/*House Type*/}
						<Grid item>
							<span style={{fontSize : '16px', color : '#000'}}>HOUSE TYPE: </span>
							<span style={{fontSize : '16px', color : '#000', fontWeight : 'bold'}}>{houseType}</span>
						</Grid>
					</Grid>

					{/*Items Descriptions, qty, amount, total, name*/}
					<Grid container style={{padding : '25px', width:'95%'}}>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell style={{borderBottom:"none", fontSize : '14px', fontWeight : 'bold', fontFamily : 'Poppins'}}>Description</TableCell>
									<TableCell style={{borderBottom:"none", fontSize : '14px', fontWeight : 'bold', fontFamily : 'Poppins'}}>Price (KES)</TableCell>
									<TableCell style={{borderBottom:"none", fontSize : '14px', fontWeight : 'bold', fontFamily : 'Poppins'}}>Qty</TableCell>
									<TableCell style={{borderBottom:"none", fontSize : '14px', fontWeight : 'bold', fontFamily : 'Poppins', textAlign : 'right'}}>Total (KES)</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{items?.map((items, index) => (
									<TableRow key={index}>
										<TableCell style={{borderBottom:"none", fontSize : '14px', fontFamily : 'Poppins'}}>{index + 1}. {items.productName}</TableCell>
										<TableCell style={{borderBottom:"none", fontSize : '14px', fontFamily : 'Poppins'}}>{CurrencyFormatter(items.productPrice * 1, 'KES')}</TableCell>
										<TableCell style={{borderBottom:"none", fontSize : '14px', fontFamily : 'Poppins'}}>{items.quantity * 1}</TableCell>
										<TableCell style={{borderBottom:"none", fontSize : '14px', fontFamily : 'Poppins', textAlign : 'right'}}>{CurrencyFormatter(((items.quantity * 1) * (items.productPrice * 1)), 'KES')}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Grid>

					{/*Items Total*/}
					<Grid container justifyContent={'flex-end'} style={{padding : '25px', width:'95%'}}>

						{/*Total Amount*/}
						<Grid item style={{background : '#fafafa', padding: '10px', width : '30%', textAlign : 'right'}} >
							<span style={{color : '#032541', fontSize : '14px'}}>Total : </span>
							<span style={{color : '#032541', fontWeight : 'bold', fontSize : '14px'}}>{CurrencyFormatter(invoiceAmount * 1, 'KES')}</span>
						</Grid>
					</Grid>

					{/*Items Total Summary*/}
					<Grid container justifyContent={'flex-end'} style={{padding : '25px', width:'95%'}}>

						{/*Total Summary breakdown*/}
						<Grid item style={{background : '#fafafa', padding: '10px', width : '30%'}} >
							<Grid container justifyContent={'space-between'} direction={'row'}>

								{/*Summary Labels*/}
								<Grid item>
									<Grid container direction={'column'} justifyContent={'space-between'} spacing={1}>

										{/*transfer Charges*/}
										<Grid item>
											<span style={{color : "#000", fontSize : '14px'}}>Transfer Charges</span>
										</Grid>
										<Grid item>
											<span style={{color : "#000", fontSize : '14px'}}>Credit balance</span>
										</Grid>
										<Grid item>
											<span style={{color : "#dc3545", fontSize : '20px'}}>Total Amount</span>
										</Grid>
									</Grid>
								</Grid>

								{/*Summary Amount*/}
								<Grid item style={{textAlign : 'right'}}>
									<Grid container direction={'column'} justifyContent={'space-between'} spacing={1}>

										{/*transfer Charges*/}
										<Grid item>
											<span style={{color : '#000', fontWeight : 'bold', fontSize : '14px'}}>KES 0.00</span>
										</Grid>
										<Grid item>
											<span style={{color : '#000', fontWeight : 'bold', fontSize : '14px'}}>KES 0.00</span>
										</Grid>
										<Grid item>
											<span style={{color : "#dc3545", fontSize : '20px', fontWeight : 'bold'}}>{CurrencyFormatter(invoiceBalance * 1, 'KES')}</span>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					{/*Payment Methods*/}
					<Grid container justifyContent={'center'} alignContent={'center'} direction={'column'} spacing={2} style={{borderTop : 'dotted #bfbfbf 2px', borderBottom : 'dotted #bfbfbf 2px', width:'70%'}}>

						{/*Header*/}
						<Grid item mt={3} style={{textAlign : 'center'}}>
							<span style={{color : '#032541', fontSize : '16px', fontWeight : 'bold'}}>Accepted Payment Methods</span>
						</Grid>

						{/*Payment Icons (mpesa & Visa*/}
						<Grid item mb={4}>
							<Grid container justifyContent={'space-between'} alignContent={'center'} direction={'row'} spacing={5}>

								{/*mpesa*/}
								<Grid item>
									<img style={{width : '130px', height : '60px'}} src={mpesaIcon} alt={'mpesa'}/>
									<Grid item style={{textAlign : 'center'}} mt={1}><span>Mpesa</span></Grid>
								</Grid>

								{/*Card*/}
								<Grid item>
									<img style={{width : '130px', height : '60px'}} src={cardIcon} alt={'card'} />
									<Grid item style={{textAlign : 'center'}} mt={1}><span>Card</span></Grid>
								</Grid>
							</Grid>

						</Grid>
					</Grid>

					{/*System Generated Statement*/}
					<Grid container justifyContent={'center'} alignContent={'center'} direction={'column'} spacing={2} mt={1} style={{width:'70%'}}>
						<Grid item>
							<span style={{color : '#727272', fontSize : '12px'}}>This system-generated invoice is created without any alteration whatsoever.</span>
						</Grid>
					</Grid>

					{/*Invoice Footer*/}
					<Grid container justifyContent={'center'} alignContent={'center'} direction={'column'} spacing={2} mt={1} style={{background : '#fafafa', padding : '25px', width:'95%'}}>
						<Grid item>
							<span style={{color : '#000', fontSize : '13px', fontFamily : 'Poppins'}}> Powered by </span>
							<span style={{color : '#000', fontSize : '13px', fontFamily : 'Poppins', fontWeight : 'bold'}}> ZED Payments Limited </span>
							<span style={{color : '#000', fontSize : '13px', fontFamily : 'Poppins'}}> .  info@zed.business    .   v1.0.2</span>
						</Grid>
					</Grid>
				</Grid>

			</div>
		)
	}
