import { Button, Grid, Typography, CircularProgress, Box } from "@mui/material"
import { ErrorAlert } from "../snackBar Alerts/errorAlert"
import { SuccessAlert } from "../snackBar Alerts/successAlert"
import PinInput from 'react-pin-input';
import { useEffect, useState } from "react";
import loginImage from "../../images/logisvg.svg";
import { makeStyles } from "@mui/styles";
import loginLogo from "../../images/zedLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleBusinessBranches } from "../../features/businessBranchesSlice";
import { handleSetBusinessData } from "../../features/tenantBusinessSlice";
import { changeToken, logInUser } from "../../features/userSlice";
import { handleBusinessCategory } from "../../features/businessCategorySlice";
import { handleSchoolTypeName } from "../../features/schoolType";
import { handleBranchDetails } from "../../features/branchSlice";
import jwt from "jwt-decode";
import StockFunctions from "../products/stockComponents/stockEndpoints";
import { switchWarehouseState } from "../../features/sideBarSlice";
const baseUrl = process.env.REACT_APP_BASE_URL;



const useStyles = makeStyles((theme) => ({
    imgRes: {
        [theme.breakpoints.down("md")]: {
            alignItems: "center",
            width: "100%",
            objectFit: "contain",
            height: "100%",
            display: "inline-block",
        },
        [theme.breakpoints.up("md")]: {
            alignItems: "center",
            justifyContent: "center",
            width: "40rem",
            paddingLeft: "5rem",
            objectFit: "contain",
            height: "100%",
            display: "inline-block",
        },
        [theme.breakpoints.down("xs")]: {
            display: "none",
            alignItems: "center",
            width: "100%",
            objectFit: "contain",
            height: "100%",
        },
    },
    pinsty: {
        [theme.breakpoints.up("sm")]: {
            width: "min-content",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    gridLayout: {
        [theme.breakpoints.up("md")]: {
            paddingRight: "10rem",
        },
        [theme.breakpoints.down("md")]: {
            paddingLeft: "1rem",
            paddingRight: "1rem",
        },
    },
}));


const OtpLogin = () => {
    const classes = useStyles();
    const [pin, setPin] = useState("")
    const [showPin, setShowPin] = useState(false)
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [isLoading, setIsLoading] = useState(false)
    let { loginToken, tokenMessage, loginResultData } = useSelector((store) => store.verifyOtpMessage);
    const stockfunctions = new StockFunctions();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [barManState, setBarManState] = useState();

    // retyr otp
    const ResendOtp = () => { }

    // fetch configs
    const fetchBarManConfigs = async () => {
        try {
            const response = await fetch(baseUrl + "/api/get_enable_bar_and_kitchen", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            });
            await response
                .json()
                .then((data) => {
                    setBarManState(data?.status);
                    // localStorage.setItem('barMan', data.status)
                    localStorage.setItem("barMan", "undefined");
                })
                .catch((err) => {
                    console.log("error getting stocks", err.message);
                });
        } catch (e) {
            console.log(e.message);
        }
    };

    // fetch business category 

    async function fetchBusinessCategory() {
        const response = await fetch(baseUrl + "/api/get_business_category", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Authorization": localStorage.getItem("X-Authorization"),
            },
        });
        const { businessCategory } = await response.json();
        dispatch(handleBusinessCategory(businessCategory));
    };

    // generate new token after login

    async function getnewTokenAfterLogin(branchId, oldToken) {
        if (!branchId || !oldToken) {
            throw new Error('Missing required parameters: branchId or token');
        }

        try {
            const response = await fetch(baseUrl + `/api/get_token_after_invite`, {
                method: "POST",
                headers: {
                    "X-Authorization": oldToken,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({ branchId: branchId }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (!data?.data?.token) {
                throw new Error('No token received in response');
            }

            return data;

        } catch (error) {
            console.error("Token generation error:", error);
            throw error; // Re-throw to be handled by the calling function
        }
    }

    //fetch branch 

    async function fetchBranches(token) {
        try {
            const response = await fetch(baseUrl + "/api/getBusinessBranchesByBid", {
                method: "GET",
                headers: {
                    "X-Authorization": token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            const data = await response.json();
            if (response?.status === 401) {
                return navigate("/logout");
            }
            if (response?.status === 200) {
                dispatch(handleBusinessBranches({ branchesArr: data.data }));
                dispatch(handleSetBusinessData(data.data))

            }
        } catch (e) {
            console.log(e);
        }
    };
    // switch branch

    async function switchBranch(branch_id, oldToken, business_id) {
        try {
            const response = await fetch(baseUrl + `/api/get_business_token`, {
                method: "POST",
                headers: {
                    "X-Authorization": oldToken,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    branch_id,
                    oldToken,
                    business_id,
                }),
            });
            if (response.status === 401) return (window.location.href = "/logout");
            const result = await response.json();
            dispatch(changeToken({ token: result.data.newToken }));
            localStorage.removeItem("X-Authorization");
            localStorage.setItem("X-Authorization", result.data.newToken);
            fetchBranches(result?.data?.newToken);
        } catch (e) {
            console.log("Error Switching branch", e.message);
        }
    };

    const fetchConfig = async (token) => {
        try {
            const response = await fetch(baseUrl + "/api/getAllConfigurations", {
                method: "GET",
                headers: {
                    "X-Authorization": token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            const data = await response.json();
            if (response?.status === 401) {
            }
            if (response?.status === 200) {
                const loginSessionConfig = data?.data.find(config => config.configName === "loginSession");
                const configValue = loginSessionConfig ? loginSessionConfig.configValue : null;
                localStorage.setItem("session_timeout", configValue)

            }
        } catch (e) {
            console.log(e);
        }
    };

    async function getSchoolTypeId(token) {
        try {
            const res = await fetch(baseUrl + "/api/get_school_type_by_businesss", {
                method: 'GET',
                headers: {
                    "X-Authorization": token,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            const response = await res.json()
            if (response?.status === 201) {
                const schoolDetails = {
                    schoolTypeName: response?.data?.schoolType,
                    schoolTypeId: response?.data?._id
                }
                dispatch(handleSchoolTypeName(schoolDetails))
            } else {
                setErrorShow({ state: true, message: response?.data?.message })
            }
        } catch (error) {
            console.error(error.message);
        }
    }

    const VerifyOtp = async () => {
        setIsLoading(true)
        try {
            const response = await fetch(baseUrl + `/api/v1/user/verify/otp`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": loginToken,
                },
                body: JSON.stringify({
                    otp: pin
                })
            })
            const data = await response.json()
            if (data?.status === "success") {
                console.log('entering success block')

                try {
                    console.log('entering success block bool')

                    let { branchId } = jwt(loginResultData.token);
                    let foundBranchId = loginResultData?.branchId ?  loginResultData?.branchId:branchId
                    const resultaftertokne = await getnewTokenAfterLogin(foundBranchId, loginResultData?.token)
                    if (!resultaftertokne?.data?.token) {
                        throw new Error("Failed to get new token");
                    }
                    try {
                        await fetchConfig(resultaftertokne?.data?.token)
                        await getSchoolTypeId(resultaftertokne?.data?.token)
                        const { branchId } = jwt(resultaftertokne?.data?.token);
                        dispatch(logInUser({ isLoggedIn: true, ...loginResultData, branchId: branchId, token:resultaftertokne?.data?.token }));
                        dispatch(handleBranchDetails({ _id: branchId }));
                        switchBranch(branchId, resultaftertokne?.data?.token, loginResultData.defaultBusinessId);
                        let newGroup = loginResultData?.group
                        if (newGroup === "payItUser") {
                            newGroup = "Cashier"
                        }
                        localStorage.setItem("X-Authorization", resultaftertokne?.data?.token);
                        localStorage.setItem("group", newGroup);
                        localStorage.setItem("zedAccountingId", loginResultData.zedAccountingId);
                        localStorage.setItem("email", loginResultData.email);
                        localStorage.setItem("state", loginResultData.state);
                        localStorage.setItem("businessID", loginResultData.defaultBusinessId);
                        localStorage.setItem("username", loginResultData.username);
                        localStorage.setItem("businessName", loginResultData.businessName);
                        localStorage.setItem("businessId", loginResultData.businessNumber);
                        localStorage.setItem("userId", loginResultData.userId);
                        localStorage.setItem("BranchID", branchId);
                        localStorage.setItem("customerId", data.customerId);
                        localStorage.setItem("phone", loginResultData.phone)
                        localStorage.setItem("businessCategory", loginResultData.businessCategory);
                        localStorage.setItem('localCurrency', loginResultData?.localCurrency)
                        localStorage.setItem('clientId', resultaftertokne.data.xClientId)
                        localStorage.setItem('clientSecret', resultaftertokne.data.xClientSecret)
                        localStorage.setItem('webHookKey', resultaftertokne.data.xClientWebHookKey)
                        localStorage.setItem('customerClassification', loginResultData?.customerClassification)
                        localStorage.setItem('clientSecretQuick', resultaftertokne.data.qbSecret)
                        localStorage.setItem('clientIdQuick', resultaftertokne.data.qbClientId)
                        localStorage.setItem('webHookKeyQuick', resultaftertokne.data.qbWebhookKey)
                        localStorage.setItem("affiliatedPartner", loginResultData.partnerName)
                        localStorage.setItem('zhClientId', resultaftertokne.data.zhClientId)
                        localStorage.setItem('zhSecret', resultaftertokne.data.zhSecret)
                        localStorage.setItem('zhWebhookKey', resultaftertokne.data.zhWebhookKey)
                        localStorage.setItem("partnerbusinessType", loginResultData?.partnerBusinessType)
                        dispatch(switchWarehouseState(loginResultData?.warehouseOn))
                        await stockfunctions.getWarehouseStatus().then((data) => {
                            if (data?.status === 201) {
                                dispatch(switchWarehouseState(data?.response?.data?.warehouseOn))
                            }
                        })
                        await fetchBarManConfigs();
                        await fetchBusinessCategory();

                        switch (loginResultData.group) {
                            case loginResultData.group === "Cashier":
                            case loginResultData.group === "Supervisor":
                                navigate("/orders/startorder");
                                break;
                            case loginResultData.group === "User":
                                navigate("/userDashboard");
                                break;

                            case loginResultData.group && loginResultData.group.toLowerCase().includes("storemanager"):
                                navigate("/stock");
                                break;
                            case loginResultData.group === 'Admin' || loginResultData.group === 'Partner':
                                navigate('/adminDashboard');
                                break;

                            case (loginResultData.group === 'admin' && loginResultData.businessCategory === 'Transport') || loginResultData.group === 'TransportPartner':
                                navigate('/partnerstransport');
                                break;

                            case loginResultData.group === 'ZPM School Admin':
                                navigate('/school/payitshooladmin');
                                break;
                            default:
                                navigate('/dashboard')
                                break;
                        }
                        setIsLoading(false)

                    } catch (error) {
                        console.log(error)
                    }
                } catch (error) {
                    console.log(error)
                }
            }else{
                console.log(data , 'hereee')
                setErrorShow({state:true , message:data?.message})
            }
        } catch (error) {
            console.log(error , 'err')
            setErrorShow({state:true , message:error?.message})
        } finally {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        if (pin.length === 4) {
            VerifyOtp();
        }
    }, [pin]);
    return (
        <Grid container width={'100%'} minHeight={'90vh'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid container direction={"row"} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
                {/*Logo image*/}
                <Grid item>
                    <img src={loginLogo} alt="logo" style={{ marginTop: "10%" }} />
                </Grid>
            </Grid>
            <Grid container justifyContent={"center"} alignContent={"center"} width={'100%'}>
                {/*login image*/}
                <Grid item lg={6} md={6} sm={12} xs={12} xl={6} justifyContent={"center"} alignContent={"center"}>
                    <img src={loginImage} className={classes.imgRes} alt={"login"} style={{ width: '80%', height: '100%', objectFit: 'contain' }} />
                </Grid>
                <Grid item style={{ marginTop: "5%" }} className={classes.gridLayout} lg={6} md={6} sm={12} xs={12} xl={6}>
                    <Grid container display={'flex'} direction={'column'}>
                        <Grid item display={'flex'} direction={'column'} justifyContent={'center'} textAlign={'center'}>
                            <Typography style={{ fontSize: '25px', fontWeight: 400, color: '#032541' }}>{tokenMessage}</Typography>
                        </Grid>
                        <Grid item mt={1} display={'flex'} justifyContent={'center'}>
                            <PinInput
                                length={4}
                                initialValue=""
                                secret={true}
                                secretDelay={100}
                                onChange={(pin) => setPin(pin)}
                                type={showPin ? 'text' : 'password'}
                                inputMode="numeric"
                                style={{ padding: '10px' }}
                                inputStyle={{ borderColor: '#bec5d1', width: "92px", height: "108px", fontSize: "50px" }}
                                inputFocusStyle={{ borderColor: 'blue' }}
                                autoSelect={true}
                                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                            />
                        </Grid>
                        <Grid item mt={2} display={'flex'} direction={'column'} justifyContent={'center'} textAlign={'center'}>
                            <span style={{ fontSize: '16px', cursor: 'pointer', fontWeight: 600, color: '#9AA9B2' }}>Didn't get the code ?  <span style={{ cursor: "pointer", color: "#032541" }} onClick={() => ResendOtp()} >Resend</span> </span>
                        </Grid>
                        <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'} marginY={3}>
                            <Button style={pin.length < 4 ? { textTransform: 'inherit', background: '#03254166', color: "#fff", width: "170px", height: "45px" } : { textTransform: 'inherit', width: "170px", height: "45px", background: "#032541", color: '#fff' }} disabled={pin.length < 4} onClick={VerifyOtp}> {isLoading ? (
                                <Box component={'div'} sx={{ display: 'flex', alignItems: 'center' }} gap={4}>
                                    Verifying OTP <CircularProgress size={24} sx={{ color: '#fff' }} />
                                </Box>
                            ) : "Verify"}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default OtpLogin