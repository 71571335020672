import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess } from "@mui/icons-material";
import { ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import List from "@mui/material/List";
import { Link } from "react-router-dom";
import React, { useEffect, useState, useSyncExternalStore } from "react";
import { makeStyles } from "@mui/styles";
import InventoryIcon from '@mui/icons-material/Inventory';
import HttpComponent from "../../components/School/MakeRequest";

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));
const baseUrl = process.env.REACT_APP_BASE_URL;


export default function Inventory(props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [open2, setOpen2] = React.useState(false);
	const [status, setStatus] = useState()


	const handleClick = () => { setOpen(!open); };
	const handleClick2 = () => { setOpen2(!open2); };
	const [quickBookStatus, setQuickBookStatus] = useState({})
	const { quickbooksEnabled } = quickBookStatus

	const [stockConfigMap, setStockConfigMap] = useState([]);
	const token = localStorage.getItem("X-Authorization")
	
	const fetchStockConfigs = async () => {
		try {
			const response = await fetch(baseUrl + "/api/get_branch_stock_options_list", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),
				},
			});

			const data = await response.json();
			if (data.Status === "SUCCESS") {

				const stockMap = data.data.map((config) => ({
					id: config.id,
					configName: config.name,
					isSelected: config.isSelected,
				}));
				setStockConfigMap(stockMap);
			} else {
				console.log(data.message);
			}
		} catch (e) {
			console.log(e.message);
		}
	};

	useEffect(() => {
		fetchStockConfigs()
		const handleTokenChange = () => {
			fetchStockConfigs();
		};
		window.addEventListener("tokenChange", handleTokenChange);
		return () => {
			window.removeEventListener("tokenChange", handleTokenChange);
		};
	}, [token]);

	const isStockInZedEnabled = stockConfigMap.some(config => config.id === "Stock In Zed" && config.isSelected);

	const userGroup = localStorage.getItem('group')
	const businessCategory = localStorage.getItem("businessCategory");

	const isStoreManager = userGroup && userGroup?.toLocaleLowerCase()?.includes("storemanager")

	const getWareHouseStatus = async () => {
		try {
			const response = await fetch(`${baseUrl}/api/v1/stores/get_inventory_status `, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),

				},
			})
			const data = response.json()
				.then((response) => {
					//console.log('status getWareHouseStatus ', response.data.enabled);
					setStatus(response?.data?.enabled)
				})

		} catch (error) {

		}

	}

	useEffect(() => {
		getWareHouseStatus()

	}, [])
	const handleCheckWhereAre = () => {
		// save product and service and business Category school
		localStorage.setItem('product and service', true);
		localStorage.setItem('invetoryON', true)

	}
	function getAccountingStatus() {
		try {
			HttpComponent({
				method: "POST",
				url: '/api/v1/check_accounting_status',
				token: localStorage.getItem('X-Authorization'),
			}).then((data) => {
				if (data?.status === 200) {
					setQuickBookStatus(data?.response?.data)
				}
			})
		} catch (error) {
		}
	}
	// useEffect(()=>{
	// 	console.log("category in inventory", props.businessCat)
	// },[props.businessCat])

	// storeManager and StoreOperators!
	const [storeUsers, setStoreUsers] = useState(false);
	console.log(props.payablesStatus , status , 'props.payablesStatus === true')
	useEffect(() => {
		getAccountingStatus()
		if (userGroup === "StoreOperator") {
			setStoreUsers(true)
		}
	}, [businessCategory, userGroup])
	return (
		<div>

			{/*Users*/}


			{(props.businessCat && props.businessCat === "School" && status === false) || (props.businessCat === "Rental") || (props.businessCat !== "School" && status && props.payablesStatus === false ) || (props.businessCat !== "School" && !status && props.payablesStatus === false)   ? (
				<ListItem button onClick={handleClick}>
					<div style={{ marginRight: '5%' }}>
						<InventoryIcon />
					</div>
					{(props.businessCat === "School" || props.businessCat === "Rental") ? (<>
						{console.log('hereeee')}
						<ListItemText primary="Services  " style={{ fontFamily: 'Poppins' }} onClick={() => {
							localStorage.setItem('product and service', false)
						}} />
						</>
					) :
						(
							<ListItemText primary='Inventory' style={{ fontFamily: 'Poppins' }} />
						)
					}


					{open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
			) : (
				<>
				{console.log('ski')}
					{
						(userGroup !== "ZPM School Admin" || props.payablesStatus === true)    && <ListItem button onClick={handleClick2}>
							<div style={{ marginRight: '5%' }}>
								<InventoryIcon />
							</div>

							<ListItemText primary="Services" style={{ fontFamily: 'Poppins' }} onClick={() => {
								localStorage.setItem('product and service', false)
							}} />


							{open2 ? <ExpandLess /> : <ExpandMore />}
						</ListItem>
					}
					<>
						<Collapse in={open2} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								<div style={{ marginLeft: '8%' }}>
									{userGroup !== 'Admin' && userGroup === 'Merchant' || userGroup === "Owner" || userGroup === "Accountant" || userGroup === "Director" || userGroup === "ZPM School Admin" ?
										<div>
											<Link style={{ textDecoration: 'none', color: '#fff' }} to={props.businessCat && props.businessCat == "School" || props.businessCat == "Rental" || (props?.businessCat !== 'School' && props.payablesStatus === true) ? `/categories?Service` : `/categories?Product`}>
												<ListItem button className={classes.nested}>
													{props.businessCat && props.businessCat == "School" || props.businessCat == "Rental" ? (
														<ListItemText primary="Service Categories" />)
														: (
															<ListItemText primary="Product Categories" />
														)}
												</ListItem>
											</Link>

											<ListItem button className={classes.nested}>
												<Link style={{ color: '#fff', textDecoration: 'none' }} to={props.businessCat && props.businessCat == "School" || props.businessCat == "Rental" || (props?.businessCat !== 'School' && props.payablesStatus === true) ? `/products?Service` : `/products?Product`}>
													{props.businessCat && props.businessCat == "School" || props.businessCat == "Rental" ? (
														<ListItemText primary="Services " onClick={() => {
															localStorage.setItem('product and service', false)
														}} />
													) : (
														<ListItemText primary="Product /Services" />
													)}
												</Link>
											</ListItem>

											{props.businessCat && props.businessCat !== "School" && !status && props.businessCat !== "Rental" ? (
												<Link style={{ color: '#fff', textDecoration: 'none' }} to="/stock">
													<ListItem button className={classes.nested}>
														<ListItemText primary="Stock" />
													</ListItem>
												</Link>
											) : (<></>)}
										</div> : null}
								</div>

							</List>

						</Collapse>
					</>
					<ListItem button onClick={handleClick}>
						<div style={{ marginRight: '5%' }}>
							<InventoryIcon />
						</div>

						<ListItemText primary='Inventory' style={{ fontFamily: 'Poppins' }} />
						{open ? <ExpandLess /> : <ExpandMore />}
					</ListItem>
				</>
			)




			}


			{/*Collapsed Users*/}
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					<div style={{ marginLeft: '8%' }}>
						{userGroup !== 'Admin' && userGroup === 'Merchant' || userGroup === "Owner" || userGroup === "Accountant" || userGroup === "Director" || isStoreManager || userGroup === "Supervisor" || userGroup === "ZPM School Admin" ?
							<div>
								<Link style={{ textDecoration: 'none', color: '#fff' }} to={props.businessCat && props.businessCat == "School" && !status || props.businessCat == "Rental"  ? `/categories?Service` : `/categories?Product`} >
									<ListItem button className={classes.nested}>
										{props.businessCat && props.businessCat == "School" && !status || props.businessCat == "Rental" ? (
											<ListItemText primary="Service Categories" />)
											: (
												<ListItemText primary="Product Categories" />
											)}
									</ListItem>
								</Link>
								{quickbooksEnabled === true && (
									<Link
										style={{ textDecoration: 'none', color: '#fff' }}
										to={
											props.businessCat && (props.businessCat === "School" && !status || props.businessCat === "Rental")
												? `/qbcategories?Service`
												: `/qbcategories?Product`
										}
									>
										<ListItem button className={classes.nested}>
											{props.businessCat && (props.businessCat === "School" && !status || props.businessCat === "Rental")
												? (
													<ListItemText primary="Qb Service Categories" />
												) : (
													<ListItemText primary="Qb Product Categories" />
												)
											}
										</ListItem>
									</Link>
								)}

								<Link style={{ color: '#fff', textDecoration: 'none' }} to={props.businessCat && props.businessCat == "School" && !status || props.businessCat == "Rental" ? `/products?Service` : `/products?Product`}>
									<ListItem button className={classes.nested}>

										{props.businessCat && props.businessCat === "School" && !status || props.businessCat == "Rental" ? (
											<ListItemText primary="Services" onClick={() => {
												localStorage.setItem('product and service', false)
											}} />
										) : (
											<ListItemText primary="Products" onClick={handleCheckWhereAre} />
										)}


									</ListItem>
								</Link>

								{businessCategory === "School" && status === false || businessCategory === "Rental" ? (
									<></>
								) : (
									isStockInZedEnabled && (
										<Link style={{ color: '#fff', textDecoration: 'none' }} to="/stock">
											<ListItem button className={classes.nested}>
												<ListItemText primary="Stock" />
											</ListItem>
										</Link>
									)
								)}
							</div> : null}
					</div>
				</List>
			</Collapse>

		</div>
	)
}