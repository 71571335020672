import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import WarningImg from '../../../images/warning-icn.svg'
import { Box, Button, ButtonBase, Checkbox, IconButton, Table, TableCell, TableHead, TableRow, Modal, Typography, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PrintIcon from '@mui/icons-material/Print';
import mpesaIcon from "../../../common/images/mpesa-icn.svg";
import cardIcon from "../../../common/images/card-icn.svg";
import creditIcon from "../../../common/images/creditbal-icn.svg";
import { ReceivedPayment } from "../../Modals/Invoice/receivedPayment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PaymentMethods } from "../../Modals/Invoice/paymentMethods";
import SyncIcon from "@mui/icons-material/Sync";
import "./invoice.css";
import HttpComponent from "../../School/MakeRequest";
import { useDispatch, useSelector } from "react-redux";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { DeleteOutline } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { setCurrentServices } from "../../../features/servicesSlice";
import warningImage from "../../School/Images/warning-remove-icn.svg";
import ReusableModal from "../../School/ReusableModal";
import CustomeronAccountFunctions from "./customerOnAccountFunctions";
import { DeleteDiscountModal } from "../../bills/startorderModals/modals";
import ConvenienceFeePay from "./payConvenienceFee";
import jsondecode from "jwt-decode";
import html2pdf from 'html2pdf.js';
import { currencyconverter } from "../../../common/currencyconverter";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const moment = require("moment");

const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
  address_text_common: {
    fontSize: "13px",
    fontWeight: "300",
  },
  table_titles: {
    fontSize: "14px",
    fontWeight: "700",
  },
  table_body: {
    fontSize: "13px",
    fontWeight: "300",
    padding: theme.spacing(0.25),
  },
  footer_text: {
    fontSize: "10px",
    fontWeight: "300",
    padding: theme.spacing(0.25),
  },
  totals: {
    fontSize: "14px",
    padding: theme.spacing(0.25),
  },
  p1: {
    padding: theme.spacing(0.25),
  },
  p2: {
    padding: theme.spacing(0.5),
  },
  p3: {
    padding: theme.spacing(0.5),
  },
  mt4: {
    marginTop: theme.spacing(0.5),
  },
  m1: {
    margin: theme.spacing(0.5),
  },
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
}));

// modal styles

const style = { borderRadius: "10px", bgcolor: 'background.paper', display: "flex", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "630px", height: "298px", p: 1, };


const Invoice = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileUI = useMediaQuery(theme.breakpoints.down("sm"));

  const customerOnAccountsMethods = new CustomeronAccountFunctions();
  const { invoiceNumber } = useParams();
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [invoiceDiscountAmount, setInvoiceDiscountAmount] = useState(0);
  const [invoiceStatus, setInvoiceStatus] = useState('')
  const [businessInfo, setBusinessInfo] = useState();
  const [invoiceData, setInvoiceData] = useState();
  const [receivePaymentOpen, setReceivePaymentOpen] = useState(false);
  const [paymentMethodsOpen, setPaymentMethodsOpen] = useState(false);
  const [currentEmail, setCurrentEmail] = useState();
  const [errorFetching, setErrorFetching] = useState(false);
  const [invoicePayments, setInvoicePayments] = useState();
  const [invoiceBalance, setInvoiceBalance] = useState();
  const [discountNamee, setDiscountNamee] = useState("")
  const [salesOrPurchaseInvoice, setSalesOrPurchaseInvoice] = useState("");
  const [openConvenienceFeeModal, setOpenConvenienceFeeModal] = useState(false)
  const [convenienceFeeAmount, setConvenienceFeeAmount] = useState(0)
  const [isStudentPaidConvenienceFee, setIsStudentPaidConvenienceFee] = useState(false)
  const [convenienceFeeInvoiceNumber, setconvenienceFeeInvoiceNumber] = useState('')
  const [foundbid, setfoundbid] = useState('')
  const queryParams = decodeURIComponent(window.location.search)
  const state = queryParams.slice(1)
  useEffect(()=>{
   if(state.startsWith('businessNumber')) {
    const businessNumber = state.split('=')[1];
    setfoundbid(businessNumber)
   }
  },[state])

  const handleOpenConvenienceFeeModal = () => {
    setOpenConvenienceFeeModal(true)
  }

  const handleCloseConvenienceFeeModal = () => {
    setOpenConvenienceFeeModal(false)
  }

  // check if convenience fee has been paid

  const [notStudent, setNotStudent] = useState(false)


  function checkIfConvenienceFeeHasBeenPaid() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/paidConvenience`,
        body: { invoiceNumber: invoiceNumber },
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        console.log(data, 'dattyttt')
        if (data?.status === 200) {
          setIsStudentPaidConvenienceFee(data?.response?.convenienceStatus)
          setConvenienceFeeAmount(data?.response?.data?.invoiceBalance)
          setconvenienceFeeInvoiceNumber(data?.response?.data?.invoiceNumber)
          setNotStudent(data?.response?.data)
        }

      })
    } catch (error) {

    }
  }

  useEffect(() => {
    checkIfConvenienceFeeHasBeenPaid()
  }, [state])

  // generate convenience fee invoice


  // const downloadPDF = () => {
  //   const input = document.getElementById("downloadable");
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png", 2.0);
  //     const inputWidthPx = input.clientWidth;
  //     const inputHeightPx = input.clientHeight;
  //     const dpi = window.devicePixelRatio * 96;
  //     let scaleFactor = 2;
  //     if (window.innerWidth > 1512) {
  //       scaleFactor = 2;
  //     }
  //     const inputWidthMm = ((inputWidthPx / dpi) * 25.4) / scaleFactor;
  //     const inputHeightMm = (inputHeightPx / dpi) * 25.4;
  //     const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
  //     pdf.addImage(imgData, "PNG", 5, 5, inputWidthMm - 10, inputHeightMm - 10);
  //     pdf.save(`invoice.pdf`);
  //   });
  // };
  const downloadPDF = () => {
    const input = document.getElementById("downloadable");
    const options = {
      margin: 10,
      filename: 'invoice.pdf',
      image: { type: 'png', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    html2pdf()
      .from(input)
      .set(options)
      .save();
  };

  // const viewPDF = () => {
  //   const input = document.getElementById("downloadable");
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png", 1.0);
  //     const inputWidthPx = input.clientWidth;
  //     const inputHeightPx = input.clientHeight;
  //     const dpi = window.devicePixelRatio * 96;
  //     let scaleFactor = 1;
  //     if (window.innerWidth > 1512) {
  //       scaleFactor = 1.4;
  //     }
  //     const inputWidthMm = ((inputWidthPx / dpi) * 25.4) / scaleFactor;
  //     const inputHeightMm = (inputHeightPx / dpi) * 25.4;
  //     const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
  //     pdf.addImage(imgData, "JPEG", 5, 5, inputWidthMm - 10, inputHeightMm - 10);

  //     // Open the PDF in a new tab or a modal window
  //     const pdfDataUri = pdf.output("datauristring");
  //     const pdfWindow = window.open(pdfDataUri, "_blank");
  //     if (!pdfWindow) {
  //       alert("Please allow pop-ups for this site to view the PDF.");
  //     }
  //   });
  // };
  const viewPDF = () => {
    const input = document.getElementById("downloadable");
    html2pdf(input, {
      margin: 10,
      filename: `invoice.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }).from(input).save();

  };
  const printPDF = () => {
    const input = document.getElementById("downloadable");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const inputWidthPx = input.clientWidth;
      const inputHeightPx = input.clientHeight;
      const dpi = window.devicePixelRatio * 96;
      let scaleFactor = 1;
      if (window.innerWidth > 1512) {
        scaleFactor = 1.4;
      }
      const inputWidthMm = ((inputWidthPx / dpi) * 25.4) / scaleFactor;
      const inputHeightMm = (inputHeightPx / dpi) * 25.4;
      const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
      pdf.addImage(imgData, "JPEG", 5, 5, inputWidthMm - 10, inputHeightMm - 10);

      // Print the PDF
      pdf.autoPrint();
      window.open(pdf.output('bloburl'), '_blank');
    });
  };


  const getInvoiceDetails = async () => {
    try {
      const invoiceDetailsResponse = await fetch(baseUrl + `/api/get_invoice_by_invoice_number?invoiceNumber=${invoiceNumber}&businessNumber=${foundbid ?? foundbid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (invoiceDetailsResponse.status === 200) {
        const invoiceDetails = await invoiceDetailsResponse.json();
        console.log('invoiceDetails <><>', invoiceDetails?.data);
        setInvoiceData(invoiceDetails.data);
        setSalesOrPurchaseInvoice(invoiceDetails.data.salesOrPurchaseInvoice);
        setInvoiceStatus(invoiceDetails?.data?.invoiceStatus)
        setDiscountNamee(invoiceDetails.data.discountName)
      } else {
        setErrorFetching(true);
      }
    } catch (error) {
      console.error("error fetching invoice details", error.message);
    }
  };

  let localCurrency = localStorage.getItem('localCurrency')
  const localToken = localStorage.getItem("X-Authorization");
  const decoded = jsondecode(localToken)
  if (!localCurrency) {
    localCurrency = decoded?.localCurrency
  }
  if (!localCurrency || localCurrency === 'undefined' || localCurrency === undefined) {
    localCurrency = 'KES'
  }

  // Number Format for Currency
  const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency }).format(value);



  const formatDate = (date) => {
    return moment(date).format("dddd, MMMM Do, YYYY");
  };

  const formatPaymentDate = (unFormatted) => {
    const date = new Date(unFormatted);
    const options = { day: "numeric", month: "short", year: "numeric", hour: "numeric", minute: "numeric", hour12: true };
    const formattedDate = date.toLocaleString("en-US", options);
    const dateSuffix = function () {
      const date = new Date();
      const dateNumber = date.getDate();
      switch (dateNumber) {
        case 1:
        case 21:
        case 31:
          return "st";
        case 2:
        case 22:
          return "nd";
        case 3:
        case 23:
          return "rd";
        default:
          return "th";
      }
    };
    return `${date.getDate()}${dateSuffix()} ${formattedDate.slice(0, 3)} ${formattedDate.slice(7)}`;
  };

  const getBusinessDetails = async () => {
    try {
      const businessDetailsResponse = await fetch(baseUrl + "/api/get_business_info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (businessDetailsResponse.status === 200) {
        const businessDetailsData = await businessDetailsResponse.json();
        // console.log("business data ", businessDetailsData.data);
        setBusinessInfo(businessDetailsData.data);
      } else {
        setErrorFetching(true);
      }
    } catch (error) {
      console.error("error fetching customer details", error.message);
    }
  };

  const calculateTotal = () => {
    if (invoiceData) {
      // console.log('invoiceData =====<><>', invoiceData);
      setTotal(invoiceData?.total);
      setInvoiceDiscountAmount(invoiceData?.invoiceDiscountAmount || invoiceData?.discountAmount);
      //   let calculatedTotal = 0;
      //   invoiceData?.items?.forEach((product) => {
      //     calculatedTotal += product.productPrice * product.quantity;
      //   });
      //   setTotal(calculatedTotal);
    } else {
      setTotal(0);
      setInvoiceDiscountAmount(0);
    }
  };

  const back = () => {
    navigate(-1);
  };

  const [isSpinning, setIsSpinning] = useState(false);

  const refresh = () => {
    setIsSpinning(true);
    getInvoiceDetails().then(() => {
      console.log("done fetching invoice data");
      setIsSpinning(false);
    });
  };

  const getCurrentEmail = () => {
    setCurrentEmail(localStorage.getItem("email"));
  };

  const fetchBusiInvoiceData = async () => {
    const businessInvoiceDetailsResponse = await fetch(baseUrl + `/api/get_invoice_and_business_data/${invoiceNumber}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (businessInvoiceDetailsResponse.status === 200) {
      const businessDetailsData = await businessInvoiceDetailsResponse.json();
      // console.log("business Invoice data ", businessDetailsData.data)
      setBusinessInfo(businessDetailsData.data);
      // setInvoiceData(businessDetailsData.data)
    } else {
      setErrorFetching(true);
    }
  };

  const getInvoicePayments = async () => {
    const invoicePaymentDetailsResponse = await fetch(baseUrl + `/api/get_invoice_payments/${invoiceNumber}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    if (invoicePaymentDetailsResponse.status === 200) {
      const paymentDetailsData = await invoicePaymentDetailsResponse.json();
      //console.log("Invoice payment data ", paymentDetailsData.data)
      let { invoicePayments, invoiceBalance } = paymentDetailsData.data;
      //console.log("ip",invoicePayments)
      setInvoicePayments(invoicePayments);
      setInvoiceBalance(invoiceBalance);
    }
  };

  const { userId, X_Authorization } = useSelector((store) => store.user);
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const resendEmail = () => {
    HttpComponent({
      method: "GET",
      url: `/api/resendInvoice?invoiceNumber=${invoiceNumber}`,
      body: null,
      token: X_Authorization,
    })
      .then((data) => {
        //console.log("here store is data",data);
        if (data.status === 200) {
          setSuccessShow({ state: true, message: `${invoiceNumber} successfully resent to ${data.response.data.sentTo}` });
        } else {
          setErrorShow({ state: true, message: data.response.message });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
  const [warningOpen, setWarningOpen] = useState(false);
  const [indexToBeDeleted, setIndexToBeDeleted] = useState();
  const [idToBeDeleted, setIdToBeDeleted] = useState();

  const handleWarningClose = () => {
    setWarningOpen(false);
  };

  useEffect(() => {
    getBusinessDetails().then(() => console.log("done getting business info"));
    getInvoiceDetails().then(() => console.log("done fetching invoice data"));
    getCurrentEmail();
    getInvoicePayments().then(() => console.log("payments fetched"));
  }, [foundbid]);

  useEffect(() => {
    if (errorFetching) {
      //console.log("we had an error fetching")
      fetchBusiInvoiceData().then(() => console.log("Fallback function used"));
    }
  }, [errorFetching]);

  useEffect(() => {
    calculateTotal();
  }, [invoiceData]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentServices(invoiceData?.items));
  }, [invoiceData]);

  const businessCategory = localStorage.getItem("businessCategory");
  const service = useSelector((store) => store.services.currentServices);

  const deleteFromInvoice = () => {
    let data = {
      invoiceNumber,
      productId: idToBeDeleted,
    };
    HttpComponent({
      method: "POST",
      url: `/api/delete_product_from_invoice`,
      body: data,
      token: X_Authorization,
    })
      .then((data) => {
        // console.log("here store is data",data);
        if (data.status === 200) {
          handleWarningClose();
          getInvoiceDetails();
          setSuccessShow({
            state: true,
            message: "Service was removed successfully",
          });
        } else {
          console.error("Error setting info");
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  //   handle discount on Invoice;
  const [showDiscount, doShowDiscount] = useState(false);
  const [discountNames, setDiscountNames] = useState([]);
  const [discountTypes, setDiscountTypes] = useState([]);
  const getDiscountName = () => {
    customerOnAccountsMethods.getDiscountName().then((data) => {
      if (data?.status === 200) {
        setDiscountNames(data?.response?.data);
      }
    });
  };

  useEffect(() => {
    getDiscountName();
  }, []);

  // get discount type;

  const getDiscountType = () => {
    customerOnAccountsMethods.getDiscountType().then((data) => {
      if (data?.status === 200) {
        setDiscountTypes(data?.response?.data);
      }
    });
  };

  useEffect(() => {
    getDiscountType();
  }, []);

  // discount info;
  const [discountDetails, setDiscountDetails] = useState({ invoiceNumber: "", discountName: "", discountType: "", discountAmount: 0, discountPercent: 0 });
  const [discount, setDiscount] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [discountType, setDiscountType] = useState("");
  const [showDiscountButton, setShowDiscountButton] = useState(false);
  const [discountName, setDiscountName] = useState("");
  const [otherDiscount, setOtherDiscount] = useState("")


  useEffect(() => {
    if (discountName === "Other") {
      setDiscountDetails({ ...discountDetails, discountName: otherDiscount })
    }
  }, [otherDiscount])


  const getDiscount = (discValue) => {
    setDiscountType(discValue);
  };

  useEffect(() => {
    setDiscountDetails({ ...discountDetails, discountName: discountName, discountType: discountType, discountAmount: 0, discountPercent: 0 });

    if (discountType === "Percentage") {
      setDiscountDetails({ ...discountDetails, discountName: discountName, discountAmount: 0, discountPercent: discountValue, discountType: discountType });
      const discAmount = Number(total * (discountValue / 100));
      if (Number(discountValue) >= 100) {
        setDiscount(0);
      }
      setDiscount(discAmount);
    } else if (discountType === "Amount") {
      setDiscountDetails({ ...discountDetails, discountName: discountName, discountAmount: discountValue, discountPercent: 0, discountType: discountType });
      if (Number(discountValue) >= Number(total)) {
        setDiscount(0);
      } else {
        setDiscount(Number(discountValue));
      }
    }
  }, [discountType, discountValue]);

  useEffect(() => {
    if (discountDetails.discountName && discountDetails.discountType && discountValue && discountType) {
      setShowDiscountButton(true);
    } else if (discountDetails.discountName == "Discount Name" || discountDetails.discountType === "" || discountValue === 0 || discountType === "") {
      setShowDiscountButton(false);
    }
  }, [discountDetails.discountName, discountDetails.discountType, discountValue, discountType]);

  useEffect(() => {
    if (showDiscount === false) {
      setDiscountDetails({ ...discountDetails, discountName: "", discountAmount: "", discountPercent: 0, discountType: "" });
    }
  }, [showDiscount])

  console.log('discount details ======<><><>', discountDetails);

  const doApplyDiscount = () => {
    if (!invoiceNumber) {
      setErrorShow({ state: true, message: "Invoice Number is required!" });
    } else if (discountDetails?.discountName === "") {
      setErrorShow({ state: true, message: "Discount Name is required!" });
    } else if (discountDetails?.discountType === "") {
      setErrorShow({ state: true, message: "Discount Type is required!" });
    } else if (discountValue === 0) {
      setErrorShow({ state: true, message: "Discount Amount is required!" });
    } else if (discountName === "Other" && otherDiscount === "") {
      setErrorShow({ state: true, message: "Discount Name is required!" });
    } else {
      customerOnAccountsMethods.postDiscount(invoiceNumber, discountDetails.discountName, discountDetails.discountType, discountDetails.discountAmount, discountDetails.discountPercent).then((data) => {
        if (data?.status === 200 || data?.status === 201) {
          setSuccessShow({ state: true, message: "Discount applied successfully!" });

          setTimeout(() => {
            setSuccessShow({ state: false, message: "" });
          }, 3000);

          doShowDiscount(false);
          refresh();
        } else {
          setErrorShow({ state: true, message: data.response.message });
        }
      });
    }
  };

  // DELETE INVOICE FUNCTIONALITY;

  const [deleteDiscountModal, setDeleteDiscountModal] = useState(false);

  const deleteAppliedDiscount = () => {
    customerOnAccountsMethods.deleteInvoiceDiscount(invoiceNumber).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setDeleteDiscountModal(false);
        setSuccessShow({ state: true, message: "Discount deleted sucessfuly!" });
        doShowDiscount(false);
        refresh();
      } else if (data?.status === 400) {
        setErrorShow({ state: true, message: data?.response?.message });
      }
    });
  };

  const userGroup = localStorage.getItem('group');
  const canPerformDiscounting = userGroup && userGroup !== 'Customer' && invoiceStatus !== "Paid" && invoiceStatus !== 'Partially Paid';

  // console.log('canPerformDiscounting ========== <><><>', canPerformDiscounting);
  const RaisePaymentSupplier = () => {
    // /payables/suppplier/raisepayment?step=2
    localStorage.removeItem('supplierPayment')
    let data = {
      selectedInvoices: [{ invoiceAmount: invoiceData.invoiceAmount, invoiceId: invoiceData._id, invoiceNumber: invoiceData.invoiceNumber }],
      selectedSupplierId: invoiceData.supplierId,
      selectedName: invoiceData.supplierName,
      totalInvoicesAmount: invoiceData.invoiceAmount,
      bankName: invoiceData.bankName,
      bankCode: invoiceData.bankCode,
    }
    console.log(data, 'datadatadata');
    localStorage.setItem('supplierPayment', JSON.stringify(data))
    navigate('/payables/suppplier/raisepayment?step=2')

  };
  // console.log(state, 'stateee')
  const [accountStatus, setAccountStatus] = useState({})

  function getAccountingStatus() {
    try {
      HttpComponent({
        method: "POST",
        url: '/api/v1/check_accounting_status',
        body: {
          businessID: localStorage.getItem('businessId')
        },
        token: localStorage.getItem('X-Authorization'),
      }).then((data) => {
        if (data.status === 200) {
          setAccountStatus(data.response.data)
        }
      })
    } catch (error) {
    }
  }

  useEffect(() => {
    getAccountingStatus()
  }, [])

  const { accountingEnabled } = accountStatus

  const [generalLedgerOptions, setGeneralLedgerOptions] = useState([])
  const [selectedLedgerOption, setSelectedLedgerOption] = useState()
  function getGeneralLedgerCategories() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/v1/business/get_general_ledger_accounts`,
        token: localStorage.getItem('X-Authorization'),
        body: {
          businessID: localStorage.getItem('businessId')
        }
      }).then((data) => {
        if (data.status === 200) {
          setGeneralLedgerOptions(data?.response?.data)
        }
      })
    } catch (error) {

    }
  }
  useEffect(() => {
    getGeneralLedgerCategories()
  }, [])

  const handleOnChangeLedger = (e, productId) => {
    setSelectedLedgerOption(e)

    HttpComponent({
      method: "POST",
      url: '/api/v1/update_account_items',
      body: {
        invoiceNumber: invoiceNumber,
        accountId: e,
        productId: productId
      },
      token: localStorage.getItem('X-Authorization'),
    }).then((data) => {
      if (data.status === 200 || data.status === 201) {
        setSuccessShow({ state: true, message: `Successfully update the GL Account` });
      } else {
        setErrorShow({ state: true, message: data.response.message });

      }
    })
  }

  const [productDiscountAmount, setProductDiscountAmount] = useState({})
  const [calculatedDiscount, setCalculatedDiscount] = useState({})
  const [newSelectedDiscountType, setNewSelectedDiscountType] = useState({})
  const [newProductArray, setNewProductArray] = useState([])


  const handleCalculateProductDiscount = (selectedDiscountType, product, discountAmount) => {
    let initialDiscount = 0;
    let discountPercent = 0;
    if (selectedDiscountType === 'Percentage') {
      initialDiscount = (product?.productPrice * product?.quantity) * (discountAmount / 100);
      discountPercent = discountAmount;
    } else if (selectedDiscountType === 'Amount') {
      discountPercent = 0
      initialDiscount = discountAmount;
    }
    console.log('product ====== <><><>', product);
    setCalculatedDiscount(prevState => ({ ...prevState, [product.pricingId]: initialDiscount }));
    const newProductArrayWithDiscount = newProductArray.filter(item => item.productId !== product.pricingId);
    const newProduct = {
      discount: initialDiscount,
      discountPercent: discountPercent,
      discountType: selectedDiscountType,
      productId: product.pricingId,
      amount: product.productPrice,
      quantity: product.quantity,
    };
    setNewProductArray([...newProductArrayWithDiscount, newProduct]);

  };
  const handleDiscountChange = (e, product) => {
    console.log('product ====== <><><>', product, e.target.value);
    const discountAmount = Number(e.target.value)
    setProductDiscountAmount(prevState => ({ ...prevState, [product.pricingId]: discountAmount }));
    handleCalculateProductDiscount(newSelectedDiscountType[product.pricingId], product, discountAmount);
  };

  const handleDiscountTypeChange = (e, product) => {
    const selectedDiscountType = e.target.value;
    setNewSelectedDiscountType(prevState => ({ ...prevState, [product.pricingId]: selectedDiscountType }));
    handleCalculateProductDiscount(selectedDiscountType, product, productDiscountAmount[product.pricingId]);
  };


  console.log(newProductArray, 'newProductArray  🚀 🚀🚀');
  // apply discount per product

  function ApplyDiscountPerProductToInvoice() {
    try {
      HttpComponent({
        method: "POST",
        url: '/api/generateInvoice',
        body: {
          discountName: '',
          discountPercent: 0,
          discountAmount: 0,
          discountType: '',
          customerId: invoiceData?.customerId,
          type: invoiceData?.invoiceType,
          frequency: invoiceData?.invoiceFrequency,
          products: newProductArray,
        },
        token: localStorage.getItem('X-Authorization'),
      }).then((data) => {
        if (data?.status === 200 || data?.status === 201) {
          setSuccessShow({ state: true, message: data?.response?.message });
          setTimeout(() => {
            navigate(-1)
          }, 2000);
        } else {
          setErrorShow({ state: true, message: data.response.message });
        }
        getInvoiceDetails()
      }).catch((error) => {
        console.log(error);
      })
    } catch (error) {
      console.log(error);
    }

  }

  return (
    <>
      {state === 'PayConvenienceFee' ? <ConvenienceFeePay amount={convenienceFeeAmount} invoiceNumber={convenienceFeeInvoiceNumber} /> : <>
        <div style={ isMobileUI ?  {width: "100%"} : { width: "80%", marginLeft: "10%" }}>
          <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
          <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
          <div>
            <div>
              <div style={isMobileUI  ? {display :"block"} : { display: "flex", justifyContent: "space-between" }}>
                <div style={{ paddingTop: 15, display: "flex", alignItems:"center" }}>
                  <span>
                    <IconButton aria-label="back" onClick={() => back()}>
                      <KeyboardBackspaceOutlinedIcon sx={{ color: "#dc3545", fontSize: "25px" }} />
                    </IconButton>
                  </span>
                  <span style={{ fontFamily: "Poppins", fontSize: isMobileUI ? "20px" : "25px", fontWeight: "600", textAlign: "left", color: "#dc3545" }}>Preview Invoice</span>
                </div>
                <div style={{ padding: 10, display: "flex", alignItems:"center", justifyContent:"space-between" }}>
                  {/*{invoiceData?.invoiceStatus === "Unpaid" && invoiceData?.sentTo != currentEmail &&(*/}
                  {/*    <Button style={{"width":"180px","height":"45px","borderRadius":"5px","backgroundColor":"transparent", color:"#032541", border:"solid 1px #032541", marginRight:2}} onClick={()=>setReceivePaymentOpen(true)}>*/}
                  {/*        Accept Payment*/}
                  {/*    </Button>)}*/}

                  {(invoiceData?.invoiceStatus === "Unpaid" || invoiceData?.invoiceStatus === "Partially Paid") && invoiceData?.sentTo === currentEmail && (
                    <div>
                      <IconButton aria-label="refresh icon" style={{ color: "#032541", height: "45px", width: "45px" }} onClick={() => refresh()}>
                        <SyncIcon className={`${isSpinning ? "sync-icon spinning" : ""}`} />
                      </IconButton>

                      <Button
                        style={{ width:isMobileUI ? "150px" : "180px", height: isMobileUI ? "40px" : "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white" }}
                        onClick={() => ((isStudentPaidConvenienceFee === true && convenienceFeeAmount === 0) || (isStudentPaidConvenienceFee === true && notStudent === true)) ? navigate(`/parent/pay/${invoiceNumber}?businessNumber=${foundbid}`) : handleOpenConvenienceFeeModal()}
                      >
                        Pay
                      </Button>
                    </div>
                  )}

                  {invoiceData?.batchStatus !== "PENDING" && invoiceData?.batchStatus !== "DECLINED" && invoiceData?.sentTo !== currentEmail && invoiceData?.invoiceStatus !== "CANCELLED" && invoiceData?.invoiceStatus !== "DELETED" && (
                    <div>
                      {invoiceData?.invoiceStatus?.toLowerCase() === "paid" ? null : (
                        <>
                          {salesOrPurchaseInvoice === "Supplier Invoice" ? <>

                            {invoiceData?.invoiceStatus === "Unpaid" ? <>
                              <Button
                                className="mr-4"
                                style={{ width:isMobileUI ? "150px" : "180px", height: isMobileUI ? "40px" : "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white" }}
                                onClick={() => RaisePaymentSupplier()}
                              >
                                Pay Invoice
                              </Button>
                            </> : null}

                          </> : (
                            <>
                              <Button
                                style={{ width:isMobileUI ? "150px" : "180px", height: isMobileUI ? "40px" : "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white", marginRight: '10px' }}
                                onClick={() => navigate("/parent/pay/" + invoiceNumber)}>
                                Request Payment
                              </Button>

                              <Button onClick={resendEmail} style={{ width:isMobileUI ? "100px" : "180px", height: isMobileUI ? "40px" : "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white" }}>
                                Resend Invoice
                              </Button>
                            </>
                          )}
                        </>


                      )}
                    </div>
                  )}
                  {(userGroup === 'Merchant' && newProductArray.length > 0) && (invoiceData?.invoiceStatus === "Unpaid" || invoiceData?.invoiceStatus === "Partially Paid") ?
                    <Button onClick={ApplyDiscountPerProductToInvoice} style={{ width:isMobileUI ? "100px" : "180px", height: isMobileUI ? "40px" : "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white" }}>
                      Save Invoice
                    </Button> : null}

                  <span style={{ backgroundColor: "rgba(3, 37, 65, 0.05)", width: "45px", height: "45px", display: "flex", justifyContent: "center", marginLeft: 5, borderRadius: 5 }}>
                    <IconButton aria-label="download" onClick={downloadPDF}>
                      <FileDownloadOutlinedIcon sx={{ color: "#032541", fontSize: "25px" }} />
                    </IconButton>

                  </span>
                  <span style={{ backgroundColor: "rgba(3, 37, 65, 0.05)", marginLeft: "20px", width: "45px", height: "45px", display: "flex", justifyContent: "center", marginLeft: 5, borderRadius: 5 }}>
                    <IconButton aria-label="download" onClick={viewPDF}>
                      <PrintIcon sx={{ color: "#032541", fontSize: "25px" }} />
                    </IconButton>

                  </span>

                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", overflow: "visible", height: "auto" }} id="downloadable">
                <div className={classes.p3} style={{ backgroundColor: `${invoiceData?.invoiceStatus === "Unpaid" ? "rgba(220,53,69,0.07)" : invoiceData?.invoiceStatus === "Paid" ? "rgba(23,174,123, 0.07)" : "rgba(247,137,41, 0.05)"}`, height: "130px", display: "flex", justifyContent: "space-between" }}>
                  <div className={classes.p1} style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ color: "black", fontSize: "30px", fontWeight: "700" }}>INVOICE</span>
                    <span style={{ color: "black", fontSize: "14px", fontWeight: "700" }}>Date:</span>
                    <span style={{ fontSize: "14px" }}>{formatDate(invoiceData?.createdAt)}</span>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {isMobileUI ? null :  <img alt="" src={`${baseUrl}/staticimages/logos/${invoiceData?.businessLogo}`} style={{ width: "180px", height: "180px", objectFit: "contain" }} />} 
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ color: `${invoiceData?.invoiceStatus === "Paid" ? "#17ae7b" : invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#e07c28"}`, fontSize: "40px", fontWeight: "700" }}>{invoiceData?.invoiceStatus}</span>
                    <span style={{ color: "black", fontSize: "14px", fontWeight: "700" }}>Invoice No:</span>
                    <span style={{ fontSize: "14px" }}>{invoiceData?.invoiceNumber}</span>
                  </div>
                </div>
                <div className={""} style={ isMobileUI ?  {display:"block"} : { display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ fontSize: "18px" }}>From:</span>
                    <span className={classes.address_text_common} style={{ fontWeight: "700" }}>
                      {
                        invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? invoiceData?.supplierName : businessInfo?.businessName
                      }
                    </span>
                    <span className={classes.address_text_common}>
                      {/* {businessInfo?.businessOwnerAddress}, {businessInfo?.country} */}
                      {invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? invoiceData?.supplierAddress : businessInfo?.businessOwnerAddress}
                      {
                        invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? invoiceData?.supplierLocation : businessInfo?.country
                      }
                    </span>
                    <span className={classes.address_text_common}>
                      {/* {businessInfo?.businessOwnerEmail} */}
                      {invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? invoiceData?.supplierEmail : businessInfo?.businessOwnerEmail}

                    </span>
                    <span className={classes.address_text_common}>
                      {/* {businessInfo?.businessOwnerPhone} */}
                      {invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? invoiceData?.supplierPhoneNumber : businessInfo?.businessOwnerPhone}

                    </span>
                  </div>
                  <div className={""} style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                    <span className={classes.address_text_common} style={{ fontWeight: "700" }}>
                      Billed to:
                    </span>
                    <span className={classes.address_text_common}>
                      {/* {invoiceData?.customerName} */}
                      {invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? businessInfo?.businessName : invoiceData?.customerName}
                    </span>
                    <span className={classes.address_text_common}>
                      {/* {invoiceData?.sentTo} */}
                      {invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? businessInfo?.businessOwnerAddress : invoiceData?.sentTo}
                    </span>
                    <span className={classes.address_text_common}>
                      {/* {invoiceData?.customerPhoneNumber} */}
                      {invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? businessInfo?.businessOwnerPhone : invoiceData?.customerPhoneNumber}
                    </span>
                  </div>
                </div>
                <br />
                {/*<br/>*/}
                {businessCategory == "School" ? (
                  <div style={{ border: "dotted 0.5px", display: isMobileUI ? "block" : "flex", justifyContent: isMobileUI ? null : "space-between", padding: "0.625rem" }}>
                    <div>STUDENT ID:<span style={{ fontWeight: "bold", }}>{invoiceData?.itemNumber}</span></div>
                    <div>STUDENT NAME:<span style={{ fontWeight: "bold", }} > {invoiceData?.studentName} </span> </div>
                    <div>GRADE:<span style={{ fontWeight: "bold", }}>{invoiceData?.grade}</span></div>
                    <div>TERM:<span style={{ fontWeight: "bold", }}>{invoiceData?.term}</span></div>
                  </div>
                ) : (
                  <div style={{ border: "dotted 1px" }}></div>
                )}
                <br />
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.textLeft}>Description</TableCell>
                        <TableCell className={classes.textCenter}>Price ({localCurrency})</TableCell>
                        <TableCell className={classes.textCenter}>Qty</TableCell>
                        {invoiceData?.batchStatus == "PENDING" && businessCategory !== 'School' ? <TableCell className={classes.textRight}>Discount</TableCell> : null}
                        <TableCell className={classes.textRight}>Total ({localCurrency})</TableCell>
                        {invoiceData?.batchStatus == "PENDING" && accountingEnabled ? <TableCell className={classes.textRight}>GL Account</TableCell> : null}
                        {invoiceData?.batchStatus == "PENDING" ? <TableCell className={classes.textRight}>Delete</TableCell> : null}

                      </TableRow>
                    </TableHead>
                    {invoiceData?.items?.map((product, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.textLeft}>
                          <span className={classes.table_body}>{product.productName}</span>
                        </TableCell>
                        <TableCell className={classes.textCenter}>
                          <span className={classes.table_body}>{currencyconverter(product.productPrice)}</span>
                        </TableCell>
                        <TableCell className={classes.textCenter}>
                          <span className={classes.table_body}>{product.quantity}</span>
                        </TableCell>
                        {invoiceData?.batchStatus == "PENDING" && businessCategory !== 'School' ? (
                          <TableCell className={classes.textRight}>
                            <Box component={'div'} sx={{ display: 'flex', alignItems: 'center' }}>
                              <TextField id="outlined-basic" sx={{ width: 100 }}
                                value={productDiscountAmount[product.pricingId] || 0}
                                onChange={(e) => handleDiscountChange(e, product)}
                                variant="outlined" />
                              <FormControl sx={{ m: 1, minWidth: 80 }}>
                                <Select
                                  value={newSelectedDiscountType[product.pricingId] || ''}
                                  onChange={(e) => handleDiscountTypeChange(e, product)}
                                  displayEmpty
                                  inputProps={{ 'aria-label': 'Without label' }}
                                >
                                  {discountTypes.map((option) => {
                                    return (
                                      <MenuItem value={option} >{option}</MenuItem>
                                    )
                                  })}
                                </Select>
                              </FormControl>
                              <span style={{ color: '#17ae7b', fontWeight: 600 }}> {currencyconverter(calculatedDiscount[product.pricingId] || 0)}</span>
                            </Box>
                          </TableCell>
                        ) : null}
                        <TableCell className={classes.textRight}>
                          <span className={classes.table_body}>{numberFormat(product.productPrice * product.quantity)}</span>
                        </TableCell>
                        {invoiceData?.batchStatus == "PENDING" && accountingEnabled ? (


                          <TableCell className={classes.textRight}>
                            <FormControl sx={{ minWidth: 250 }}>
                              <InputLabel id="demo-simple-select-helper-label">General Ledger Account (Income)</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                defaultValue={product?.accountId}
                                value={selectedLedgerOption}
                                onChange={(e) => handleOnChangeLedger(e.target.value, product?.productId)}
                                label="General Ledger Account (Income)"
                              >
                                {generalLedgerOptions.map((option) => {
                                  return (
                                    <MenuItem value={option?.accountId} >{option.name}</MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>


                          </TableCell>
                        ) : null}
                        {invoiceData?.batchStatus == "PENDING" ? (
                          <TableCell className={classes.textRight}>
                            <ButtonBase
                              onClick={() => {
                                const index = service.findIndex((service) => service.productName === product.productName);
                                setIndexToBeDeleted(index);
                                setIdToBeDeleted(product.productId);
                                setWarningOpen(true);
                              }}
                            >
                              <DeleteOutline style={{ color: "#dc3545" }} />
                            </ButtonBase>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    ))}
                  </Table>
                </div>

                {invoiceData?.batchStatus == "PENDING" ? (
                  <Button onClick={() => navigate(`/school/invoice/edit/${invoiceNumber}`)} style={{ border: "solid 1px rgba(3, 37, 65, 0.1)", fontWeight: "bold", color: "#032541", borderRadius: "2px", alignContent: "center", background: "#fff", fontSize: "14px", marginTop: 5 }}>
                    <AddCircleOutlineIcon style={{ fontSize: "18px", marginRight: "12px" }} /> Add Services
                  </Button>
                ) : null}

                <div
                  className={classes.p3}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: '20px'
                  }}
                >

                  {
                    canPerformDiscounting && invoiceData?.salesOrPurchaseInvoice !== "Supplier Invoice" ? <div style={{ width: '70%' }}>
                      {invoiceDiscountAmount > 0 ? (
                        <div>
                          <div>
                            <p style={{ margin: '0', color: '#032541', fontWeight: 'bold', margin: '1% 0 1% 0' }}>
                              Discount Name : {discountNamee}
                            </p>

                          </div>

                          <Button onClick={() => setDeleteDiscountModal(true)} style={{ backgroundColor: "red", color: "#ffffff" }}>
                            Delete Discount
                          </Button>
                        </div>
                      ) : (
                        <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
                          <Checkbox
                            style={{ color: "#000000" }}
                            onChange={(e) => {
                              doShowDiscount(!showDiscount);
                              setDiscount(0);
                            }}
                          />
                          <span style={{ color: "#000000", fontWeight: "bold" }}>Apply Discount</span>
                        </div>
                      )}

                      {showDiscount && invoiceDiscountAmount === 0 && (
                        <>
                          <div style={{ display: "flex", alignItems: "center", gap: "5px", margin: "0px 0px 2% 0px" }}>

                            <div>
                              <select onChange={(e) => setDiscountName(e.target.value)} style={{ color: "#000000", border: "1px solid #bec5d1", outline: "none", padding: "5px", width: "100%", cursor: "pointer", borderRadius: "5px", backgroundColor: "#ffffff", fontFamily: "Poppins", fontSize: "14px" }}>
                                <option>Discount Name</option>
                                {discountNames?.map((disc) => {
                                  return <option value={disc}>{disc}</option>;
                                })}
                              </select>

                            </div>



                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                              <input placeholder="Value" onChange={(e) => setDiscountValue(e.target.value)} style={{ width: "50%", border: "1px solid #bec5d1", outline: "none", padding: "5px" }} />
                              <select onChange={(e) => getDiscount(e.target?.value)} style={{ color: "#000000", border: "1px solid #bec5d1", outline: "none", padding: "5px 5px 5px 20px", width: "100%", cursor: "pointer", borderRadius: "5px", backgroundColor: "#ffffff", fontFamily: "Poppins", fontSize: "14px" }}>
                                <option></option>
                                {discountTypes?.map((type) => {
                                  return <option value={type}>{type}</option>;
                                })}
                              </select>
                            </div>
                          </div>

                          <div style={{ margin: '0px 0px 2% 0px' }}>
                            {
                              discountName === "Other" ? <input placeholder="Description" onChange={(e) => setOtherDiscount(e.target.value)} style={{ width: "50%", border: "1px solid #bec5d1", outline: "none", padding: "5px" }} /> : null
                            }
                          </div>
                          {
                            salesOrPurchaseInvoice === "Supplier Invoice" ? null :
                              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button onClick={() => (showDiscountButton ? doApplyDiscount() : null)} style={showDiscountButton ? { backgroundColor: "#17ae7b", color: "#ffffff", opacity: 1 } : { backgroundColor: "#17ae7b", color: "#ffffff", opacity: 0.5 }}>
                                  Apply Discount
                                </Button>
                              </div>
                          }

                        </>
                      )}
                    </div> : <div style={{ width: '70%' }}></div>
                  }


                  <div className={classes.p1} style={{ backgroundColor: "#f7f7f7", width:isMobileUI ? "50%" : "30%", height: "80px", display: "flex", flexDirection: "column", justifyContent: "center", }}>
                    <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                      <span>Subtotal</span>
                      <span>{discount === 0 && invoiceDiscountAmount === 0 ? numberFormat(total - discount) : numberFormat(total + invoiceDiscountAmount)}</span>
                    </div>
                    <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                      <span>Discount</span>
                      <span> {discount === 0 && invoiceDiscountAmount === 0 ? numberFormat(discount) : numberFormat(invoiceDiscountAmount)} </span>
                    </div>
                    <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                      <span style={{ color: `${invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`, fontWeight: "bold" }}>Total</span>
                      <span
                        style={{
                          fontSize: "16px",
                          color: `${invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`,
                          fontWeight: "bold",
                        }}
                      >
                        {numberFormat(total)}
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <div style={{ border: "dotted 1px" }}></div>
                <br />

                {invoiceData?.invoiceStatus === "Partially Paid" || invoiceData?.invoiceStatus === "Paid" ? (
                  <>
                    <div>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.textLeft}>Mode of Payment</TableCell>
                            <TableCell className={classes.textCenter}>Date</TableCell>
                            <TableCell className={classes.textRight}>Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        {invoicePayments?.map((payment, index) => (
                          <TableRow key={index}>
                            <TableCell className={classes.textLeft}>
                              <span className={classes.table_body}>
                                {payment.modeOfPayment}: {payment.ReferenceNumber}
                              </span>
                            </TableCell>
                            <TableCell className={classes.textCenter}>
                              <span className={classes.table_body}>{formatPaymentDate(payment.date)}</span>
                            </TableCell>
                            <TableCell className={classes.textRight}>
                              <span className={classes.table_body}>{
                                invoiceData?.salesOrPurchaseInvoice === "Supplier Invoice" ? numberFormat(invoiceData?.invoiceAmount) : numberFormat(payment.amount)
                              }</span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </div>
                    <br />
                    <div style={{ display: "flex", justifyContent: "end" }}>
                      <div style={{ color: "#dc3545", fontWeight: "bold" }}>
                        {invoiceData?.invoiceStatus !== "Paid" ?
                          <span>Balance: {numberFormat(invoiceBalance)}</span>
                          : null}
                      </div>
                    </div>
                    <br />
                    <div style={{ border: "dotted 1px" }}></div>
                    <br />
                  </>
                ) : (
                  <></>
                )}
                {/* <div style={{ display: "flex", flexDirection: "column" }}>
                  <div >
                    <span style={{ color: "#032541", fontWeight: "700", fontSize: "16px", }}>Accepted Payment Methods</span>
                  </div>
                  <div style={{ display: "flex" }} id="payments">
                    <div style={{ display: "flex", flexDirection: "column", }} className="icon">
                      <img className={classes.p2} src={mpesaIcon} alt="M-pesa" />
                      <div style={{ display: "flex", justifyContent: "center", fontSize: "12px", }}><span>M-Pesa</span></div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", }} className="icon">
                      <img className={classes.p2} src={cardIcon} alt="Visa" />
                      <div style={{ display: "flex", justifyContent: "center", fontSize: "12px", }}><span>Card</span> </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", }} className="icon">
                      <img className={classes.p2} src={creditIcon} alt="Credit" />
                      <div style={{ display: "flex", justifyContent: "center", fontSize: "12px", }}><span>Credit Balance</span></div>
                    </div>
                  </div>
                </div> */}
                <br />
                <div style={{ border: "dotted 1px" }}></div>
                <br />
                <div className={classes.p2} style={{ display: "flex", justifyContent: "center" }} id="footer-text">
                  <div className={classes.textCenter} style={{ fontSize: "10px", fontWeight: "300" }} id="footer-text-content">
                    This is a system-generated invoice is created without anyalteration whatsoever.
                    <br />
                    Thank you for your business.
                  </div>
                </div>
                <div className={classes.p2} style={{ backgroundColor: "#f7f7f7", display: "flex", justifyContent: "center", }} id="footer-info">
                  <div id="footer-info-text">
                    <span className={classes.footer_text}>Powered by{" "}
                      <span style={{ fontWeight: "700", }}>
                        ZED Payments Limited
                      </span>
                      <span className={classes.footer_text}>.</span>
                    </span>
                  </div>
                  <div id="footer-info-email">
                    <span className={classes.footer_text}>info@zed.business</span>
                    <span className={classes.footer_text}>.</span>
                    <span className={classes.footer_text}>v1.0.2</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </>
      }
      <Modal
        open={openConvenienceFeeModal}
        onClose={handleCloseConvenienceFeeModal}
        slotProps={{ backdrop: { style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Grid container display={'flex'} spacing={4}>
              <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Grid item>
                  <img src={WarningImg} alt='Warning' />
                </Grid>
                <Grid item ml={2}>
                  <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                    Convenience Fee
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    To continue using this service , a convenience fee of  <span style={{ color: '#032541', fontWeight: 600 }}> {numberFormat(convenienceFeeAmount)}</span> is required
                  </Typography>
                  <Typography>
                    Click Activate to proceeed
                  </Typography>
                  <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Button onClick={() => { navigate(`/invoice/${invoiceNumber}?PayConvenienceFee`); handleCloseConvenienceFeeModal() }} style={{ width: "264px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#032541" }}>Pay Convenience Fee</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ReusableModal open={warningOpen} onClose={handleWarningClose} width="34.688rem">
        <Box sx={{ display: "flex", justifyContent: "center", padding: "3.125rem" }}>
          <Box sx={{ alignSelf: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Box sx={{ display: "flex" }}>
              <img style={{ paddingRight: "2.5rem" }} src={warningImage} alt="Warning" />
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography sx={{ color: "#032541", fontWeight: 600 }}>Remove Service ?</Typography>
                <Typography sx={{ color: "#323d52", fontSize: "0.875rem" }}>
                  Service will be deleted. <br /> This action cannot be undone.
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", gap: 2, marginTop: 5 }}>
              <Button
                sx={{
                  backgroundColor: "transparent",
                  border: "1px solid #002543",
                  color: "#002543",
                  width: "7.813rem",
                  height: "2.813rem",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#002543",
                  },
                }}
                onClick={handleWarningClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#dc3545",
                  color: "#ffffff",
                  width: "7.813rem",
                  height: "2.813rem",
                  "&:hover": {
                    backgroundColor: "#dc3545",
                    color: "#ffffff",
                  },
                }}
                onClick={deleteFromInvoice}
              >
                Remove
              </Button>
            </Box>
          </Box>
        </Box>
      </ReusableModal>


      {/*Legacy modals below*/}
      {/*Received Payment Modal*/}
      <ReceivedPayment paymentOpen={receivePaymentOpen} onClose={() => setReceivePaymentOpen(false)} />

      {/*    Payment Methods*/}
      <PaymentMethods paymentMethodsOpen={paymentMethodsOpen} onClose={() => setPaymentMethodsOpen(false)} invoiceBalance={invoiceData?.invoiceBalance} invoiceNumber={invoiceNumber} />

      <DeleteDiscountModal deleteDiscountModal={deleteDiscountModal} billAmount={invoiceDiscountAmount} setDeleteDiscountModal={setDeleteDiscountModal} deleteAppliedDiscount={deleteAppliedDiscount} />
    </>
  );
};

export default Invoice;



let cartItems = localStorage.getItem('CartItem') ?? []

// addt to cart
function addToCart(product){
  // to check the product exiost loca storage
  let exitingProduct = cartItems.filter(item => item.id === product.id)
}