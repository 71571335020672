import { Breadcrumbs, ButtonBase, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { DataGrid } from '@mui/x-data-grid';
import HttpComponent from "../../../School/MakeRequest";
import { useSelector } from "react-redux";

function UnoccupiedUnits() {
    const navigate = useNavigate();
    const { X_Authorization } = useSelector((store) => store.user);
    const [unoccupiedUnits, setUnoccupiedUnits] = useState([]);
    const [pageSize, setPageSize] = useState(10);

    const fetchUnoccupiedUnits = async () => {
        try {
            const response = await HttpComponent({
                method: 'GET',
                url: '/api/get_business_unit_config_list',
                token: X_Authorization
            });
            if (response.status === 201) {
                const unitsData = response.response.data;
                const unoccupied = [];

                unitsData.forEach(unitData => {
                    unitData.unitTypes.forEach(unitType => {
                        unitType.units.forEach(unit => {
                            if (unit.assignedStatus !== "Y") {
                                unoccupied.push({
                                    id: unit._id,
                                    hseNo: unit.unitName,
                                    hseType: unitType.unitType,
                                    unitAmount: unit.cost,
                                    dateCreated: new Date(unit.createdAt).toLocaleDateString(),
                                });
                            }
                        });
                    });
                });

                setUnoccupiedUnits(unoccupied);
            }
        } catch (error) {
            console.error("Error fetching unoccupied units:", error);
        }
    };

    useEffect(() => {
        fetchUnoccupiedUnits();
    }, []);

    const columns = [
        { field: 'hseNo', flex: 1, renderHeader: () => (<strong style={{ fontSize: '14px', color: '#032541' }}>Hse No</strong>) },
        { field: 'hseType', flex: 1, renderHeader: () => (<strong style={{ fontSize: '14px', color: '#032541' }}>Hse Type</strong>) },
        { field: 'unitAmount', flex: 1, renderHeader: () => (<strong style={{ fontSize: '14px', color: '#032541' }}>Unit Amount</strong>) },
        { field: 'dateCreated', flex: 1, renderHeader: () => (<strong style={{ fontSize: '14px', color: '#032541' }}>Date Created</strong>) },
    ];

    const breadcrumbs = [
        <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="1" color="text.inactive">Dashboard</Typography>,
        <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="2" color="text.inactive">Number Of Units</Typography>,
        <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Unoccupied Units</Typography>
    ];

    return (
        <div>
            <Grid container justifyContent={'flex-start'} mt={0}>
                <Grid item>
                    <ButtonBase onClick={() => { navigate("/units") }}>
                        <ArrowBackIosIcon style={{ color: '#707070', fontSize: '35px' }} />
                    </ButtonBase>
                </Grid>
                <Grid item>
                    <span style={{ color: '#032541', fontWeight: 'bold', fontSize: '25px' }}>Unoccupied Units</span>
                </Grid>
            </Grid>

            <Grid container justifyContent={'space-between'} mt={3} mb={3}>
                <Grid item>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
            </Grid>

            <div style={{ height: 650, width: '100%' }}>
                <DataGrid
                    rows={unoccupiedUnits}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 25, 50]}
                    disableSelectionOnClick
                />
            </div>
        </div>
    );
}

export default UnoccupiedUnits;