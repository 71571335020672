import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import HttpComponent from "../MakeRequest";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";

const AccessBankDebit = ({ setShowAccessDebitOptions }) => {
  const handleFormState = () => {
    setShowAccessDebitOptions(false);
  };

  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });

  const [showAccessBankForms, setShowAccessBankForms] = useState(false);
  const [showAccessBankAccounts, setShowAccessBankAcconts] = useState(true);
  const [showAccessBankButtons, setShowAccessBankButtons] = useState(false);
  const [accessBank, setAccessBank] = useState([]);
  const [accessconfigs, setAccessBankConfigs] = useState({ accessBank: true, bankName: "ACCESS-BANK", username:"", password: "", consumerKey: "",  });
  
  const addAccessBanksConfigs = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setAccessBankConfigs({ ...accessconfigs, [name]: value });
  };
  const addAccessBankConfigs = () => {
    HttpComponent({
      method: "POST",
      url: `/api/v1/addfunds_transfer_config`,
      token: localStorage.getItem("X-Authorization"),
      body: accessconfigs,
    }).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setSuccessShow({ state: true, message: "Access Bank Config added successfully!" });

        setTimeout(() => {
          setShowAccessBankAcconts(true)
          setShowAccessBankButtons(true)
          // setShowAccessDebitOptions(false);
          setShowAccessBankForms(false);
          getAccessBankDepositAccounts();
          setSuccessShow({ state: false, message: "" });
        }, 3000);
      } else {
        setErrorShow({ state: true, message: "Error adding accessbank configs!" });

        setTimeout(() => {
          setErrorShow({ state: false, message: "" });
        }, 3000);
      }
    });
    setAccessBankConfigs({ ...accessconfigs, accountName: "", accountNumber: "", consumerKey: "", consumerSecret: "", subscriptionkey: "", resourceId: "" });
  };

  const editAccessBankConfigs = () => {
    HttpComponent({
      method: "POST",
      url: `/api/v1/updatefunds_transfer_config`,
      body: accessconfigs,
      token:localStorage.getItem("X-Authorization")
    }).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setSuccessShow({ state: true, message: "Access Bank Updated successfully!" });
        setEditInfo(false)
        setTimeout(() => {
          setShowAccessBankAcconts(true)
          setShowAccessBankButtons(true)
          setShowAccessBankForms(false);
          getAccessBankDepositAccounts();
          setSuccessShow({ state: false, message: "" });
        }, 3000);
      } else {
        setErrorShow({ state: true, message: "Error adding accessbank configs!" });

        setTimeout(() => {
          setErrorShow({ state: false, message: "" });
        }, 3000);
      }
    });
  };

  const getAccessBankDepositAccounts = () => {
    try {
      HttpComponent({
        method: "GET",
        url: `/api/v1/getfunds_transfer_config?accountType=ACCESS-BANK`,
        token: localStorage.getItem("X-Authorization"),
      }).then((data) => {
        const accessBankAccounts = data?.response?.data;
        if (accessBankAccounts?.length === 0) {
          setAccessBank([]);
          setShowAccessBankButtons(false);
          setShowAccessBankForms(true);
        } else {
          setAccessBank(accessBankAccounts);
          setShowAccessBankButtons(true);
        }
      });
    } catch (e) {
      console.log("error", e?.message);
    }
  };

  useEffect(() => {
    getAccessBankDepositAccounts();
  }, []);

  const toggleFormAndAccounts = () => {
    setShowAccessBankAcconts(false);
    setShowAccessBankButtons(false);
    setShowAccessBankForms(true);
  };

  const removeConfigs = (id) => {
    HttpComponent({
      method: "POST",
      url: `/api/v1/deletefunds_transfer_config`,
      body: { configId: id },
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      if (data.status === 200 || data.status === 201) {
        setSuccessShow({ state: true, message: "Account Deleted Successfully" });
        setTimeout(() => {
          setSuccessShow({ state: false, message: "" });
        }, 1000);

        getAccessBankDepositAccounts();
      } else {
        setErrorShow({ state: true, message: data.response.message });
        setTimeout(() => {
          setErrorShow({ state: false, message: "" });
        }, 1000);
      }
    });
  };

  const [editiInfo, setEditInfo] = useState(false)
  const updateConfig = (account) => {
    setEditInfo(true)
    setShowAccessBankAcconts(false);
    setShowAccessBankButtons(false);
    setShowAccessBankForms(true);
    setAccessBankConfigs({ ...accessconfigs, configId: account?._id ,username:account?.username , password: account?.password, consumerKey: account?.consumerKey });
  };

  return (
    <div style={{ width: "90%", padding: "0px 0px 0px 60px" }}>
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <Grid container direction={"column"}>
        <Grid item mb={2}>
          <Typography>Setup you ACCESSBANK Bank Debit accounts and pay your suppliers, vendors and other payments!</Typography>
        </Grid>
        <Grid item mb={2} width={"100%"}>
          {showAccessBankAccounts &&
            accessBank?.map((account) => {
              return (
                <Grid container padding={"20px"} direction={"row"} mb={2} justifyContent={"space-between"} width={"100%"} display={"flex"} gap={"20px"} border={"1px solid #eaeaea"}>
                  <Grid item>
                    <Typography>{account?.bankName}</Typography>
                    <Typography>{account?.accountNumber}</Typography>
                  </Grid>

                  <Grid item display={"flex"} gap={"20px"}>
                    <Button style={{ color: "#032541", border: "1px solid" }} onClick={() => updateConfig(account)}>
                      {" "}
                      Edit{" "}
                    </Button>
                    <Button style={{ color: "#dc3545", border: "1px solid" }} onClick={() => removeConfigs(account?._id)}>
                      {" "}
                      Remove{" "}
                    </Button>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>

        <Grid item mb={2} width={"100%"} gap={"20px"} display={"flex"} justifyContent={"flex-end"}>
          {showAccessBankButtons && (
            <>
              <Button onClick={() => setShowAccessDebitOptions(false)} style={{ color: "#032541", border: "1px solid", padding: "8px 30px 8px 30px" }}>
                {" "}
                CANCEL{" "}
              </Button>
              <Button onClick={() => toggleFormAndAccounts()} style={{ backgroundColor: "#032541", color: "white", border: "1px solid", padding: "8px 30px 8px 30px" }}>
                {" "}
                ADD ACCOUNT{" "}
              </Button>
            </>
          )}
        </Grid>
        <Grid item mb={2} width={"100%"}>
          {/* add new account input fields!! */}
        </Grid>
      </Grid>

      {showAccessBankForms && (
        <Grid container direction={"column"} width={"100%"}>
          {/* <Grid item mb={2}>
            <TextField value={accessconfigs?.accountName} onChange={addAccessBanksConfigs} name={"accountName"} sx={{ width: "90%" }} placeholder="Account Name" />
          </Grid> */}
          <Grid item mb={2}>
            <TextField label={"Username"} value={accessconfigs?.username} onChange={addAccessBanksConfigs} name="username" sx={{ width: "90%" }} placeholder="Username" />
          </Grid>
          <Grid item mb={2}>
            <TextField label={"Password"} value={accessconfigs?.password} onChange={addAccessBanksConfigs} name="password" sx={{ width: "90%" }} placeholder="Password" />
          </Grid>
          <Grid item mb={2}>
            <TextField label={"Consumer Key"} value={accessconfigs?.consumerKey} onChange={addAccessBanksConfigs} name="consumerKey" sx={{ width: "90%" }} placeholder="Consumer key" />
          </Grid>
          {/* <Grid item mb={2}>
            <TextField label={"Resource Id"} value={accessconfigs?.resourceId} onChange={addAccessBanksConfigs} name="resourceId" sx={{ width: "90%" }} placeholder="Resource Id" />
          </Grid> */}
          {/* <Grid item mb={3}>
            <TextField label={"Subscription Key"} value={accessconfigs?.subscriptionkey} onChange={addAccessBanksConfigs} name="subscriptionkey" sx={{ width: "90%" }} placeholder="Subscription Key" />
          </Grid> */}

          <Grid width={"90%"} item display={"flex"} justifyContent={"flex-end"} gap={"20px"}>
            <Button onClick={() => handleFormState()} style={{ color: "#dc3545", border: "1px solid", padding: "8px 30px 8px 30px" }}>
              Cancel
            </Button>
            <Button onClick={() => editiInfo ? editAccessBankConfigs() : addAccessBankConfigs() } style={{ color: "#032541", border: "1px solid", padding: "8px 30px 8px 30px" }}>
               {editiInfo ? "Edit" : "Add"}
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AccessBankDebit;
