import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Allocated from "../../../../common/images/allocated.svg";
import NoOfUnits from "../../../../common/images/apartment.svg";
import Unallocated from "../../../../common/images/unallocated.svg";
import HttpComponent from "../../../School/MakeRequest";
import { Link } from "react-router-dom";

{/*Units Dashboard*/ }
export default function UnitsDashBoard(props) {
	const { userId, X_Authorization } = useSelector((store) => store.user);
	const [data, setData] = useState('')

	const getUnitsData = () => {
		HttpComponent({
			method: 'GET',
			url: '/api/get_business_unit_config_list',
			token: X_Authorization
		}).then((data) => {
			if (data.status === 201) {
				setData(data.response.data)
			} else {
				console.error("Error setting info")
			}
		}).catch((error) => {
			console.error(error.message);
		})
	}

	useEffect(() => {
		getUnitsData()
	}, [props.move])


	return (

		<Grid container justifyContent={'space-between'} alignContent={'center'} direction={'row'}>

			{/*Number of Units*/}
			<Grid item>
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={1}>

					<Grid item>
						<img src={NoOfUnits} alt={'images'} />
					</Grid>

					<Grid item>
						<span style={{ color: '#6e7074', fontSize: '18px' }} >Total No. Of Units</span>
						<Grid item style={{ marginTop: '10px' }}>
							<span style={{ fontSize: '18px', fontWeight: 'bolder', color: '#212b36' }} >{data[0]?.unitCount}</span>
						</Grid>
					</Grid>

				</Grid>

			</Grid>

			{/*Allocated*/}
			<Grid item>
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={1}>

					<Grid item>
						<img src={Allocated} alt={'images'} />
					</Grid>
					<Link to="/units/occupied" style={{ textDecoration: 'none' }}>
						<Grid item>
							<span style={{ color: '#6e7074', fontSize: '18px' }} >Occupied</span>
							<Grid item style={{ marginTop: '10px' }}>
								<span style={{ fontSize: '18px', fontWeight: 'bolder', color: '#17ae7b', marginLeft: '30px',  textDecoration: 'underline' }} >{data[0]?.allocatedUnits}</span>
							</Grid>
						</Grid>
					</Link>

				</Grid>
			</Grid>

			{/*Unallocated*/}
			<Grid item>
				<Grid container direction={'row'} justifyContent={'space-between'} spacing={1}>

					<Grid item>
						<img src={Unallocated} alt={'images'} />
					</Grid>
					<Link to="/units/unoccupied" style={{ textDecoration: 'none' }}>
						<Grid item>
							<span style={{ color: '#6e7074', fontSize: '18px' }} >Unoccupied</span>
							<Grid item style={{ marginTop: '10px' }}>
								<span style={{ fontSize: '18px', fontWeight: 'bolder', color: '#dc3545', marginLeft: '20px', textDecoration: 'underline' }} >{data[0]?.unallocatedUnits}</span>
							</Grid>
						</Grid>
					</Link>

				</Grid>
			</Grid>

		</Grid>
	)
}

