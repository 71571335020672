import { Breadcrumbs, ButtonBase, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { DataGrid } from '@mui/x-data-grid';
import HttpComponent from "../../../School/MakeRequest";
import { useSelector } from "react-redux";

function OccupiedUnits() {
    const navigate = useNavigate();
    const { X_Authorization } = useSelector((store) => store.user);
    const [occupiedUnits, setOccupiedUnits] = useState([]);
    const [pageSize, setPageSize] = useState(10);

    const fetchOccupiedUnits = async () => {
        try {
            const response = await HttpComponent({
                method: 'GET',
                url: '/api/get_business_unit_config_list',
                token: X_Authorization
            });
            if (response.status === 201) {
                const unitsData = response.response.data;
                const occupied = [];

                unitsData.forEach(unitData => {
                    unitData.unitTypes.forEach(unitType => {
                        unitType.units.forEach(unit => {
                            if (unit.assignedStatus === "Y") {
                                occupied.push({
                                    id: unit._id,
                                    hseNo: unit.unitName,
                                    hseType: unitType.unitType,
                                    occupiedBy: unit.tenantName,
                                    tenantPhone: unit.tenantPhone,
                                    unitAmount: unit.cost,
                                    dateOccupied: new Date(unit.dateAssigned).toLocaleDateString(),
                                });
                            }
                        });
                    });
                });

                setOccupiedUnits(occupied);
            }
        } catch (error) {
            console.error("Error fetching occupied units:", error);
        }
    };

    useEffect(() => {
        fetchOccupiedUnits();
    }, []);

    const columns = [
        { field: 'hseNo', flex: 1, renderHeader: () => (<strong style={{ fontSize: '14px', color: '#032541' }}>Hse No</strong>) },
        { field: 'hseType', flex: 1, renderHeader: () => (<strong style={{ fontSize: '14px', color: '#032541' }}>Hse Type</strong>) },
        { field: 'occupiedBy', flex: 1, renderHeader: () => (<strong style={{ fontSize: '14px', color: '#032541' }}>Occupied By</strong>) },
        { field: 'tenantPhone', flex: 1, renderHeader: () => (<strong style={{ fontSize: '14px', color: '#032541' }}>Tenant Phone</strong>) },
        { field: 'unitAmount', flex: 1, renderHeader: () => (<strong style={{ fontSize: '14px', color: '#032541' }}>Unit Amount</strong>) },
        { field: 'dateOccupied', flex: 1, renderHeader: () => (<strong style={{ fontSize: '14px', color: '#032541' }}>Date Occupied</strong>) },
    ];

    const breadcrumbs = [
        <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="1" color="text.inactive">Dashboard</Typography>,
        <Typography style={{ fontFamily: 'Poppins', fontSize: '14px' }} key="2" color="text.inactive">Number Of Units</Typography>,
        <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontSize: '14px' }} key="3" color="text.error">Occupied Units</Typography>
    ];

    return (
        <div>
            <Grid container justifyContent={'flex-start'} mt={0}>
                <Grid item>
                    <ButtonBase onClick={() => { navigate("/units") }}>
                        <ArrowBackIosIcon style={{ color: '#707070', fontSize: '35px' }} />
                    </ButtonBase>
                </Grid>
                <Grid item>
                    <span style={{ color: '#032541', fontWeight: 'bold', fontSize: '25px' }}>Occupied Units</span>
                </Grid>
            </Grid>

            <Grid container justifyContent={'space-between'} mt={3} mb={3}>
                <Grid item>
                    <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
            </Grid>

            <div style={{ height: 650, width: '100%' }}>
                <DataGrid
                    rows={occupiedUnits}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)} 
                    rowsPerPageOptions={[5, 10, 20, 25, 50]}
                    disableSelectionOnClick
                />
            </div>
        </div>
    );
}

export default OccupiedUnits;
