import { Box, Breadcrumbs, Button, Divider, Grid, Menu, MenuItem, Tab, TextField, Typography, TableBody, TableRow, TableCell, Table, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import HttpComponent from "../../School/MakeRequest";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { AddCircle, ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import Radio from '@mui/material/Radio';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Checkbox from "@mui/material/Checkbox";
import PreviewRaiseSupplierPayment from "./previewRaisePaymentSupplier";
import AddInvoiceSupplier from "./addSupplierInvoice";
import PreviewSupplierInvoice from "./previewSupplierInvoice";
import AuthecateUser from "../../paymentmodule/autheticatedComponent";


const numberFormat = (value) => { return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'KES' }).format(value) }
const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Supplier/Vendors</Typography>,
    <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Raise Payment</Typography>

]
const RaisePaymentSupplier = () => {
    const navigate = useNavigate()
    const [suppliers, setSuppliers] = useState([])
    const [selectedSupplier, setSelectedSupplier] = useState(null)
    const [invoices, setInvoices] = useState([])
    const [selectedInvoices, setSelectedInvoices] = useState([])
    const [buttonState, setButtonState] = useState(false)
    const queryparams = decodeURIComponent(window.location.search);
    const [supplierName, setSupplierName] = useState('')
    const [supplierDetails, setSupplierDetails] = useState({})
    const [raisepaymentButtonState, setRaisepaymentButtonState] = useState(false)

    const stateUrl = queryparams.slice(1)


    const getSuppliersAndInvoices = () => {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/suppliers?page=1&limit=1000&status=ACTIVE`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200 || data.status === 201) {
                    console.log(data.response.data)
                    setSuppliers(data.response.data)
                }
            })
        } catch (error) {

        }

    }
    useEffect(() => {
        getSuppliersAndInvoices()
    }, [stateUrl])
    const handleSelectRowClick = (id) => {
        setSelectedSupplier(id)
        const getSuppliersInvoices = suppliers.filter((supplier) => supplier._id === id)
        const supplierName = suppliers.find((supplier) => supplier._id === id)?.name
        console.log(getSuppliersInvoices, 'getSuppliersInvoices');
        setSupplierDetails(getSuppliersInvoices)
        let invoices = getSuppliersInvoices[0]?.invoices
        console.log(invoices, 'invoicesinvoices');
        setInvoices(invoices)
        setSelectedInvoices([])
        setSupplierName(supplierName)


    }
    const handleSelectInvoice = (event, invoice) => {
        let id = invoice._id
        console.log(id, 'selectedInvoicesselectedInvoices');
        const currentIndex = selectedInvoices.findIndex((invoice) => invoice === id)
        const newSelectedInvoices = [...selectedInvoices]
        if (currentIndex === -1) {
            let invoiceObject = {
                invoiceId: id,
                invoiceAmount: invoice.invoiceAmount,
                invoiceNumber: invoice.invoiceNumber
            }
            newSelectedInvoices.push(invoiceObject)
            setSelectedInvoices(newSelectedInvoices)
        } else {
            newSelectedInvoices.splice(currentIndex, 1)
        }

    }
    const buttonStateMethod = () => {
        if (selectedInvoices.length > 0) {
            return setButtonState(true)
        }
        else {
            return setButtonState(false)
        }
    }

    useEffect(() => {
        buttonStateMethod()
    }, [selectedInvoices, selectedInvoices])
    const submitDetails = () => {
        let data = {
            selectedInvoices: selectedInvoices,
            selectedSupplierId: selectedSupplier,
            selectedName: suppliers.find((supplier) => supplier._id === selectedSupplier)?.name || '',
            totalInvoicesAmount: selectedInvoices.reduce((acc, curr) => acc + curr.invoiceAmount, 0),
            bankName: suppliers.find((supplier) => supplier._id === selectedSupplier)?.bankName || '',
            bankCode: suppliers.find((supplier) => supplier._id === selectedSupplier)?.bankCode || '',
            accountNumber: suppliers.find((supplier) => supplier._id === selectedSupplier)?.bankAccountNumber || '',
            phoneNumber: suppliers.find((supplier) => supplier._id === selectedSupplier)?.phone || '',
        }
        console.log(data, 'datadatadata');
        localStorage.setItem('supplierPayment', JSON.stringify(data))
        navigate('/payables/suppplier/raisepayment?step=2')
    }
    const checkedStatus = (id) => {
        const currentIndex = selectedInvoices.findIndex((invoice) => invoice?.invoiceId === id)
        if (currentIndex === -1) {
            return false
        } else {
            return true
        }
    }

    console.log(selectedInvoices, 'selectedInvoicesselectedInvoices');
    const addInvoice = () => {
        navigate('/payables/suppplier/raisepayment?add_invoices')
    }
    const raisepaymentButtonStateMethod = () => {
        if(selectedInvoices >  0 && selectedSupplier){
            return setRaisepaymentButtonState(true)
        }else if (selectedInvoices > 0){
            return setRaisepaymentButtonState(false)
        }
    }
    useEffect(() => {
        raisepaymentButtonStateMethod()
    }, [selectedInvoices, selectedSupplier])

    const [isAuthecated, setIsAuthecated] = useState(false)
    const checkAuth = () => {
        if (isAuthecated === false) {
            navigate('/payables/suppplier/raisepayment?autheticate')
        }else if(isAuthecated === true && stateUrl === 'autheticate'){
            navigate('/payables/suppplier/raisepayment')
        }else if(isAuthecated === true && stateUrl){
            navigate(`/payables/suppplier/raisepayment?${stateUrl}`)
        }

    }
    useEffect(() => {
        checkAuth()
    }, [stateUrl])

    const handleIsAuthecated = (childData) => {
        let state = childData.state;
        console.log(state, 'statestatestate');
        
        setIsAuthecated(state)
        checkAuth()
    }

   

    return (
        <Grid>
            {stateUrl === 'step=2' ? <PreviewRaiseSupplierPayment /> :
            stateUrl === 'add_invoices' ? <AddInvoiceSupplier supplierId={selectedSupplier} supplierName = {supplierName} supplierDetails ={supplierDetails}/> :
            stateUrl === 'preview_invoice' ? <PreviewSupplierInvoice /> :
            stateUrl === 'autheticate' && (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') ? <AuthecateUser handleIsAuthecated={handleIsAuthecated} /> :


                <Grid container>
                    <Grid item mt={1}>
                        <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }} onClick={() => navigate("/dashboard")}> <ArrowBackIos/> Raise  Supplier/Vendor Payment</Typography>
                    </Grid>
                    <Grid container direction={'column'} mt={3}>
                        <Grid item>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                        <Grid item mt={1}>
                            <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#032541" }}>Select Supplier/Vendor</Typography>
                        </Grid>
                        <Grid>
                            <Table>
                                {suppliers.map((supplier) => {
                                    return (
                                        <Accordion key={supplier?._id} style={{ width: "700px" }}>
                                            <AccordionSummary onClick={() => handleSelectRowClick(supplier?._id)}>
                                                <TableBody  width={"100%"}>
                                                    <TableRow>
                                                        <TableCell padding="checkbox" style={{ border: "none" }}>
                                                            <Radio
                                                                value={supplier?._id} // Set the radio button value to the supplier _id
                                                                checked={selectedSupplier === supplier?._id} // Check if this supplier is selected
                                                                onChange={(event) => handleSelectRowClick(supplier?._id)} // Handle the selection
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ border: "none", width:"410px" }}>
                                                            <Box style={{ display: 'block', justifyContent: "space-between", cursor: "pointer" }}>
                                                                <span style={{ fontWeight: 600, fontSize: "14px", color: "#032541", cursor: "pointer" }}> {supplier?.name} </span> <br />
                                                                <span style={{ fontWeight: 600, fontSize: "14px", color: "#707070", cursor: "pointer" }}>{supplier?.bankName ? supplier?.bankName + " " + "Bank" : ''} </span>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell style={{ border: "none", width:"410px" }}>
                                                            <Box style={{ display: 'block', justifyContent: "space-between", cursor: "pointer" }}>
                                                                <span style={{ fontWeight: 600, fontSize: "14px", color: "#032541", cursor: "pointer" }}> {numberFormat(supplier?.invoicesTotal)} </span> <br />
                                                                <span style={{ fontWeight: 600, fontSize: "14px", color: "#707070", cursor: "pointer" }}>{supplier?.invoicesCount ? supplier?.invoicesCount + " " + "Invoices" : "0 Invoices"}</span>

                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {selectedSupplier === supplier?._id &&
                                                    <>
                                                        <Box style={{ display: 'flex', justifyContent: "space-between", cursor: "pointer", padding: "0px 45px 20px 10px" }}>

                                                            <span style={{ fontWeight: 600, fontSize: "14px", color: "#032541", cursor: "pointer" }}> Invoices </span> <br />
                                                            <span style={{ fontWeight: 600, fontSize: "16px", color: "#032541", cursor: "pointer", }} onClick={() => addInvoice()}><AddCircle style={{ fontSize: "16px",  }} />Add Invoices</span>
                                                        </Box>
                                                        {supplier?.invoices.length > 0 ?
                                                            supplier?.invoices.map((invoice) =>
                                                                <Table style={{ width: "600px" }}>
                                                                    <TableBody>

                                                                        <TableRow>
                                                                            <TableCell >
                                                                                <Checkbox

                                                                                    checked={checkedStatus(invoice?._id)}
                                                                                    onChange={(event) =>
                                                                                        handleSelectInvoice(
                                                                                            event,
                                                                                            invoice

                                                                                        )
                                                                                    }
                                                                                />
                                                                                <span style={{ fontWeight: 600, fontSize: "14px", color: "#032541", cursor: "pointer" }}> {invoice?.invoiceNumber} </span> <br />
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <span style={{ fontWeight: 600, fontSize: "14px", color: "#707070", cursor: "pointer" }}>{numberFormat(invoice?.invoiceAmount)} </span>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <span style={{ fontWeight: 600, fontSize: "14px", color: "#707070", cursor: "pointer" }} onClick={() => navigate(`/invoice/${invoice?.invoiceNumber}`)}><RemoveRedEyeIcon style={{ color: "#032541", fontSize: "20px", paddingTop: "5px", }} /> <span style={{ color: "#032541", }}>View Invoice</span> </span>
                                                                            </TableCell>

                                                                        </TableRow>

                                                                    </TableBody>
                                                                </Table>
                                                            )
                                                            : <Box style={{ display: "flex", justifyContent: "center", }}> No Invoices </Box>

                                                        }
                                                    </>
                                                }
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })}
                            </Table>
                        </Grid>
                    </Grid>

                    <Box style={{ display: 'flex', justifyContent: "space-between", width: "600px", marginTop: "20px" }} >

                        {/* <Button onClick={() => navigate(-1)} style={{ backgroundColor: "#fff", color: "#dc3545", borderRadius: "5px", width: "125px", height: "45px",border:"2px solid #dc3545" ,textTransform: "none" }}>Cancel </Button> */}
                        {buttonState ?
                            <Button onClick={() => submitDetails()} style={{ backgroundColor: "#032541", color: "#fff", borderRadius: "5px", width: "125px", height: "45px", textTransform: "none" }}>Next <ArrowForwardIos style={{ fontSize: "16px" }} /></Button>
                            : null}
                    </Box>


                </Grid>
            }
        </Grid >
    )
}

export default RaisePaymentSupplier