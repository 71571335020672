import React, { useEffect, useState } from 'react';
import { Breadcrumbs, FormControl, Grid, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { DataGrid } from "@mui/x-data-grid";
import CustomSearchInput from '../School/CustomSearchInput';
import HttpComponent from '../School/MakeRequest';
import { NoRowsOverlay } from '../No Rows/noRowsOverlay';
import ExportMenu from '../School/ExportMenu';
import CustomSelectField from '../School/CustomSelectField';
import { CustomizedDate } from '../customerAccounts/customerComponents/customDate';
import DateFormatter from '../../utils/dateFormatter';

// Breadcrumbs for navigation
const breadcrumbs = [
  <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
  <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Reports</Typography>,
  <Typography key={3} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Opening & Closing Stock Report</Typography>
];

const baseUrl = process.env.REACT_APP_BASE_URL;
const columns = [
  // {
  //   field: "productId",
  //   headerName: 'Product ID',
  //   flex: 1,
  //   align: 'left',
  //   renderHeader: () => (
  //     <strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }}>Product ID</strong>
  //   )
  // },
  {
    field: "productName",
    headerName: 'Product Name',
    flex: 1,
    align: 'left',
    renderHeader: () => (
      <strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }}>Product Name</strong>
    )
  },
  {
    field: "openingStock",
    headerName: 'Opening Stock',
    flex: 1,
    align: 'left',
    renderHeader: () => (
      <strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }}>Opening Stock</strong>
    )
  },
  {
    field: "quantitySold",
    headerName: 'Quantity Sold',
    flex: 1,
    align: 'left',
    renderHeader: () => (
      <strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }}>Quantity Sold</strong>
    )
  },
  {
    field: "closingStock",
    headerName: 'Closing Stock',
    flex: 1,
    align: 'left',
    renderHeader: () => (
      <strong style={{ fontFamily: "Poppins", fontSize: "10px", fontWeight: "600", color: "#032541" }}>Closing Stock</strong>
    )
  },
];

const ClosingAndOpeningReport = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedCustomDate, setselectedCustomDate] = useState('');
  const [showSelectDate, setShowSelectDate] = useState(true);
  const [dataGridPageSize, setPageSize] = useState(10);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: 10 });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [handleDaysChange, setHandleDaysChange] = useState('Today');
  const [days, setDays] = useState([
    { value: "Default", label: "Default" },
    { value: "Today", label: "Today" },
    { value: "Yesterday", label: "Yesterday" },
    { value: "Last 7 Days", label: "Last 7 Days" },
    { value: "This Month", label: "This Month" },
    { value: "Last Month", label: "Last Month" },
    { value: "Custom Range", label: "Custom Range" },
  ]);

  // Handle date range selection based on user input
  const hideShowSelectedDate = (selectedCustomDate) => {
    setShowSelectDate(selectedCustomDate !== 'custom_range');
    setselectedCustomDate(selectedCustomDate);
  };

  // Update date range based on the selected option
  useEffect(() => {
    switch (handleDaysChange) {
      case "Today":
        const today = new Date();
        setStartDate(today);
        setEndDate(today);
        break;
      case "Yesterday":
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
        setStartDate(yesterday);
        setEndDate(yesterday);
        break;
      case "Last 7 Days":
        const last7Days = new Date();
        setStartDate(new Date(last7Days.setDate(last7Days.getDate() - 7)));
        setEndDate(new Date());
        break;
      case "This Month":
        const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
        setStartDate(firstDayOfMonth);
        setEndDate(lastDayOfMonth);
        break;
      case "Last Month":
        const firstDayLastMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
        const lastDayLastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
        setStartDate(firstDayLastMonth);
        setEndDate(lastDayLastMonth);
        break;
      case "Custom Range":
        setStartDate(new Date(dateFrom));
        setEndDate(new Date(dateTo));
        break;
      default:
        setStartDate('');
        setEndDate('');
        break;
    }
  }, [handleDaysChange, dateFrom, dateTo]);

  // Fetch opening and closing report data
  const getOpeningClosingReport = async () => {
    setPageState((old) => ({ ...old, isLoading: true }));

    const adjustedStartDate = new Date(startDate);
    adjustedStartDate.setUTCHours(0, 0, 0, 0);

    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setUTCHours(23, 59, 59, 999);

    try {
      const response = await HttpComponent({
        method: 'GET',
        url: `/api/v1/business/getStock?startDate=${adjustedStartDate.toISOString()}&endDate=${adjustedEndDate.toISOString()}&page=${pageState.page}&limit=${dataGridPageSize}&searchValue=${searchValue}`,
        token: localStorage.getItem('X-Authorization')
      });
      if (response.status === 200) {
        setPageState({ ...pageState, isLoading: false, total: response?.response?.totalItems, data: response.response.data });
      }
    } catch (error) {
      console.error(error);
      setPageState((old) => ({ ...old, isLoading: false }));
    }
  };

  useEffect(() => {
    getOpeningClosingReport();
  }, [startDate, endDate, pageState?.page, dataGridPageSize, searchValue]);


  const rowData = pageState?.data?.map((item) => ({
    id: item.productId,
    productId: item.productId,
    productName: item.productName,
    openingStock: item.openingStock,
    quantitySold: item.quantitySold,
    closingStock: item.closingStock,
  }));

  // Prepare data for export
  const filedata = pageState?.data?.map((item) => ({
    "Product Name": item.productName,
    "Opening Stock": item.openingStock,
    "Quantity Sold": item.quantitySold,
    "Closing Stock": item.closingStock,
  }));
  const fileHeaders = [["Product Name", "Opening Stock", "Quantity Sold", "Closing Stock"]];
  const csvColumns = [
    { label: "Product Name", key: "Product Name" },
    { label: "Opening Stock", key: "Opening Stock" },
    { label: "Quantity Sold", key: "Quantity Sold" },
    { label: "Closing Stock", key: "Closing Stock" }
  ];
  const fileDataPDF = pageState?.data?.map((item) => [item.productId, item.productName, item.openingStock, item.quantitySold, item.closingStock]);

  return (
    <Grid container direction={'column'}>
      <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
        <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Opening & Closing Stock Report</Typography>
      </Grid>
      <Grid item display={'flex'} mt={2}>
        <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
          <Grid item>
            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid item>
            <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={`Sales Report By Cashier on ${DateFormatter(new Date())}`} title={`Sales Report By Cashier on ${DateFormatter(new Date())}`} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item mt={4}>
        <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <CustomSearchInput placeholder={'Search'} name={searchValue} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
          </Grid>
          <Grid item style={{ width: '500px' }}>
            {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}
            {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center", }} />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item mt={4}>
        <DataGrid
          autoHeight
          getRowId={(row) => row?.id}
          rows={rowData}
          rowCount={pageState?.total}
          columns={columns}
          loading={pageState?.isLoading}
          pageSize={dataGridPageSize}
          paginationMode="server"
          rowsPerPageOptions={[10, 50, 100, 150, 200, 300, 400, 500]}
          components={{
            NoRowsOverlay,
          }}
          onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        />
      </Grid>
    </Grid>
  );
};

export default ClosingAndOpeningReport;
