import { Box, Breadcrumbs, Grid, Typography, Paper, TextField, Button } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import React, { useEffect, useState } from "react";
import HttpComponent from "../School/MakeRequest";
import ProfilePic from '../../common/images/profile_picture.svg'
import DeleteUserAccountPage from "./deleteAccount";
import { useNavigate } from "react-router-dom";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { useDispatch } from "react-redux";
import { deleteBranchDetails } from "../../features/branchSlice";
import { deleteBusinessCategoryDetails } from "../../features/businessCategorySlice";
import { deleteBusinessBranches } from "../../features/businessBranchesSlice";
import { logoutUser } from "../../features/userSlice";
import { resetSideBarDefault } from "../../features/sideBarSlice";
import { reversePageLoadState } from "../../features/autorefreshonpageload";
import { handleReset } from "../../features/tenantsDataSlice";
import { handleClearServicesData } from "../../features/servicesSlice";
import { clearAdditions } from "../../features/customerSlice";
import { removeSchoolTypeName } from "../../features/schoolType";
import { handleResetStaff } from "../../features/staffSlice";
import { resetVerifyToken } from "../../features/verifyToken";
import { handleResetDetail } from "../../features/businessAddPartner";
const baseUrl = process.env.REACT_APP_BASE_URL;

const breadcrumbs = [
    <Typography key={1} style={{ color: '#707070', fontSize: '14px', fontFamily: 'Poppins' }}>
        Dashboard
    </Typography>,
    <Typography key={4} style={{ color: '#dc3545', fontSize: '14px', fontFamily: 'Poppins' }}>
        My Profile
    </Typography>,
];

export default function UserProfile() {
    const queryparams = decodeURIComponent(window.location.search);
    const navigate = useNavigate();
    const state = queryparams.slice(1);
    const [customerDetails, setCustomerDetails] = useState()
    const [userDetailsState, setUnserDetailsState] = useState(false)
    const [pinState, setPinState] = useState(false)
    const [userPin, setuserPin] = useState('')
    const [newPin, setNewPin] = useState('')
    const [errorShow, setErrorShow] = useState({ state: false, message: "" });
    const [successShow, setSuccessShow] = useState({ state: false, message: "" });
    const [pinResetLoading, setPinResetLoading] = useState(false)
    const [confirmNewPin, setConfirmPinNewPin] = useState()
    const [formData, setFormdata] = useState({ firstName: "", secondName: "", userName: "", userEmail: "", userPhone: '', userGroup: "", location: "" })
    const handleFormDataChange = (e) => {
        setFormdata({ ...formData, [e.target.name]: e.target.value })
    }
    const changePinState = () => {
        setPinState(!pinState)
    }
    const userIdd = localStorage.getItem('userId')

    const dispatch = useDispatch()

    const handleUpdateUserDetails = () => {
        setUnserDetailsState(!userDetailsState)
    }
    const handleUpdateUserDetailsEdit = async () => {
        try{
            HttpComponent({
                method:"PUT",
                token:localStorage.getItem("X-Authorization"),
                url:`/api/updateUser/${userIdd}`,
                body:{
                    firstname: formData.firstName,
                    secondname: formData.secondName,
                    userName: formData.userName,
                    userEmail: formData.userEmail,
                    userPhone: formData.userPhone,
                    userGroup: formData.userGroup,
                    modifiedAtBy: localStorage.getItem("username"),
                    modifiedAt: Date.now(),
                }
            }).then((data)=>{
                if(data?.status === 200 || data?.status === 201){
                    setSuccessShow({state:true, message:data?.response?.message})
                    setTimeout(()=>{
                        setUnserDetailsState(false)
                    },1000)
                }
            })
        }catch(error){

        }finally{
        }
    }

    // reset pin

    const resetBody = {
        userEmail: localStorage.getItem("email"),
        userPin: userPin,
        userNewPin: newPin,
    };

    function handleResetPin() {
        if (confirmNewPin !== newPin) {
            return setErrorShow({ state: true, message: "New Pin Doesn't Match Pin Confirmation!" });
        }
        setPinResetLoading(true)
        try {
            HttpComponent({
                method: "PUT",
                url: "/api/resetPinVersion2",
                body: resetBody,
            }).then((data) => {
                if (data.status === 200) {
                    setSuccessShow({ state: true, message: "Pin reset successful" });
                    dispatch(deleteBranchDetails());
                    dispatch(deleteBusinessCategoryDetails());
                    dispatch(deleteBusinessBranches());
                    dispatch(logoutUser());
                    dispatch(resetSideBarDefault());
                    dispatch(reversePageLoadState())
                    dispatch(handleReset())
                    dispatch(handleClearServicesData())
                    dispatch(clearAdditions())
                    dispatch(removeSchoolTypeName())
                    dispatch(handleResetStaff())
                    dispatch(handleResetDetail())
                    dispatch(resetVerifyToken())
                    localStorage.clear()
                    navigate("/");
                } else if (data.response.message.includes("Error wrong pin!")) {
                    setErrorShow({ state: true, message: data?.response?.message });
                } else if (data.status === 401) {
                    setErrorShow({ state: true, message: data?.response?.message });
                }
            })

        } catch (error) {
            setErrorShow({ state: true, message: error?.message });
        } finally {
            setPinResetLoading(false)
        }
    }


    function fetchCustomerDetaisl() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/get_customer_profile_by_userId?customerId=${userIdd}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'customer data here')
                if (data?.status === 200) {
                    setCustomerDetails(data?.response?.data)
                    setFormdata({
                        ...formData,
                        firstName: data?.response?.data?.firstName,
                        secondName: data?.response?.data?.secondName,
                        userName: data?.response?.data?.userName,
                        userPhone: data?.response?.data?.userPhone,
                        userEmail: data?.response?.data?.userEmail
                    })
                }
            })
        } catch (error) {
            console.log(error, 'customer data here')
        }
    }

    useEffect(() => {
        fetchCustomerDetaisl()
    }, [userIdd])
    return (
        <Grid container width={'100%'} display={'flex'} flexDirection={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            {state === 'DeleteAccount' ? <DeleteUserAccountPage /> : <>
                <Grid item display={'flex'} alignItems={'center'}>
                    <ArrowBackIosNewIcon style={{ color: '#707070' }} />
                    <Typography variant="h4" style={{ color: '#032541', fontWeight: 'bold', fontSize: '20px', fontFamily: 'Poppins' }}>
                        My Profile
                    </Typography>
                </Grid>
                <Grid item display={'flex'} mt={2}>
                    <Breadcrumbs
                        style={{ fontFamily: 'Poppins', fontSize: '14px' }}
                        aria-label="breadcrumb"
                        separator={<FiberManualRecordIcon style={{ fontSize: '0.625rem', fontFamily: 'Poppins', color: '#e1e5e8' }} />}
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                </Grid>
                <Grid item width={'100%'} xl={12} lg={12} md={12} sm={12}>
                    <Grid container width={'100%'} gap={2}>
                        <Grid item xl={3} lg={3} md={12} sm={12} py={2}>
                            <Paper sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', px: 2, py: 2, height: "300px" }}>
                                {customerDetails?.userPhoto ? <Box sx={{ height: '100%', width: '100%' }} component={'img'} src={`${baseUrl}/staticimages/logos/${customerDetails?.userPhoto}`} /> : <Box sx={{ height: '100%', width: '100%' }} component={'img'} src={ProfilePic} />}
                            </Paper>
                        </Grid>
                        <Grid item xl={8} lg={8} md={12} sm={12} mt={2}>
                            <Paper sx={{ display: 'flex', flexDirection: 'column', width: '100%', px: 2, py: 2 }}>
                                <Box component={'div'} sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography variant="h6">Personal Details</Typography>
                                    {userDetailsState === false ? <Typography onClick={handleUpdateUserDetails} sx={{ cursor: 'pointer' }} variant="h6">Edit Info</Typography> : <Typography sx={{ cursor: 'pointer' }} fontWeight={'700'} color={'#17ae7b'} onClick={handleUpdateUserDetailsEdit} variant="h6">Save Changes</Typography>}
                                </Box>
                                {userDetailsState === false ?
                                    <React.Fragment>
                                        <Box component={'div'} display={'flex'} pt={2} pb={2} justifyContent={'space-between'} gap={2}>
                                            <TextField value={formData.firstName} name="firstName" id="outlined-basic" onChange={handleFormDataChange} label="FirstName" variant="outlined" fullWidth />
                                            <TextField value={formData.secondName} name="secondName" id="outlined-basic" onChange={handleFormDataChange} label="LastName" variant="outlined" fullWidth />
                                        </Box>
                                        <Box component={'div'} display={'flex'} pb={2} justifyContent={'space-between'} gap={2}>
                                            <TextField value={formData.userName} name="userName" id="outlined-basic" onChange={handleFormDataChange} label="UserName" variant="outlined" fullWidth />
                                            <TextField value={formData.userEmail} name="userEmail" id="outlined-basic" onChange={handleFormDataChange} label="Email" variant="outlined" fullWidth />
                                        </Box>
                                        <Box component={'div'} display={'flex'} pb={2} justifyContent={'space-between'} gap={2}>
                                            <TextField value={formData.userPhone} name="userPhone" id="outlined-basic" onChange={handleFormDataChange} label="Phone Number" variant="outlined" fullWidth />
                                            <TextField value={formData.location} name="location" id="outlined-basic" onChange={handleFormDataChange} label="Location" variant="outlined" fullWidth />
                                        </Box>
                                    </React.Fragment> :
                                    <React.Fragment>
                                        <Box component={'div'} display={'flex'} pt={2} pb={2} justifyContent={'space-between'} gap={2}>
                                            <TextField value={formData.firstName} name="firstName" id="outlined-basic" onChange={handleFormDataChange} label="FirstName" variant="outlined" fullWidth />
                                            <TextField value={formData.secondName} name="secondName" id="outlined-basic" onChange={handleFormDataChange} label="LastName" variant="outlined" fullWidth />
                                        </Box>
                                        <Box component={'div'} display={'flex'} pb={2} justifyContent={'space-between'} gap={2}>
                                            <TextField value={formData.userName} name="userName" id="outlined-basic" onChange={handleFormDataChange} label="UserName" variant="outlined" fullWidth />
                                            <TextField value={formData.userEmail} name="userEmail" id="outlined-basic" onChange={handleFormDataChange} label="Email" variant="outlined" fullWidth />
                                        </Box>
                                        <Box component={'div'} display={'flex'} pb={2} justifyContent={'space-between'} gap={2}>
                                            <TextField value={formData.userPhone} name="userPhone" id="outlined-basic" onChange={handleFormDataChange} label="Phone Number" variant="outlined" fullWidth />
                                            <TextField value={formData.location} name="location" id="outlined-basic" onChange={handleFormDataChange} label="Location" variant="outlined" fullWidth />
                                        </Box>
                                    </React.Fragment>
                                }
                            </Paper>
                            <Paper sx={{ display: 'flex', flexDirection: 'column', mt: 3, width: '100%', px: 2, py: 2 }}>
                                <Box component={'div'} py={2}>
                                    <Typography variant="h6">Pin</Typography>
                                    <Typography variant="body2">Manage your 4-digit Pin.</Typography>
                                </Box>
                                <Box component={'div'}>
                                    {pinState ?
                                        <Box component={'div'}>
                                            <TextField value={userPin} onChange={(e) => setuserPin(e.target.value)} id="outlined-basic" sx={{ pb: 1, pt: 1 }} label="Current Pin" variant="outlined" fullWidth />
                                            <TextField value={newPin} onChange={(e) => setNewPin(e.target.value)} id="outlined-basic" sx={{ pb: 2 }} label="New Pin" variant="outlined" fullWidth />
                                            <TextField value={confirmNewPin} onChange={(e) => setConfirmPinNewPin(e.target.value)} id="outlined-basic" sx={{ pb: 1 }} label="Confirm New Pin" variant="outlined" fullWidth />
                                        </Box> :
                                        <TextField id="outlined-basic" label="Pin" variant="outlined" fullWidth />
                                    }
                                    {pinState ?
                                        <Box component={'div'} sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', cursor: 'pointer', alignItems: 'flex-end' }}>
                                            <Button onClick={handleResetPin} style={{ color: "#fff", background: "#032541", textTransform: "inherit", padding: "10px 10px", '&:hover': { background: '#fff' } }}>Save Changes</Button>
                                        </Box> :
                                        <Typography onClick={changePinState} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, cursor: 'pointer', alignItems: 'flex-end' }}>Change Pin</Typography>}
                                </Box>
                            </Paper>
                            <Box component={'div'} pt={3}>
                                <Button onClick={() => navigate('/user/profile?DeleteAccount')} sx={{ color: "#dc3545", textTransform: 'inherit', '&:hover': { backgroundColor: '#fff' } }}>Delete Account </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </>}
        </Grid>
    )
}