import { createSlice } from '@reduxjs/toolkit'

let initialState = {
    loginToken: null,
    tokenMessage: "",
    loginResultData:{}
}

const verifyOtpToken = createSlice({
    name: "userDetails",
    initialState,
    reducers: {
        setVerifyToken: (state, action) => {
            state.loginToken = action.payload
        },
        setVerifyMessage: (state, action) => {
            state.tokenMessage = action.payload
        },
        setLoginResultData:(state, action)=>{
            state.loginResultData = action.payload
        },
        resetVerifyToken: (state, action) => {
            state.loginToken = null
            state.tokenMessage = ""
            state.loginResultData = {}
        }
    }
})

export const { setVerifyToken, setVerifyMessage, resetVerifyToken ,setLoginResultData} = verifyOtpToken.actions;
export default verifyOtpToken.reducer;
